<template>
  <div class="container mb-4" style="max-height: 300px">
    <VPerfectSignature
      :stroke-options="strokeOptions"
      ref="signature"
      style="background: white; border-radius: 10px"
    />
    <!-- button to save and to clear -->
    <div class="row d-flex justify-content-center">
      <button class="invisible-btn text-success w-25" @click="saveSignature">
        <i class="fas fa-save"></i>
      </button>
      <button class="invisible-btn text-danger w-25" @click="clearSignature">
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import VPerfectSignature from "v-perfect-signature";
import axios from "axios";

export default {
  name: "Signature",
  components: {
    VPerfectSignature,
  },
  data() {
    return {
      signature_img: null,
      strokeOptions: {
        size: 10,
        thinning: 0.5,
        smoothing: 0.5,
        streamline: 0.5,
      },
    };
  },
  methods: {
    async saveSignature() {
      const signatureBase64 = this.$refs.signature.toDataURL();
      const signature = await fetch(signatureBase64);
      const signatureBlob = await signature.blob();
      const signatureFile = new File([signatureBlob], "signature.png", {
        type: "image/png",
      });

      const formData = new FormData();
      formData.append("signature", signatureFile);
      axios
        .patch(`/api/v1/users/${this.$parent.info.id}/`, formData)
        .then(() => {
          this.clearSignature();
          this.$store.dispatch("showAlert", {
            message: this.$t("signature_saved"),
            type: "success",
            timeout: 3000,
          });
          this.$emit("updateSignature");
          this.$parent.editable = false;
        })
        .catch((err) => {
          this.clearSignature();
          console.log(err);
          this.$store.dispatch("showAlert", {
            message: this.$t("signature_not_saved"),
            type: "error",
            timeout: 3000,
          });
        });
    },

    clearSignature() {
      this.$refs.signature.clear();
      this.$parent.drawSignature = false;
    },
  },
  setup() {
    const signature = ref(null);
    return {
      signature,
    };
  },
};
</script>

<style scoped></style>
