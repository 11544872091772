<template>
  <div>
    <!-- Modal -->
    <div
      v-if="OpenClose"
      class="modal-backdrop"
      tabindex="-2"
      aria-labelledby="exampleModalLabel"
      aria-modal="true"
      role="dialog"
      style="display: block"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <p class="modal-title" id="exampleModalLabel">
              <slot name="title"></slot>
            </p>
            <button
              type="button"
              @click="OpenCloseFun()"
              class="btn-close"
            ></button>
          </div>
          <div class="modal-body">
            <slot name="body"></slot>
          </div>
          <div class="modal-footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AlertDefault",
  props: {
    visible: Boolean,
  },
  data() {
    return {
      OpenClose: this.visible,
    };
  },
  methods: {
    OpenCloseFun() {
      this.OpenClose = false;
      this.$emit("event");
    },
  },
  watch: {
    visible: function (newVal) {
      this.OpenClose = newVal;
    },
  },
};
</script>
<style scoped>
.modal-content {
  background-color: rgba(0, 0, 0, 0.85);
  border: 1px solid white;
}
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (1.75rem * 2));
  min-width: 50%;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.75
  ); /* Set background color and transparency */
  z-index: 9999; /* Ensure the modal is on top of other elements */
}
.modal-header {
  border-bottom: none;
}
.modal-footer {
  border-top: none;
}
</style>
