import axios from "axios";

const availabilities = {
  state: {
    weeks: {},
  },
  mutations: {
    setWeeks(state, data) {
      state.weeks = data.data;
    },
  },
  actions: {
    getUserWeeks(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/api/v1/users/${data.userId}/availabilities/${data.year}/weeks/?page_size=53`
          )
          .then((response) => {
            return resolve(response.data);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
    getEditedWeeks(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/api/v1/users/${data.userId}/availabilities/${data.year}/last_edited_weeks/`
          )
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
    editWeek(context, data) {
      return new Promise((resolve, reject) => {
        const year = data.week.start_date.split("-")[0];
        axios
          .put(
            `/api/v1/users/${data.userId}/availabilities/${year}/weeks/${data.week.number}/`,
            data.week
          )
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
    batchWeekUpdate(context, data) {
      return new Promise((resolve, reject) => {
        const year = data.weeks[0].start_date.split("-")[0];
        axios
          .post(
            `/api/v1/users/${data.userId}/availabilities/${year}/weeks/batch-update/`,
            data.weeks
          )
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },

    changeAvailabilitiesStatus(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/users/${data.userId}/availabilities/change_status/`, {
            status: data.status,
          })
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
    addWeeks(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `/api/v1/users/${data.userId}/availabilities/${data.year}/add_weeks/`
          )
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
    checkStatus(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/users/${data.userId}/availabilities/${data.year}/`)
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
    fetchAllAvailabilities() {
      const year = new Date().getFullYear();
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/users/availabilities/?year=${year}`)
          .then((response) => {
            return resolve(response.data);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
    assignUserToJob(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/users/${data.userId}/assign_job/`, data.params)
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
    unassignUserFromJob(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/users/${data.userId}/unassign_job/`, data.params)
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
  },
  getters: {
    getWeeks(state) {
      return state.weeks;
    },
  },
};

export default availabilities;
