<template>
  <div class="card">
    <div class="p-3 pb-0 card-header">
      <div class="row">
        <div class="col-md-8 d-flex align-items-center">
          <h6 class="mb-0 text-primary">{{ title }}</h6>
        </div>
        <div v-if="admin_view" class="col-md-4 text-end">
          <IconBtn title="add_job" icon="fas fa-plus text-bg-contrast" @click="initiateAddJob" />
        </div>
      </div>
    </div>
    <div v-if="this.info.jobs" class="p-3 card-body">
      <table class="table align-items-center mb-0">
        <tbody class="text-center">
          <tr v-if="addNewJob">
            <td>
              <select class="form-control" v-model="this.newJob">
                <option v-for="job in this.jobs" :key="job.name" :value="job.name">{{ job.name }}</option>
              </select>
            </td>
            <td>
              <IconBtn title="save" icon="fas fa-save text-bg-contrast" @click="saveJob" />
              <IconBtn title="cancel" icon="fas fa-times text-bg-contrast" @click="cancelAddJob" />
            </td>
          </tr>
          <span v-if="this.info.jobs.length">
            <tr v-for="job in this.info.jobs" :key="job.job">
              <td>{{ job.job }}</td>
              <td>
                <IconBtn v-if="this.admin_view" title="delete" icon="fas fa-minus text-bg-contrast"
                  @click="deleteJob(job)" />
              </td>
            </tr>
          </span>
          <div v-else-if="!this.addNewJob" class="text-center">
            <p class="text-muted">{{ $t("no_jobs") }}</p>
          </div>
        </tbody>
      </table>

    </div>

  </div>
</template>

<script>
import IconBtn from "@/components/buttons/IconBtn.vue";


export default {
  name: "JobsInfoCard",
  components: {
    IconBtn,
  },

  data() {
    return {
      admin_view: false,
      addNewJob: false,
      jobs: [],
      newJob: ""

    };
  },

  methods: {
    cancelAddJob() {
      this.addNewJob = false;
    },

    saveJob() {
      let userJobs = this.info.jobs;
      userJobs.push({ job: this.newJob });
      this.updateUserJobs("job_added_successfully", userJobs);
    },


    deleteJob(job) {
      let userJobs = this.info.jobs;
      userJobs = userJobs.filter((item) => item.job !== job.job);
      this.updateUserJobs("job_removed_successfully", userJobs);
    },

    updateUserJobs(successMsg, userJobs) {
      const data = {
        jobs: userJobs,
        userId: this.info.id
      }
      this.$store.dispatch("patchUserJobs", data).then(() => {
        this.$store.dispatch("showAlert", {
          message: this.$t(successMsg),
          type: "success",
          timeout: 3000,
        });
        this.$parent.getUser()
      }).catch(() => {
        this.$store.dispatch("showAlert", {
          message: this.$t("something_went_wrong"),
          type: "error",
          timeout: 3000,
        });
      })
      this.cancelAddJob();
    },
    async initiateAddJob() {
      this.getAllJobs()
      this.addNewJob = true;

    },
    getAllJobs() {
      this.$store.dispatch("fetchJobs").then((resp) => {
        this.jobs = resp.results;
      })
    },

  },

  props: {
    title: {
      type: String,
      default: "",
    },
    info: {
      type: Object,
      fullName: String,
      mobile: String,
      email: String,
      country: String,
      default: () => { },
    },

  },
  mounted() {
    if (this.$store.state.user.is_superuser) {
      this.admin_view = true;
    }
  },
};
</script>
<style scoped>
.card {
  min-height: 40vh;
  max-height: 40vh;
  overflow-y: auto;

}

@media (max-width: 576px) {
  .card {
    margin-top: 2rem;
  }

  .tableFixHead {
    height: 100%;
    max-width: 100%;
  }
}
</style>
