<template>
  <div class="mb-n4 mx-4 card card-body blur shadow-blur mt-0">
    <div class="row gx-4">
      <div class="col-auto">
        <h6 class="mb-1">{{ $t("all_championship_events") }}</h6>
      </div>
      <SwitchMenu
        :menuList="['championship_events_list', 'championship_list']"
        :switchMenuFunc="switchCard"
      />
    </div>
  </div>
  <div class="container-fluid mt-4">
    <div class="row" style="height: 40vh">
      <div class="col-lg-12 col-md-6 mb-md-0 my-4">
        <ChampionshipEventsList v-if="this.activeCard == 'championship_events_list'" />

        <ChampionshipList v-if="this.activeCard == 'championship_list'" />
      </div>
    </div>
  </div>
</template>

<script>
import ChampionshipEventsList from "./components/ChampionshipEventList.vue";
import ChampionshipList from "./components/ChampionshipList.vue";
import SwitchMenu from "@/components/SwitchMenu.vue";

export default {
  components: {
    ChampionshipEventsList,
    ChampionshipList,
    SwitchMenu,
  },
  data() {
    return {
      activeCard: "championship_events_list",
    };
  },
  methods: {
    switchCard(card) {
      this.activeCard = card;
    },
  },
};
</script>

<style scoped></style>
