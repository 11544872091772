<template>
  <div class="container-fluid">
    <DeleteAlert
      @deleteEvent="deleteEvent"
      :msg="deleteMsg"
      :visible="ShowModal"
    />
    <EventsCard ref="eventCard">
      <template #headerButton>
        <router-link to="/add-event" class="btn btn-primary text-primary-contrast right">{{
          $t("add_event")
        }}</router-link>
      </template>
      <template #eventButtons="eventItem">
        <IconBtn
          title="delete"
          icon="fas fa-trash text-secondary"
          @click="
            () => {
              this.ShowModal = true;
              this.selectedEvent = eventItem.item;
            }
          "
        />
      </template>
    </EventsCard>
  </div>
</template>

<script>
import EventsCard from "./components/EventsCard.vue";
import DeleteAlert from "@/components/alerts/DeleteAlert.vue";
import IconBtn from "@/components/buttons/IconBtn.vue";

export default {
  data() {
    return {
      deleteMsg: "want_to_delete",
      ShowModal: false,
      selectedEvent: {},
    };
  },
  methods: {
    deleteEvent() {
      this.ShowModal = false;
      this.$store.dispatch("deleteEvent", this.selectedEvent).then(() => {
        this.$refs.eventCard.getEventData();
      });
    },
  },
  components: {
    EventsCard,
    DeleteAlert,
    IconBtn,
  },
};
</script>

<style scoped>
.card {
  max-height: 85vh;
  overflow-y: hidden;
  overflow-x: auto;
}
</style>
