<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="70"
  />
</template>

<script>
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import "chartjs-adapter-date-fns";
import annotationPlugin from "chartjs-plugin-annotation";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  annotationPlugin
);

export default {
  name: "TimelineChart",
  components: { Bar },
  props: {
    roomCheckIn: {
      type: String,
      required: true,
    },
    roomCheckOut: {
      type: String,
      required: true,
    },
    assignedUsers: {
      type: Array,
      required: true,
    },

    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      chartData: {
        labels: this.assignedUsers.map((user) => user.user.full_name),
        datasets: [
          {
            data: this.assignedUsers.map((user) => [
              user.check_in,
              user.check_out,
            ]),
          },
        ],
      },
      chartOptions: {
        barPercentage: 0.2,
        backgroundColor: ["#FF8040", "#FF8040", "#FF8040", "#FF8040"],
        indexAxis: "y",
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          annotation: {
            annotations: {
              line1: {
                id: "line1",
                type: "line",
                xMin: this.roomCheckIn,
                xMax: this.roomCheckIn,
                borderColor: "rgb(0, 0, 0)",
                borderWidth: 2,
                label: {
                  backgroundColor: "rgb(0, 0, 0)",
                  color: "white",
                  content: "Check-In",
                  display: true,
                  position: "end",
                },
              },
              line2: {
                id: "line2",
                type: "line",
                xMin: this.roomCheckOut,
                xMax: this.roomCheckOut,
                mode: "vertical",
                borderColor: "rgb(0, 0, 0)",
                borderWidth: 2,
                label: {
                  backgroundColor: "rgb(0, 0, 0)",
                  color: "white",
                  content: "Check-Out",
                  display: true,
                  position: "end",
                },
              },
            },
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                return (
                  context.dataset.data[context.dataIndex][0]
                    .toLocaleString()
                    .replace("T", " ")
                    .slice(0, -3) +
                  " - " +
                  context.dataset.data[context.dataIndex][1]
                    .toLocaleString()
                    .replace("T", " ")
                    .slice(0, -3)
                );
              },
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "white",
            },
            min: this.roomCheckIn.split("T")[0],
            max: new Date(this.roomCheckOut.split("T")[0]).setDate(
              new Date(this.roomCheckOut.split("T")[0]).getDate() + 1
            ),

            type: "time",
            time: {
              unit: "day",
              displayFormats: {
                day: "dd.MM",
              },
            },
          },
          y: {
            ticks: {
              color: "darkorange",
            },
          },
        },
      },
    };
  },
};
</script>
