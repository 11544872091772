<template>
  <div class="py-4 container-fluid">
    <div class="row mt-2" style="height: 40vh">
      <div class="col-lg-12 col-md-6 mb-md-0 mb-4">
        <TravelExpensesUserCard
          cardName="event_expenses"
          type="event_expenses"
          :expenses="expenses.filter((expense) => expense.event)"
        />
      </div>
    </div>
    <!-- Code below temporary disabled for the ADAC -->

    <div class="row mt-4" style="height: 40vh">
      <div class="col-lg-12 col-md-6 mb-md-0 mb-4">
        <TravelExpensesUserCard
          cardName="non_event_expenses"
          type="non_event_expenses"
          :headers="['reason', 'from', 'till', 'status']"
          :expenses="expenses.filter((expense) => !expense.event)"
          :expensesTypes="expenseTypes"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import TravelExpensesUserCard from "./components/TravelExpensesUserCard.vue";

export default {
  components: {
    TravelExpensesUserCard,
  },
  data() {
    return {
      expenses: [],
      expenseTypes: [],
    };
  },

  methods: {
    async getExpensesTypes() {
      await axios.get("api/v1/travel_expenses/types/").then((response) => {
        this.expenseTypes = response.data.results;
      });
    },
    async getUserExpenses() {
      this.$store.commit("setIsLoading", true);
      await axios
        .get(
          `/api/v1/users/${this.$store.state.user.id}/travel_expenses/?fields=id,trip_reason,trip_start,trip_end,status,event,comment`
        )
        .then((response) => {
          this.expenses = response.data.results;
          this.$store.commit("setIsLoading", false);
        })
        .catch((error) => {
          this.$store.commit("setIsLoading", false);
          this.$store.dispatch("showAlert", {
            type: "error",
            message: error.response.data.message,
            timeout: 5000,
          });
        });
    },
  },
  created() {
    this.getUserExpenses();
    this.getExpensesTypes();
  },
};
</script>

<style scoped></style>
