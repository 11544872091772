<template>
  <AddExpenseModal @save="saveTravelExpense" :visible="ShowModal" v-if="ShowModal" />
  <TableCard
    :headers="headers"
    :tableItems="filteredUsers"
    searchBy="full_name"
    :sortAliases="{
      name: 'last_name',
      type: 'expense_type',
    }"
  >
    <template #cardHeader>
      <div class="mb-4">
        <select
          class="minimalistic-input"
          v-model="selectedStatus"
          @change="this.filterUsersByStatus"
        >
          <option
            v-for="status in [
              'all',
              'new',
              'pending',
              'accepted',
              'declined',
              'no_expense',
            ]"
            :key="status"
            :value="status"
          >
            {{ $t(status) }}
          </option>
        </select>
      </div>
    </template>

    <template #tableBody="slotItem">
      <td class="p-0">
        <user-button :item="slotItem.item" />
      </td>

      <td width="30%" class="align-middle text-center text-sm">
        <span v-if="slotItem.item.travel_expense">
          <span :class="this.statuses[slotItem.item.travel_expense.status]">
            {{ $t(slotItem.item.travel_expense.status) }}
          </span>
        </span>
        <span
          class="badge badge-sm text-bg-contrast bg-grey"
          v-else-if="!slotItem.item.travel_expense"
        >
          {{ $t("no_expense") }}
        </span>
      </td>

      <td
        width="30%"
        v-if="selectExpenseType && slotItem.item === selectedUser"
        class="align-middle text-center text-sm"
      >
        <select
          class="form-select form-select-sm w-50 mx-auto bg-transparent"
          aria-label=".form-select-sm example"
          v-model="slotItem.item.expense_type"
        >
          <option v-for="type in expenseTypes" :key="type.id" :value="type.type">
            {{ type.type }}
          </option>
        </select>
      </td>
      <td width="30%" v-else class="align-middle text-center text-sm text-bg-contrast">
        <small v-if="slotItem.item.travel_expense">
          {{ slotItem.item.travel_expense.type }}
        </small>
      </td>

      <td width="10%" class="align-middle text-center text-sm">
        <span v-if="selectExpenseType && slotItem.item === selectedUser">
          <button
            class="invisible-btn text-success"
            @click="saveTravelExpense(slotItem.item)"
          >
            <i class="fas fa-check"></i>
          </button>
          <button
            class="invisible-btn text-danger"
            @click="
              selectExpenseType = false;
              selectedUser = null;
              slotItem.item.expense_type = null;
            "
          >
            <i class="fas fa-times text-bg-contrast"></i>
          </button>
        </span>
        <button
          v-else-if="!slotItem.item.travel_expense"
          class="invisible-btn text-bg-contrast"
          @click="
            ShowModal = true;
            selectedUser = slotItem.item;
          "
        >
          <i class="fas fa-plus text-bg-contrast"></i>
        </button>
        <button
          v-else
          class="invisible-btn text-info"
          @click="
            this.$router.push(
              `/user/${slotItem.item.id}/travel-expenses/${slotItem.item.travel_expense.id}`
            )
          "
        >
          <i class="fas fa-info text-bg-contrast"></i>
        </button>
      </td>
    </template>
  </TableCard>
</template>

<script lang="js">
import UserButton from "@/components/UserButton.vue";
import AddExpenseModal from "@/components/alerts/AddExpenseModal.vue";
import TableCard from "@/components/TableCard.vue";
import { useStore } from "vuex";
import axios from "axios";

export default {
  name: "users-travelexpenses-table",
  components: {
    UserButton,
    TableCard,
    AddExpenseModal,
  },
  data() {
    return {
      ShowModal: false,
      headers: ["name", "status", "type"],
      event: {},
      users: [],
      usersWithExpenses: [],
      selectExpenseType: false,
      selectedUser: null,
      expenseTypes: [],
      selectedStatus: localStorage.getItem("EventExpensesSelectedStatus")
        ? localStorage.getItem("EventExpensesSelectedStatus")
        : "all",
      statuses:
      {
        "pending": "badge badge-sm bg-warning text-dark",
        "accepted": "badge badge-sm bg-success text-dark",
        "declined": "badge badge-sm bg-danger text-white",
        "new": "badge badge-sm bg-info text-dark",
      },
    };
  },
  setup() {
    return {
      getters: useStore().getters,
    };
  },
  computed: {

    sortedUsers() {
      return [...this.usersWithExpenses].sort((a, b) => {
        if (a.last_name < b.last_name) {
          return -1;
        }
        if (a.last_name > b.last_name) {
          return 1;
        }
        return 0;
      });
    },
    filteredUsers() {
      if (this.selectedStatus === "all") {
        return this.sortedUsers;
      } else if (this.selectedStatus === "no_expense") {
        return this.sortedUsers.filter((user) => !user.travel_expense);
      } else {
        return this.sortedUsers.filter(
          (user) => user.travel_expense && user.travel_expense.status === this.selectedStatus
        );
      }
    },
  },
  methods: {
    async getEvent() {
      this.$store.dispatch("getEventInfo", this.$route.params.id).then((resp) => {
        this.event = resp;
      });
    },
    async getUsers() {
      await this.$store.dispatch("getUsersFromAllPagesShort").then(() => {
        this.users = this.getters.getUsersShort;
      });
    },
    async parseUsersFromEvent() {
      const event_id = this.$route.params.id;
      await this.$store.dispatch("parseAssignedUsersFromEvent", event_id).then((resp) => {
        this.users = this.users.filter((user) => {
          return resp.find((eventUser) => eventUser.user_id === user.id);
        });
        this.updateUsersTravelExpenses()
      });
  },


    async getExpensesTypes() {
      await axios.get("api/v1/travel_expenses/types/").then((response) => {
        this.expenseTypes = response.data.results;
      });
    },

    saveTravelExpense(typeData) {
      console.log(typeData);
      const user = this.selectedUser;
      const type = typeData.expense_type
        ? typeData.expense_type
        : this.expenseTypes.find((type) => type.is_default).type;
      const expense = {
        user: user.id,
        event: this.$parent.event.name,
        type: type,
        cost_center: typeData.cost_center ? typeData.cost_center : '',
        trip_start: this.$parent.event.start_date,
        trip_end: this.$parent.event.end_date,
      };

      console.log(expense);
      axios
        .post(`api/v1/users/${user.id}/travel_expenses/`, expense)
        .then(() => {
          this.$store.dispatch("showAlert", {
            type: "success",
            message: this.$t("travel_expense_added"),
            timeout: 3000,
          });
          this.selectExpenseType = false;
          this.parseUsersFromEvent();
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("showAlert", {
            type: "danger",
            message: this.$t("travel_expense_not_added"),
            timeout: 3000,
          });
        });
    },

    async updateUsersTravelExpenses() {
      await axios
        .get(`api/v1/travel_expenses/?event=${this.$route.params.id}`)
        .then((response) => {
          this.usersWithExpenses = [...this.users];
          this.usersWithExpenses.forEach((user) => {
            response.data.results.forEach((expense) => {
              if (user.id === expense.user) {
                user.travel_expense = expense;
                user.expense_type = expense.expense_type;
              }
            });
          });

        });
    },

    sortUsersList() {
      this.users.sort((a, b) => {
        if (a.last_name < b.last_name) {
          return -1;
        }
        if (a.last_name > b.last_name) {
          return 1;
        }
        return 0;
      });
    },
    paginate() {
      this.$refs.tableChild.paginateByPage();
    },
  },
  mounted() {

    this.getUsers().then(() => {
      this.parseUsersFromEvent();
    });
  },
  created() {
    this.getExpensesTypes();
  },
};
</script>
