<template>
  <TableCard :tableItems="this.filteredEvents" :headers="this.headers" searchBy="name"
    filterPlaceholder="search_by_name">
    <template #cardHeader>
      <div class="row">
        <div class="col-md-5 col-sm-12 mb-4">
          <FilterSelection :items="['previous', 'next']" @filter="this.filterEvents" label="events" />
        </div>
        <div class="col-7 text-end mb-2">
          <slot name="headerButton"></slot>
        </div>
      </div>
    </template>

    <template #tableBody="slotItem">
      <td width="25%">
        <button v-if="this.isSuperuser()" class="invisible-btn text-bg-contrast full-size"
          @click="this.$router.push(`/events/${slotItem.item.id}`)">
          <p class="text-sm font-weight-thin mb-0">
            {{ slotItem.item.name }}
          </p>
        </button>
        <p v-else class="text-sm font-weight-thin mb-0">
          {{ slotItem.item.name }}
        </p>
      </td>
      <td v-for="el in ['location', 'start_date', 'end_date']" :key="el" class="text-center">
        <p class="text-sm font-weight-thin mb-0">
          {{ slotItem.item[el] }}
        </p>
      </td>
      <td class="text-center">
        <div class="mx-auto d-block">
          <span class="text-xs font-weight-bold ">{{ slotItem.item.percentage }}%</span>
          &nbsp;
          <span class="d-inline-block">
            <soft-progress color="warning"
              :percentage="parseInt(slotItem.item.percentage)" />
          </span>
        </div>
      </td>
      <slot name="eventButtons" :item="slotItem.item"></slot>
    </template>
  </TableCard>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import SoftProgress from "@/components/SoftProgress.vue";
import axios from "axios";
import TableCard from "@/components/TableCard.vue";
import FilterSelection from "@/components/inputs/FilterSelection.vue";
import { mapMutations, useStore } from "vuex";

export default {
  name: "events-card",
  components: {
    TableCard,
    FilterSelection,
    SoftProgress,
  },
  data() {
    return {
      headers: ["name", "location", "start_date", "end_date", "completion"],
      events: this.getters.getEventsShort,
      filteredEvents: [],
    };
  },

  setup() {
    return {
      getters: useStore().getters,
    };
  },
  methods: {
    ...mapMutations(["setEventsShort"]),

    async getEventData() {
      await axios
        .get(`api/v1/events/?fields=id,name,location,start_date,end_date,percentage`)
        .then((response) => {
          this.events = response.data.results;
          this.setEventsShort(this.events);
          this.filterEvents("all");
        });
    },

    filterEvents(value) {
      const today = new Date();

      if (value === "all") {
        this.filteredEvents = this.events;
      } else if (value === "previous") {
        this.filteredEvents = this.events.filter((event) => {
          const eventDate = new Date(event.start_date);
          return eventDate <= today;
        });
      } else if (value === "next") {
        this.filteredEvents = this.events.filter((event) => {
          const eventDate = new Date(event.start_date);
          return eventDate > today;
        });
      }
    },

    isSuperuser() {
      return this.$store.getters.isSuperuser;
    },
  },

  created() {
    setTooltip();
    if (this.isSuperuser()) {
      this.getEventData();
    }
  },
};
</script>
<style scoped>
.card {
  max-height: 70vh;
  overflow-y: hidden;
  overflow-x: auto;
}
</style>
