<template>
  <AlertDefault @event="closeModal" :msg="msg" :visible="visible">
    <template v-slot:body>
      <p class="text-center">
        <i class="fas fa-exclamation-triangle text-warning fa-3x"></i>
      </p>
      <p class="text-center">{{ $t(msg) }}</p>
      <!-- input  -->
      <div class="form-group">
        <input
          type="text"
          class="form-control text-white"
          v-model="declineMessage"
          :placeholder="$t('message')"
        />
      </div>
    </template>

    <template v-slot:footer>
      <button
        type="button"
        @click="confirm(this.declineMessage)"
        :class="'btn btn-outline-success'"
      >
        {{ $t("yes") }}
      </button>
      <button
        type="button"
        @click="closeModal"
        :class="'btn btn-outline-danger'"
      >
        {{ $t("no") }}
      </button>
    </template>
  </AlertDefault>
</template>

<script>
import AlertDefault from "./AlertDefault.vue";
export default {
  name: "PromptAlert",

  data() {
    return {
      ShowModal: false,
      declineMessage: "",
    };
  },
  methods: {
    closeModal() {
      this.declineMessage = "";
      this.$parent.ShowPrompt
        ? (this.$parent.ShowPrompt = false)
        : (this.$parent.ShowPrompt = true);
    },
    confirm(message) {
      this.$emit("confirm", message);
      this.closeModal();
    },
  },
  components: {
    AlertDefault,
  },
  props: {
    msg: String,
    visible: Boolean,
    inputString: String,
    variant: {
      type: String,
      default: "warning",
    },
  },
};
</script>
