<template>
  <AlertDefault @event="closeModal" :visible="visible">
    <template v-slot:title>
      <h5 class="modal-title text-white">{{ $t("edit_selected_weeks") }}</h5>
    </template>

    <template v-slot:body>
      <div class="container">
        <div class="row">
          <div class="col-4">
            <p class="text-white">{{ $t("select_days") }}*</p>
          </div>
          <div class="col-8">
            <span v-for="day in this.defaultWeek.weekdays" :key="day.status">
              <button
                @click="changeDayStatus(day)"
                class="badge mx-1"
                :class="dayClasses(day.status)"
              >
                {{ $t(this.weekdaysMap[day.day]) }}
              </button>
            </span>
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <p class="mx-4 text-sm text-white">*{{ $t("click to change status") }}</p>
          </div>

          <div class="col-8">
            <div
              v-for="(item, index) in statusLabel"
              :key="index"
              class="d-inline"
            >
              <div
                class="d-inline-block rounded-circle mx-2"
                :class="item.classname"
                style="width: 20px; height: 20px"
              ></div>
              <p class="text-sm d-inline-block text-white agenda">{{ $t(item.label) }}</p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <p class="text-white">
              {{ $t("earliest_start") }}
            </p>
          </div>
          <div class="col-8">
            <div class="row">
              <div class="col-6">
                <select
                  class="form-select"
                  v-model="defaultWeek.earliest_start.day"
                >
                  <option
                    v-for="day in this.defaultWeek.weekdays"
                    :key="this.weekdaysMap[day.day]"
                    :value="this.weekdaysMap[day.day]"
                  >
                    {{ $t(this.weekdaysMap[day.day]) }}
                  </option>
                </select>
              </div>
              <div class="col-6">
                <input
                  type="time"
                  class="form-control"
                  v-model="defaultWeek.earliest_start.time"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <p class="text-white">
              {{ $t("latest_end") }}
            </p>
          </div>
          <div class="col-8">
            <div class="row">
              <div class="col-6">
                <select
                  class="form-select"
                  v-model="defaultWeek.latest_end.day"
                >
                  <option
                    v-for="day in this.defaultWeek.weekdays"
                    :key="this.weekdaysMap[day.day]"
                    :value="this.weekdaysMap[day.day]"
                  >
                    {{ $t(this.weekdaysMap[day.day]) }}
                  </option>
                </select>
              </div>
              <div class="col-6">
                <input
                  type="time"
                  class="form-control"
                  v-model="defaultWeek.latest_end.time"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <p class="text-white">
              {{ $t("comment") }}
            </p>
          </div>
          <div class="col-8">
            <input
              type="text"
              class="form-control"
              v-model="defaultWeek.comment"
            />
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <button
        type="button"
        @click="saveItem"
        :class="'btn btn-outline-success'"
      >
        {{ $t("save") }}
      </button>
    </template>
  </AlertDefault>
</template>

<script lang="js">
import AlertDefault from "./AlertDefault.vue";
export default {
    name: "AvailabilityModal",

    data() {
        return {
            weekdaysMap: {
                monday: "Mon",
                tuesday: "Tue",
                wednesday: "Wed",
                thursday: "Thu",
                friday: "Fri",
                saturday: "Sat",
                sunday: "Sun",
            },
            ShowModal: false,
            defaultWeek: {
                weekdays: [
                    { day: "tuesday", status: "not_set" },
                    { day: "wednesday", status: "not_set" },
                    { day: "thursday", status: "not_set" },
                    { day: "friday", status: "not_set" },
                    { day: "saturday", status: "not_set" },
                    { day: "sunday", status: "not_set" },
                    { day: "monday", status: "not_set" },
                ],
                comment: "",
                earliest_start: {
                    day: "Tue",
                    time: "06:00",
                },
                latest_end: {
                    day: "Sun",
                    time: "23:00",
                },
            },
        };
    },
    computed: {
        dayClasses() {
            return status => {
                switch (status) {
                    case 'available':
                        return 'text-dark bg-success'
                    case 'unavailable':
                        return 'text-white bg-danger'
                    case 'not_set':
                        return 'text-dark bg-gray-300'
                    case 'not_sure':
                        return 'text-dark bg-warning'
                }
            }
        },
        statusLabel() {
            return [
                { label: 'available', classname: 'bg-success' },
                { label: 'unavailable', classname: 'bg-danger' },
                { label: 'not_sure', classname: 'bg-warning' },
                { label: 'not_set', classname: 'bg-gray-300' }
            ]
        }
    },
    methods: {
        closeModal() {
            this.$parent.editMode = false;
            this.$parent.ShowModal
                ? (this.$parent.ShowModal = false)
                : (this.$parent.ShowModal = true);
        },
        changeDayStatus(day) {
            switch (day.status) {
                case "not_set":
                    day.status = "available";
                    break;
                case "available":
                    day.status = "unavailable";
                    break;
                case "unavailable":
                    day.status = "not_sure";
                    break;
                case "not_sure":
                    day.status = "not_set";
                    break;
            }
        },
        saveItem() {
            this.$emit("save", this.defaultWeek);
            this.$parent.ShowModal = false;
        },
    },
    components: {
        AlertDefault,
    },
    props: {
        visible: Boolean,
    },
};
</script>
<style scoped>
.agenda {
  vertical-align: top;
}
.form-control {
  border-color: white;
}
</style>
