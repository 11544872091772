<template>
  <!-- TODO: fix tooltips on edit/delete buttons -->
  <div class="card h-200">
    <div class="p-3 pb-0 card-header">
      <div class="row">
        <div class="col-md-8 d-flex align-items-center">
          <h6 class="mb-0 text-bg-contrast">{{ title }}</h6>
        </div>
        <div class="col-md-4 text-end">
          <!-- button to add new license -->
          <button class="btn btn-sm btn-primary text-primary-contrast mb-n3" @click="addNewLicense">
            <span>{{ $t("add_license") }}</span>
          </button>
        </div>
      </div>
    </div>
    <div class="p-3 card-body">
      <hr />
      <div v-for="license in licenseData" :key="license" class="row">
        <div class="row">
          <div class="col-md-12 text-end">
            <button v-if="!license.editable" class="invisible-btn" @click="makeEditable(license)">
              <i class="text-sm fas fa-pen text-bg-contrast" data-bs-toggle="tooltip" data-bs-placement="top"
                :title="$t('edit')"></i>
            </button>

            <button v-if="license.editable" class="invisible-btn"
              @click="license.id ? patchLicense(license) : postLicense(license)">
              <i class="text-sm fas fa-check text-success" data-bs-toggle="tooltip" data-bs-placement="top"
                :title="$t('save')"></i>
            </button>
            <button v-if="license.editable" class="invisible-btn" @click="license.editable = false">
              <i class="text-sm fas fa-ban text-warning" data-bs-toggle="tooltip" data-bs-placement="top"
                :title="$t('cancel')"></i>
            </button>
            <!-- TODO: add modal window with delete confirmation -->
            <button class="invisible-btn" @click="deleteLicense(license)">
              <i class="text-sm fas fa-trash text-danger" data-bs-toggle="tooltip" data-bs-placement="top"
                :title="$t('delete')"></i>
            </button>
          </div>
        </div>
        <div class="col">
          <ul class="list-group">
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast">{{ $t("license_number") }}:</strong>
              &nbsp;
              <span v-if="!license.editable" class="text-primary fw-bold">{{
                license.license_number
              }}</span>
              <input v-if="license.editable" type="text" class="minimalistic-input"
                :placeholder="license.license_number" v-model="
                  updatedLicenseData[selectedLicenseIndex].license_number
                " />
            </li>
            <li class="text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast">{{ $t("license_stage") }}:</strong>
              &nbsp;
              <span v-if="!license.editable" class="text-primary fw-bold">{{
                license.license_stage
              }}</span>
              <select v-if="license.editable" class="minimalistic-input" :placeholder="license.license_stage"
                v-model="updatedLicenseData[selectedLicenseIndex].license_stage">
                <option v-for="category in licenseStages" :key="category" :value="category">
                  {{ category }}
                </option>
              </select>
            </li>
            <li class="text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast">{{ $t("license_validity") }}:</strong>
              &nbsp;
              <span v-if="!license.editable" class="text-primary fw-bold">{{ license.license_validity_month }}/{{
                license.license_validity_year
              }}</span>
              <!-- TODO: fix layout and put all inputs in a single row -->
              <div v-if="license.editable">
                <select class="minimalistic-input" v-model="
                  updatedLicenseData[selectedLicenseIndex]
                    .license_validity_month
                ">
                  <option v-for="month in [...Array(12).keys()].map((i) => i + 1)" :key="month" :value="month">
                    {{ month }}
                  </option>
                </select>
                <select class="minimalistic-input" v-model="
                  updatedLicenseData[selectedLicenseIndex]
                    .license_validity_year
                ">
                  <option v-for="year in [...Array(20).keys()].map(
                    (i) => i + new Date().getFullYear()
                  )" :key="year" :value="year">
                    {{ year }}
                  </option>
                </select>
              </div>
            </li>
          </ul>
        </div>
        <div class="col">
          <ul class="list-group">
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast">{{ $t("role") }}:</strong> &nbsp;

              <span v-if="!license.editable" class="text-primary fw-bold">{{ license.role }}</span>
              <select v-else class="minimalistic-input" :placeholder="license.role"
                v-model="updatedLicenseData[selectedLicenseIndex].role">
                <option v-for="role in licenseRoles" :key="role" :value="role">
                  {{ role }}
                </option>
              </select>
            </li>
            <FileDownloadElement
              text="license_scan"
              :mainObj="license"
              :fileUrl="license.license_scan"
              :fileType="'license_scan'"
              @getFile="getFile"
              @upload="handleFileUpload"
              />
          </ul>
        </div>
        <hr />
      </div>
    </div>
  </div>
</template>

<script>
import FileDownloadElement from "@/components/FileDownloadElement.vue";
import axios from "axios";

export default {
  name: "LicenseInfoCard",
  components: {
    FileDownloadElement,
  },

  data() {
    return {
      selectedLicenseIndex: 0,
      editable: false,
      licenseData: {},
      updatedLicenseData: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
      // TODO: get license roles from backend
      licenseRoles: ["TK", "SK", "LS", "RL"],
      licenseStages: ["A", "B", "D"],
    };
  },
  methods: {
    makeEditable(license) {
      license.editable = true;
      this.selectedLicenseIndex = this.licenseData.indexOf(license);
    },

    setLicenseData() {
      this.licenseData = this.info;
    },

    updateRoles(event) {
      const roles = event.target.value.split(",");

      for (let i = 0; i < roles.length; i++) {
        roles[i] = roles[i].trim();
      }

      if (roles.every((role) => this.licenseRoles.includes(role))) {
        const allRoles = roles.map((role) => {
          return { role: role };
        });

        this.updatedLicenseData[this.selectedLicenseIndex].roles = allRoles;
      }
    },

    async getFile(path) {
      const fileName = path.split("/").pop();
      await axios
        .get(`api/v1/files/${this.username}/${fileName}/`, {
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        });
    },

    handleFileUpload(event, license, fileType) {
      const index = this.licenseData.indexOf(license);
      this.updatedLicenseData[index][fileType] = event.target.files[0];
    },

    postLicense(license) {
      const index = this.licenseData.indexOf(license);
      const formData = new FormData();

      for (const [key, value] of Object.entries(
        this.updatedLicenseData[index]
      )) {
        if (key === "roles") {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value);
        }
      }

      axios
        .post(`api/v1/users/${this.user_id}/licenses/`, formData)
        .then((response) => {
          // this.licenseData[index] = this.updatedLicenseData[index];
          Object.keys(response.data).forEach((key) => {
            this.licenseData[index][key] = response.data[key];
          });
          this.licenseData[index].editable = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    patchLicense(license) {
      const index = this.licenseData.indexOf(license);

      Object.keys(this.updatedLicenseData[index]).forEach(
        (key) =>
          this.updatedLicenseData[index][key] === "" &&
          delete this.updatedLicenseData[index][key]
      );

      delete this.licenseData[index].editable;

      const formData = new FormData();
      for (const [key, value] of Object.entries(
        this.updatedLicenseData[index]
      )) {
        if (key === "roles") {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value);
        }
      }

      axios
        .patch(
          `api/v1/users/${this.user_id}/licenses/${this.licenseData[index].id}/`,
          formData
        )
        .then((response) => {
          Object.keys(response.data).forEach((key) => {
            this.licenseData[index][key] = response.data[key];
          });
          this.licenseData[index].editable = false;;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    addNewLicense() {
      const newLicense = {
        license_number: "",
        license_stage: "",
        license_validity_month: "",
        license_validity_year: "",
        roles: [],
        license_scan: "",
      };

      this.licenseData.push(newLicense);
      this.makeEditable(newLicense);
    },

    deleteLicense(license) {
      const index = this.licenseData.indexOf(license);
      if (license.id) {
        axios
          .delete(
            `api/v1/users/${this.user_id}/licenses/${this.licenseData[index].id}/`
          )
          .then(() => {
            this.licenseData.splice(index, 1);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.licenseData.splice(index, 1);
      }
    },
  },
  mounted() {
    this.setLicenseData();
  },

  props: {
    user_id: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      default: "profile",
    },
    username: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    info: {
      type: Object,
      default: () => { },
    },

    action: {
      type: Object,
      route: String,
      tooltip: String,
      default: () => ({
        route: "javascript:;",
      }),
    },
  },
};
</script>
