<template>
  <router-link
    v-if="navText !== 'admin_panel'"
    active-class="router-active"
    class="nav-link"
    :to="to"
    v-bind="$attrs"
    @click="toggleSidebar()"
  >
    <div
      class="text-center text-dark bg-secondary shadow icon icon-shape icon-sm border-radius-md d-flex align-items-center justify-content-center"
      :class="'me-2'"

    >
      <slot name="icon"></slot>
    </div>
    <span class="nav-link-text" :class="'ms-1'">{{
      $t(navText)
    }}</span>
  </router-link>

  <a
    :href="to"
    v-else
    active-class="router-active"
    class="nav-link"
    v-bind="$attrs"
    @click="toggleSidebar()"
  >
    <div
      class="text-center text-dark bg-secondary shadow icon icon-shape icon-sm border-radius-md d-flex align-items-center justify-content-center"
      :class="'me-2'"
    >
      <slot name="icon"></slot>
    </div>
    <span class="nav-link-text" :class="'ms-1'">{{
      $t(navText)
    }}</span>
  </a>
</template>
<script>
import { mapMutations } from "vuex";

export default {
  name: "sidenav-collapse",
  props: {
    to: {
      type: [Object, String],
      required: true,
    },
    navText: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapMutations(["navbarMinimize"]),

    toggleSidebar() {
      this.navbarMinimize();
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
};
</script>
<style scoped></style>
