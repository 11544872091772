import axios from "axios";

const jobs = {
  actions: {
    fetchJobs() {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/v1/jobs/")
          .then((response) => {
            return resolve(response.data);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
    addJob(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/api/v1/${data.type}/${data.elementId}/`, {
            jobs: data.jobs,
            update_championship_events: data.update_championship_events,
          })
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
    removeJob(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            `/api/v1/${data.type}/${data.elementId}/jobs/${data.jobId}/`,
            { 0: true }
          )
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
    patchUserJobs(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/api/v1/users/${data.userId}/`, { jobs: data.jobs })
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    }
  },
};

export default jobs;
