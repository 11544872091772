<template>
  <TableCard
    :headers="headers"
    :tableItems="this.users"
    searchBy="full_name"
    :sortAliases="{
      name: 'last_name',
      status: 'is_active',
      is_admin: 'is_superuser',
    }"
  >
    <template #cardHeader>
      <div class="row">
        <div class="col">
          <h6 class="text-bg-contrast">{{ $t("users_table") }}</h6>
        </div>
        <div class="col text-end">
          <button
            class="btn btn-xs btn-primary text-primary-contrast"
            @click="this.$router.push('/add-user')"
          >
            <i class="fa fa-user-plus"></i>
          </button>
        </div>
      </div>
    </template>

    <template #tableBody="slotItem">
      <td class="p-0">
        <user-button :item="slotItem.item" />
      </td>

      <td
        v-for="el in [
          slotItem.item.username,
          slotItem.item.company,
          slotItem.item.country,
        ]"
        :key="el"
        class="align-middle text-center"
      >
        <span class="text-uppercase text-white text-xs font-weight-bold">
          {{ el }}
        </span>
      </td>

      <td class="align-middle text-center text-sm">
        <UserActiveBadge :item="slotItem.item" />
      </td>
      <td class="align-middle text-center">
        <i
          class="fa fa-check text-success"
          v-if="slotItem.item.is_superuser"
        ></i>
        <i class="fa fa-times text-danger" v-else></i>
      </td>
    </template>
  </TableCard>
</template>

<script lang="js">
import UserButton from "@/components/UserButton.vue";
import UserActiveBadge from "@/components/UserActiveBadge.vue";
import TableCard from "@/components/TableCard.vue";
import { useStore } from "vuex";

export default {
  name: "users-table",
  components: {
    UserButton,
    UserActiveBadge,
    TableCard,
  },
  data() {
    return {
      headers: ["name", "username", "company", "country", "status", "is_admin"],
      users: this.getters.getUsers,
    };
  },
  setup() {
    return {
      getters: useStore().getters,
    };
  },
  methods: {
    async getUsers() {
      await this.$store.dispatch("getUsersFromAllPages").then(() => {
        this.users = this.getters.getUsers;
      });
    },

    sortUsersList() {
      this.users.sort((a, b) => {
        if (a.last_name < b.last_name) {
          return -1;
        }
        if (a.last_name > b.last_name) {
          return 1;
        }
        return 0;
      });
    },
    paginate() {
      this.$refs.tableChild.paginateByPage();
    },
  },
  mounted() {
    this.getUsers().then(() => {
      this.sortUsersList();
    });
  },
};
</script>
<style scoped>
.card {
  max-height: 87vh;
  overflow-y: hidden;
  overflow-x: auto;
}
</style>
