<template>
  <div class="card">
    <div class="p-3 pb-0 card-header">
      <div class="row">
        <div class="col-md-8 d-flex align-items-center">
          <h6 class="mb-0 text-primary fw-bold">{{ title }}</h6>
        </div>
        <div class="col-md-4 text-end">
          <button
            v-if="!this.editable"
            class="invisible-btn"
            @click="this.editable = true"
          >
            <i
              class="text-sm fas fa-user-edit text-bg-contrast"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :title="$t('edit')"
            ></i>
          </button>

          <button v-if="this.editable" class="invisible-btn" @click="patchProfile">
            <i
              class="text-sm fas fa-user-check text-success"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :title="$t('save')"
            ></i>
          </button>
          <button
            v-if="this.editable"
            class="invisible-btn"
            @click="this.editable = false"
          >
            <i
              class="text-sm fas fa-user-times text-danger"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :title="$t('cancel')"
            ></i>
          </button>
        </div>
      </div>
    </div>
    <div class="p-3 card-body">
      <div class="row flex-column-reverse flex-md-row">
        <div class="col">
          <ul class="list-group">
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast">{{ $t("username") }}:</strong> &nbsp;
              <span class="text-primary fw-bold">{{ user.username }}</span>
            </li>
            <li class="text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast">{{ $t("mobile") }}:</strong> &nbsp;
              <span v-if="!this.editable" class="text-primary fw-bold">{{
                user.mobile
              }}</span>
              <input
                v-if="this.editable"
                type="text"
                class="minimalistic-input"
                :placeholder="user.mobile"
                v-model="profile.mobile"
              />
            </li>
            <li class="text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast">{{ $t("email") }}:</strong> &nbsp;
              <span v-if="!this.editable" class="text-primary fw-bold">{{
                user.email
              }}</span>
              <input
                v-if="this.editable"
                type="text"
                class="minimalistic-input"
                :placeholder="user.email"
                v-model="profile.email"
              />
            </li>
            <li class="text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast">{{ $t("date_of_birth") }}:</strong>
              &nbsp;
              <span v-if="!this.editable" class="text-primary fw-bold">{{
                user.date_of_birth
              }}</span>
              <input
                v-if="this.editable"
                type="date"
                class="minimalistic-input"
                :placeholder="user.date_of_birth"
                v-model="profile.date_of_birth"
              />
            </li>
          </ul>
        </div>
        <div class="col">
          <ul class="list-group">
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast">{{ $t("address") }}:</strong> &nbsp;
              <span v-if="!this.editable" class="text-primary fw-bold">{{
                user.address
              }}</span>
              <input
                v-if="this.editable"
                type="text"
                class="minimalistic-input"
                :placeholder="user.address"
                v-model="profile.address"
              />
            </li>
            <li class="text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast">{{ $t("city") }}:</strong> &nbsp;
              <span v-if="!this.editable" class="text-primary fw-bold">{{
                user.city
              }}</span>
              <input
                v-if="this.editable"
                type="text"
                class="minimalistic-input"
                :placeholder="user.city"
                v-model="profile.city"
              />
            </li>
            <li class="text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast">{{ $t("zipcode") }}:</strong> &nbsp;
              <span v-if="!this.editable" class="text-primary fw-bold">{{
                user.zipcode
              }}</span>
              <input
                v-if="this.editable"
                type="text"
                class="minimalistic-input"
                :placeholder="user.zipcode"
                v-model="profile.zipcode"
              />
            </li>
            <li class="text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast">{{ $t("country") }}:</strong> &nbsp;
              <span v-if="!this.editable" class="text-primary fw-bold">{{
                user.country
              }}</span>
              <select
                v-if="this.editable"
                name="country"
                class="minimalistic-input"
                v-model="profile.country"
              >
                <option value="" disabled selected>
                  {{ $t("select_country") }}
                </option>
                <option
                  v-for="country in countries"
                  :key="country.code"
                  :value="country.code"
                >
                  {{ country.name }}
                </option>
              </select>
            </li>
          </ul>
        </div>

        <div class="col" v-if="this.admin_view">
          <ul class="list-group">
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast">{{ $t("last_login") }}:</strong> &nbsp;
              <span class="text-primary fw-bold">
                {{
                  user.last_login
                    ? new Date(user.last_login).toLocaleString()
                    : $t("never")
                }}
              </span>
            </li>
            <li class="text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast">{{ $t("is_active") }}:</strong> &nbsp;
              <i class="fa fa-check text-success" v-if="user.is_active"></i>
              <i class="fa fa-times text-danger" v-else></i>
            </li>
            <li class="text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast">{{ $t("is_staff") }}:</strong> &nbsp;
              <i class="fa fa-check text-success" v-if="user.is_staff"></i>
              <i class="fa fa-times text-danger" v-else></i>
            </li>
            <li class="text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast">{{ $t("is_superuser") }}:</strong>
              &nbsp;
              <i class="fa fa-check text-success" v-if="user.is_superuser"></i>
              <i class="fa fa-times text-danger" v-else></i>
            </li>
          </ul>
        </div>
      </div>

      <div v-if="user.clothes" class="row my-2">
        <div class="row">
          <strong class="text-primary">{{ $t("clothes_size") }}:</strong>
        </div>
        <div class="row">
          <div class="col">
            <strong class="text-bg-contrast">{{ $t("jacket") }}:</strong> &nbsp;
            <span v-if="!this.editable" class="text-primary fw-bold">{{
              user.clothes.jacket_size
            }}</span>
            <input
              v-else
              type="text"
              class="minimalistic-input"
              v-model="clothes.jacket_size"
            />
          </div>
          <div class="col">
            <strong class="text-bg-contrast">{{ $t("pants") }}:</strong> &nbsp;
            <span v-if="!this.editable" class="text-primary fw-bold">{{
              user.clothes.pants_size
            }}</span>
            <input
              v-else
              type="text"
              class="minimalistic-input"
              v-model="clothes.pants_size"
            />
          </div>
          <div class="col">
            <strong class="text-bg-contrast">{{ $t("shirt") }}:</strong> &nbsp;
            <span v-if="!this.editable" class="text-primary fw-bold">{{
              user.clothes.shirt_size
            }}</span>
            <input
              v-else
              type="text"
              class="minimalistic-input"
              v-model="clothes.shirt_size"
            />
          </div>
        </div>
      </div>
      <div v-else class="row mt-4">
        <!-- button to ad clothes sizes -->
        <div class="col">
          <button class="btn btn-primary text-primary-contrast" @click="addClothesSizes">
            {{ $t("add_clothes_sizes") }}
          </button>
        </div>
      </div>

      <div class="row mt-4">
        <div v-if="!drawSignature" class="col d-flex align-items-center">
          <div class="col">
            <strong class="text-bg-contrast">{{ $t("signature") }}:</strong> &nbsp;
            <img
              v-if="info.signature && this.signatureImg && !editable"
              :src="this.signatureImg"
              class="img-fluid"
              style="
                width: 200px;
                background-color: #aa9081;
                border: 1px solid darkorange;
                border-radius: 5px;
              "
            />
            <button
              v-if="!info.signature && !editable"
              class="invisible-btn mb-0 text-primary fw-bold"
              @click="drawSignature = true"
            >
              <i class="fa fa-pencil-alt"></i>
              {{ $t("draw_signature") }}
            </button>
            <button
              v-if="info.signature && editable"
              class="invisible-btn mb-0 text-primary fw-bold"
              @click="drawSignature = true"
            >
              <i class="fa fa-pencil"></i>
              {{ $t("edit_signature") }}
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <Signature
          @updateSignature="getSignature()"
          v-if="drawSignature"
          :key="this.info.signature"
        />
      </div>
    </div>
    <div class="card-footer pt-0">
      <div class="row">
        <div class="col text-start">
          <button
            class="invisible-btn mb-0 text-primary fw-bold"
            v-if="admin_view"
            @click="resetPassword()"
          >
            <i class="fa fa-key text-danger"></i> {{ $t("reset_password") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Signature from "@/components/Signature.vue";
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "ProfileInfoCard",
  components: {
    // FontAwesomeIcon,
    Signature,
  },
  data() {
    return {
      countries: this.$store.state.countries,
      user: {},
      profile: {},
      editable: false,
      admin_view: false,
      drawSignature: false,
      signatureImg: null,
      jacketSizes: ["XXS", "XS", "S", "M", "L", "XL", "XXL", "XXXL"],
      pantsSizes: ["48", "50", "52", "54", "56"],
      shirtSizes: [...Array(13).keys()].map((i) => (i + 36).toString()),
      clothes: {},
    };
  },

  methods: {
    async getSignature() {
      if (this.info.signature) {
        const filename = this.info.signature.split("/").pop();
        await axios
          .get(`/api/v1/files/${this.info.username}/${filename}/`, {
            responseType: "blob",
          })
          .then((res) => {
            const reader = new FileReader();
            reader.readAsDataURL(res.data);
            reader.onloadend = () => {
              this.signatureImg = reader.result;
            };
          });
      } else {
        this.$parent.getUser();
      }
    },

    async setUser() {
      this.user = this.info;
    },

    async resetPassword() {
      // TODO: add confirmation alert
      await axios
        .post(`/api/v1/users/${this.user.id}/reset_password/`)
        .then(() => {
          this.$store.dispatch("showAlert", {
            type: "success",
            message: this.$t("password_reset_success"),
            timeout: 5000,
          });
        })
        .catch((err) => {
          let errMessage = this.$t("password_reset_error");
          if (
            err.response.data &&
            err.response.data[0] ===
              "User is synchronized with Active Directory. Password cannot be reset."
          ) {
            errMessage = this.$t("password_reset_error_active_directory");
          }

          this.$store.dispatch("showAlert", {
            type: "error",
            message: errMessage,
            timeout: 5000,
          });
        });
    },
    async addClothesSizes() {
      const data = {
        userId: this.info.id,
        data: {},
      };
      this.$store.dispatch("createUserClothesObj", data).then((resp) => {
        this.clothes = resp;
        this.user.clothes = resp;
      });
    },

    async patchClothesSizes() {
      const data = {
        userId: this.info.id,
        data: this.clothes,
      };
      console.log(data);
      this.$store.dispatch("patchUserClothesObj", data).then((resp) => {
        this.clothes = resp;
      });
    },

    async patchProfile() {
      Object.keys(this.profile).forEach(
        (key) => this.profile[key] === "" && delete this.profile[key]
      );
      try {
        this.patchClothesSizes();
        const data = {
          userId: this.info.id,
          data: this.profile,
        };
        this.$store.dispatch("patchUserProfile", data).then((resp) => {
          this.editable = false;
          Object.keys(resp).forEach((key) => {
            this.user[key] = resp[key];
          });
          this.$store.dispatch("showAlert", {
            type: "success",
            message: this.$t("profile_updated"),
            timeout: 5000,
          });
        });
      } catch (error) {
        this.$store.dispatch("showAlert", {
          type: "error",
          message: this.$t("profile_update_error"),
          timeout: 5000,
        });
      }
    },
  },

  props: {
    type: {
      type: String,
      default: "profile",
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    info: {
      type: Object,
      fullName: String,
      mobile: String,
      email: String,
      country: String,
      default: () => {},
    },
    social: {
      type: Array,
      link: String,
      icon: String,
      default: () => [],
    },
    action: {
      type: Object,
      route: String,
      tooltip: String,
      default: () => ({
        route: "javascript:;",
      }),
    },
  },
  mounted() {
    this.setUser().then(() => {
      this.getSignature();
    });

    if (this.$store.state.user.is_superuser) {
      this.admin_view = true;
    }
  },
};
</script>
<style scoped>
.card {
  min-height: 40vh;
}
</style>
