<template>
  <div class="card">
    <div class="card-header pb-0">
      <slot name="cardHeader">Fallback Header</slot>

      <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6">
          <input
            class="form-control text-bg-contrast form-control-sm mb-1"
            type="text"
            :placeholder="$t(filterPlaceholder)"
            v-model="name_filter"
          />
        </div>
        <div class="col-lg-2 col-md-2 col-sm-6 col-xs-6 text-end">
          <span class="text-sm text-bg-contrast">{{ $t("per_page") }}: </span>
          <label>
            <select
              class="form-control form-control-sm mb-1 text-bg-contrast"
              v-model="this.perPage"
            >
              <option
                v-for="value in this.paginatorItems"
                :key="value"
                :value="value"
              >
                {{ value }}
              </option>
            </select>
          </label>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-end">
          <div class="pagination text-sm text-primary-contrast">
            <a v-if="this.page !== 1" @click="this.page -= 1">&laquo;</a>
            <a v-else disabled style="color: grey">&laquo;</a>
            <span v-if="getTotalPages() <= 5">
              <span
                v-for="page in [...Array(getTotalPages()).keys()].map(
                  (i) => i + 1
                )"
                :key="page"
              >
                <a
                  v-if="this.page === page"
                  class="active"
                  @click="this.page = page"
                  >{{ page }}</a
                >
                <a v-else @click="this.page = page">{{ page }}</a>
              </span>
            </span>
            <span v-else>
              <a v-if="this.page !== 1" @click="this.page = 1">1</a>
              <a
                v-if="this.page !== 2 && this.page !== 1"
                disabled
                style="color: black"
                >...</a
              >
              <a class="active" @click="this.page = this.page">
                {{ this.page }}
              </a>
              <a
                v-if="this.page + 1 < getTotalPages()"
                @click="this.page = this.page + 1"
              >
                {{ this.page + 1 }}
              </a>
              <a
                v-if="this.page < getTotalPages() - 1"
                disabled
                style="color: black"
                >...</a
              >
              <a
                v-if="this.page !== getTotalPages()"
                @click="this.page = getTotalPages()"
                >{{ getTotalPages() }}</a
              >
            </span>
            <a v-if="this.page !== getTotalPages()" @click="this.page += 1"
              >&raquo;</a
            >
            <a v-else disabled style="color: black">&raquo;</a>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <div class="table-responsive p-0 tableFixHead">
        <table class="table align-items-center" style="margin-bottom: 15rem">
          <thead class="text-center">
            <tr>
              <slot name="selectAll"></slot>
              <th
                v-for="header in headers"
                :key="header"
                class="text-uppercase text-primary-dark text-xxs font-weight-bolder opacity-7 px-0"
              >
                <button
                  class="invisible-btn text-xs text-uppercase fw-bold text-table-header "
                  @click="sortTable(header, true)"
                >
                  {{ $t(header) }}
                  <span
                    v-if="
                      this.sortColumn === header ||
                      this.sortColumn == this.sortAliases[header]
                    "
                  >
                    <i
                      v-if="this.sortDirection === 'asc'"
                      class="fas fa-sort-down"
                    ></i>
                    <i v-else class="fas fa-sort-up"></i>
                  </span>
                </button>
              </th>
            </tr>
          </thead>
          <tbody v-if="tableItems.length" class="overflow-auto">
            <tr v-for="item in this.paginateByPage()" :key="item.id">
              <slot name="tableBody" :item="item"></slot>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang="js">
export default {
  name: "TableCard",
  data() {
    return {
      page: 1,
      perPage: 10,
      name_filter: "",
      paginatorItems: [5, 10, 20, 25, 50, 100],
      sortColumn: this.headers[0],
      sortDirection: "asc",
      items: this.tableItems,
    };
  },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    tableItems: {
      default: () => [],
    },
    searchBy: {
      type: String,
      default: "name",
    },
    filterPlaceholder: {
      type: String,
      required: false,
      default: "search_by_name",
    },
    sortAliases: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    tableItems: {
      handler: function (newVal) {
        this.items = newVal;
      },
      deep: true,
    },
  },

  created() {
    this.sortTable(this.sortColumn);
  },


  methods: {
    sortTable(header, change_direction = false) {
      let selectedHeader = header;

      if (header in this.sortAliases) {
        this.sortColumn = this.sortAliases[header];
        selectedHeader = this.sortAliases[header];
      } else {
        this.sortColumn = header;
      }

      if (change_direction) {
        if (this.sortColumn === selectedHeader) {
          this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
        }
      }
      if (this.items.length > 0) {
        this.items.sort((a, b) => {
          let modifier = 1;
          if (this.sortDirection === "desc") modifier = -1;

          if (a[this.sortColumn] < b[this.sortColumn]) return -1 * modifier;
          if (a[this.sortColumn] > b[this.sortColumn]) return 1 * modifier;
          return 0;
        });
      }

    },

    filterByString() {
      if (this.name_filter.length > 0) {
        this.page = 1;
        return this.items.filter((item) => {
          return item[this.$props.searchBy]
            .toLowerCase()
            .includes(this.name_filter.toLowerCase());
        });
      } else {
        return this.items;
      }
    },

    paginateByPage() {
      return this.filterByString().slice(
        (this.page - 1) * this.perPage,
        this.page * this.perPage
      );
    },

    getTotalPages() {
      return Math.ceil(this.filterByString().length / this.perPage);
    },
  },
};
</script>

<style scoped>
.card {
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: auto;
}
</style>
