<template>
  <div v-if="selectedType" class="py-4 container-fluid">
    <AdacExpense
      v-if="selectedType.type === 'ADAC'"
      :type="selectedType"
      :countries="countriesAllowances"
      :travelExpense="expense"
      :expenseDates="dates"
    />
    <LeomaxExpense
      v-if="['Leomax', 'x4in', 'sms'].includes(selectedType.type)"
      :type="selectedType"
      :countries="countriesAllowances"
      :travelExpense="expense"
      :expenseDates="dates"
      :user="user"
      :signatureImg="signatureImg"
      :format="selectedType.type"
    />
  </div>
</template>

<script>
import axios from "axios";
import AdacExpense from "./components/AddExpensesTypes/AdacExpense";
import LeomaxExpense from "./components/AddExpensesTypes/LeomaxExpense";

export default {
  data() {
    return {
      selectedType: {},
      types: [],
      countriesAllowances: [],
      expense: {},
      dates: {},
      user: {},
      signatureImg: null,
    };
  },
  components: {
    AdacExpense,
    LeomaxExpense,
  },

  methods: {
    getEventFunction(user_id) {
      this.$store
        .dispatch("parseAssignedUsersFromEvent", this.expense.eventId)
        .then((users) => {
          let result = "";
          let user_jobs = users.filter((user) => user.user_id == user_id);
          user_jobs.forEach((user_job) => {
            result += user_job.job + ", ";
          });
          this.expense.function = result.slice(0, -2);
        });
    },

    addMissedData() {
      if (!this.expense.function || this.expense.function === "") {
        this.getEventFunction(this.user_id);
      }
      if (this.expense.trip_start && this.expense.trip_end) {
        this.dates = {
          startDate: this.expense.trip_start.split("T")[0],
          startTime: this.expense.trip_start.split("T")[1].slice(0, -3),

          endDate: this.expense.trip_end.split("T")[0],
          endTime: this.expense.trip_end.split("T")[1].slice(0, -3),
        };
      } else {
        this.dates = {
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
        };
      }
      if (this.expense.type === "ADAC") {
        if (!this.expense.transport_expenses) {
          this.expense.transport_expenses = [
            { type: "car", price: 0, distance: 0 },
            { type: "public_transport", price: 0, distance: 0 },
          ];
        } else {
          // find the transport expenses for car and public transport
          let car = this.expense.transport_expenses.find(
            (transport) => transport.type === "car"
          );
          let public_transport = this.expense.transport_expenses.find(
            (transport) => transport.type === "public_transport"
          );
          if (!car) {
            this.expense.transport_expenses.push({
              type: "car",
              price: 0,
              distance: 0,
            });
          }
          if (!public_transport) {
            this.expense.transport_expenses.push({
              type: "public_transport",
              price: 0,
              distance: 0,
            });
          }
        }
      }

      if (
        !this.expense.allowance_expenses ||
        this.expense.allowance_expenses.length === 0
      ) {
        this.expense.allowance_expenses = {};
      }
      if (!this.expense.other_expenses || this.expense.other_expenses.length === 0) {
        this.expense.other_expenses = [{ description: "", price: 0 }];
      }
      this.getExpenseTypeDetails(this.expense.type);
      if (!this.expense.trip_reason) {
        this.expense.trip_reason = this.expense.event;
      }
    },

    async getExpense() {
      const data = {
        userId: this.user_id,
        expenseId: this.$route.params.id,
      };

      this.$store
        .dispatch("getExpense", data)
        .then((response) => {
          this.expense = response;
          this.addMissedData();
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("showAlert", {
            type: "error",
            message: "Error while getting expense",
            timeout: 5000,
          });
        });
    },

    async getCountriesAllowances() {
      const currentYear = new Date().getFullYear();
      await axios
        .get(
          `/api/v1/travel_expenses/countries_allowance/?page_size=250&year=${currentYear}`
        )
        .then((response) => {
          this.countriesAllowances = response.data.results;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getExpenseTypeDetails(type) {
      await axios
        .get(`/api/v1/travel_expenses/types/?type=${type}`)
        .then((response) => {
          this.selectedType = response.data.results[0];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getUserData() {
      await axios
        .get(
          `/api/v1/users/${this.user_id}/?fields=bank_data,address,city,zipcode,country,full_name,signature,username`
        )
        .then((response) => {
          this.user = response.data;
          this.user.id = this.user_id;
          if (this.user.signature) {
            this.getSignature(this.user.signature);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getSignature(signaturePath) {
      if (signaturePath) {
        const filename = signaturePath.split("/").pop();
        await axios
          .get(`/api/v1/files/${this.user.username}/${filename}/`, {
            responseType: "blob",
          })
          .then((res) => {
            const reader = new FileReader();
            reader.readAsDataURL(res.data);
            reader.onloadend = () => {
              this.signatureImg = reader.result;
            };
          });
      }
    },
  },
  created() {
    this.getCountriesAllowances();
    if (this.$route.params.user_id) {
      this.user_id = this.$route.params.user_id;
    } else {
      this.user_id = this.$store.state.user.id;
    }
    if (this.$route.params.id) {
      this.getExpense();
      this.getUserData();
    }
  },
};
</script>

<style scoped></style>
