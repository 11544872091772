<template>
  <BaseExpense :title="this.format">
    <template v-slot:pdf-content>
      <div class="container w-90 mt-6">
        <div class="row">
          <div class="col-3">
            <span v-if="this.format === 'Leomax'">
              <img
                src="@/assets/img/logos/leomax_logo.svg"
                alt="leomax logo"
                class="w-100"
              />
            </span>
            <span v-if="this.format === 'x4in'">
              <img
                src="@/assets/img/logos/x4in_logo.jpg"
                alt="x4in logo"
                style="width: 200px"
              />
            </span>
            <span v-if="this.format === 'sms'">
              <img src="@/assets/img/logos/sms_logo.svg" alt="adac logo" class="w-100" />
            </span>
          </div>
          <div class="col-9">
            <span class="mx-6 title">
              {{ this.$t("travel_expense") }}
            </span>
          </div>
        </div>
        <div class="row">
          <table class="mytable mytable-body mt-2">
            <tr>
              <td width="30%">Name, Vorname:</td>
              <td width="35%" colspan="2">{{ user.full_name }}</td>
            </tr>
            <tr>
              <td width="30%">Abteilung, Funktion:</td>
              <td width="35%" colspan="2">
                <span v-if="!isEditable">{{
                  expense.function ? expense.function : ""
                }}</span>
                <input
                  v-else
                  type="text"
                  class="minimalistic-input w-100 text-dark"
                  v-model="this.expense.function"
                />
              </td>
            </tr>
            <tr>
              <td width="30%">Reiseweg:</td>

              <td width="35%" colspan="2">
                <span v-if="!isEditable">{{ expense.route ? expense.route : "" }}</span>
                <input
                  v-else
                  type="text"
                  class="minimalistic-input w-100 text-dark"
                  v-model="this.expense.route"
                />
              </td>
            </tr>
            <tr>
              <td width="30%">Bestimmungsland:</td>

              <td width="35%" colspan="2">
                <span v-if="!isEditable">{{ expense.country }}</span>
                <select v-else class="minimalistic-input" v-model="this.expense.country">
                  <option
                    class="text-dark"
                    v-for="item in countries"
                    :value="item.country"
                    :key="item"
                  >
                    {{ item.country }}
                  </option>
                </select>
              </td>
            </tr>
            <tr>
              <td width="30%">Reiseanlass:</td>

              <td width="35%" colspan="2">
                <span v-if="!isEditable">{{
                  expense.trip_reason ? expense.trip_reason : ""
                }}</span>
                <input
                  v-else
                  type="text"
                  class="minimalistic-input w-100 text-dark"
                  v-model="this.expense.trip_reason"
                />
              </td>
            </tr>
            <tr>
              <td width="30%">Reisebeginn:</td>
              <td width="35%">
                <span v-if="!isEditable">{{ dates.startDate }}</span>
                <input
                  v-else
                  type="date"
                  class="minimalistic-input w-100 text-dark"
                  v-model="this.dates.startDate"
                />
              </td>
              <td width="35%">
                <span v-if="!isEditable">{{ dates.startTime }}</span>
                <input
                  v-else
                  type="time"
                  class="minimalistic-input w-100 text-dark"
                  v-model="this.dates.startTime"
                />
              </td>
            </tr>
            <tr>
              <td width="30%">Reiseende:</td>
              <td width="35%">
                <span v-if="!isEditable">{{ dates.endDate }}</span>
                <input
                  v-else
                  type="date"
                  class="minimalistic-input w-100 text-dark"
                  v-model="this.dates.endDate"
                />
              </td>
              <td width="35%">
                <span v-if="!isEditable">{{ dates.endTime }}</span>
                <input
                  v-else
                  type="time"
                  class="minimalistic-input w-100 text-dark"
                  v-model="this.dates.endTime"
                />
              </td>
            </tr>
          </table>
        </div>

        <!-- Fahrtkosten -->

        <div class="row mt-2">
          <div class="col p-0 text-xl fw-bold text-dark">
            Fahrtkosten, Transportkosten
          </div>
        </div>
        <div class="row">
          <table
            v-for="transport_type in ['car', 'public_transport', 'other']"
            :key="transport_type"
            class="mytable mytable-body"
            :style="transport_type !== 'car' ? 'border-top: 0' : ''"
          >
            <tr>
              <td width="25%" colspan="4">
                {{ $t("type_" + transport_type) }}
                <IconBtn
                  v-if="isEditable"
                  icon="fa fa-plus"
                  @click="addExpenseByType(transport_type)"
                  class="text-end"
                />
              </td>
            </tr>

            <tr v-for="item in getExpensesByType(transport_type)" :key="item">
              <td width="75%">
                <span v-if="transport_type === 'car'">
                  <span v-if="!isEditable">{{ item.distance }}</span>
                  <input
                    v-else
                    type="number"
                    class="minimalistic-input w-100 text-dark"
                    min="0"
                    v-model="item.distance"
                    @change="item.price = item.distance * type.price_per_rest_km"
                  />
                  km (a - {{ type.price_per_rest_km }} €)
                </span>
                <span v-else>
                  <span v-if="!isEditable">{{ item.description }}</span>
                  <input
                    v-else
                    type="text"
                    class="minimalistic-input w-100 text-dark"
                    v-model="item.description"
                  />
                </span>
              </td>
              <td :width="'25%' ? !isEditable : '15%'">
                <span v-if="!isEditable || transport_type === 'car'">{{
                  item.price || 0.0
                }}</span>
                <span v-else>
                  <input
                    type="number"
                    class="minimalistic-input w-100 text-dark"
                    min="0"
                    v-model="item.price"
                  />
                </span>
                €
              </td>
              <td v-if="isEditable" width="10%" class="text-center">
                <IconBtn
                  icon="fa fa-minus"
                  @click="deleteExpenseByType(transport_type, item)"
                />
              </td>
            </tr>
            <tr v-if="getExpensesByType(transport_type).length === 0">
              <td width="25%" colspan="4" class="text-center">-Keine Ausgaben-</td>
            </tr>
          </table>
        </div>

        <!-- Verpflegungskosten -->

        <div class="row mt-2">
          <div class="col p-0 text-xl fw-bold text-dark">
            Verpflegungskosten, Pauschalbeträge
          </div>
        </div>
        <div class="row mt-2">
          <table
            v-if="expense.country"
            class="mytable mytable-body"
            :key="expense.country"
          >
            <tr>
              <td width="40%">An- u. Abreisetag</td>
              <td width="20%" class="text-center">
                {{ getSelectedCountry().allowance_8h }} €
              </td>
              <td v-if="!isEditable" width="20%" class="text-center">
                {{ expense.allowance_expenses.amount_8h || 0.0 }} Tage
              </td>
              <td v-else width="20%" class="text-center">
                <input
                  type="number"
                  class="minimalistic-input w-100 text-dark"
                  min="0"
                  v-model="expense.allowance_expenses.amount_8h"
                  @change="
                    expense.allowance_expenses.total_price_8h =
                      expense.allowance_expenses.amount_8h *
                      getSelectedCountry().allowance_8h
                  "
                />
                Tage
              </td>

              <td width="20%" class="text-center">
                {{ expense.allowance_expenses.total_price_8h || 0.0 }} €
              </td>
            </tr>
            <tr>
              <td width="40%">Volle 24 Std.</td>
              <td width="20%" class="text-center">
                {{ getSelectedCountry().allowance_24h }} €
              </td>
              <td v-if="!isEditable" width="20%" class="text-center">
                {{ expense.allowance_expenses.amount_24h || 0.0 }} Tage
              </td>
              <td v-else width="20%" class="text-center">
                <input
                  type="number"
                  class="minimalistic-input w-100 text-dark"
                  min="0"
                  v-model="expense.allowance_expenses.amount_24h"
                  @change="
                    expense.allowance_expenses.total_price_24h =
                      expense.allowance_expenses.amount_24h *
                      getSelectedCountry().allowance_24h
                  "
                />
                Tage
              </td>
              <td width="20%" class="text-center">
                {{ expense.allowance_expenses.total_price_24h || 0.0 }} €
              </td>
            </tr>
          </table>
          <table v-else class="mytable mytable-body">
            <tr>
              <td width="100%">Bitte wählen Sie zuerst das Bestimmungsland</td>
            </tr>
          </table>
        </div>

        <!-- Overnight -->

        <div class="row mt-2">
          <div class="col p-0 text-xl fw-bold text-dark">Übernachtung</div>
        </div>
        <div class="row mt-2">
          <table class="mytable mytable-body">
            <tr>
              <td width="75%">Übernachtungskosten</td>
              <td v-if="!isEditable" width="25%" class="text-center">
                {{ expense.overnight_expenses || 0.0 }} €
              </td>
              <td v-else width="25%" class="text-center">
                <input
                  type="number"
                  class="minimalistic-input w-100 text-dark"
                  min="0"
                  v-model="expense.overnight_expenses"
                />
                €
              </td>
            </tr>
          </table>
        </div>

        <!-- Other expenses -->

        <div class="row mt-2">
          <div class="col p-0 text-xl fw-bold text-dark">Nebenkosten</div>
          <div class="col text-dark text-end">
            <IconBtn
              v-if="isEditable"
              icon="fa fa-plus"
              @click="addExpenseByType('other_expense')"
            />
          </div>
        </div>

        <div class="row mt-2">
          <table class="mytable mytable-body">
            <tr v-for="item in expense.other_expenses" :key="item">
              <td width="75%">
                <span v-if="!isEditable">{{ item.description }}</span>
                <input
                  v-else
                  type="text"
                  class="minimalistic-input w-100 text-dark"
                  v-model="item.description"
                />
              </td>
              <td :width="'25%' ? !isEditable : '15%'">
                <span v-if="!isEditable">{{ item.price || 0.0 }}</span>
                <input
                  v-else
                  type="number"
                  class="minimalistic-input w-100 text-dark"
                  min="0"
                  v-model="item.price"
                />
                €
              </td>
              <td v-if="isEditable" width="10%" class="text-center">
                <IconBtn
                  icon="fa fa-minus"
                  @click="deleteExpenseByType('other_expense', item)"
                />
              </td>
            </tr>
            <tr v-if="!expense.other_expenses || expense.other_expenses.length === 0">
              <td width="25%" colspan="4" class="text-center">-Keine Ausgaben-</td>
            </tr>
          </table>
        </div>

        <!-- Bank data and summary -->
        <div class="row mt-4">
          <div class="col" style="padding: 0 1em 0 0">
            <table v-if="user.bank_data" class="mytable mytable-body">
              <tr>
                <td width="25%">Kontoinhaber:</td>
                <td width="75%">
                  {{ user.bank_data.holder_name }}
                </td>
              </tr>
              <tr>
                <td width="25%">Bank:</td>
                <td width="75%">
                  {{ user.bank_data.bank_name }}
                </td>
              </tr>
              <tr>
                <td width="25%">IBAN:</td>
                <td width="75%">{{ user.bank_data.iban }}</td>
              </tr>
              <tr>
                <td width="25%">BIC:</td>
                <td width="75%">{{ user.bank_data.bic }}</td>
              </tr>
            </table>
            <table v-else class="mytable mytable-body">
              <tr>
                <td width="100%" class="text-center">
                  <span class="text-danger text-center">
                    <i class="fas fa-exclamation-triangle"></i>
                    Bitte füllen Sie Ihre Bankdaten aus, um diese Rechnung zu erstellen.
                  </span>
                  <button
                    class="btn btn-sm btn-primary text-primary-contrast"
                    @click="this.$router.push(`/users/${user_id}`)"
                  >
                    Bankdaten ausfüllen
                  </button>
                </td>
              </tr>
            </table>
          </div>
          <div class="col" style="padding: 0 0 0 1em">
            <table class="mytable mytable-body">
              <tr>
                <td width="50%">Summe:</td>
                <td width="50%" class="text-center">{{ this.convertToFixed(this.totalSumm) }} €</td>
              </tr>
              <tr>
                <td width="50%">Vorschuss:</td>
                <td width="50%" class="text-center">
                  <span v-if="!isEditable">{{ this.convertToFixed(expense.advance) || 0.0 }}</span>
                  <input
                    v-else
                    type="number"
                    class="minimalistic-input w-100 text-dark"
                    min="0"
                    v-model="expense.advance"
                  />
                  €
                </td>
              </tr>
              <tr>
                <td width="50%">Aus/Rückzahlung:</td>
                <td width="50%" class="text-center">
                  {{ this.convertToFixed(this.totalSummWithoutAdvance) }} €
                </td>
              </tr>
            </table>
          </div>
        </div>

        <!-- Place, Date and signature -->

        <div class="row mt-6 text-black">
          <div class="col">
            {{ user.city }}
            <hr class="m-0" />
            <span class="text-xs">Ort</span>
          </div>

          <div class="col">
            <span v-if="expense.signed_date">
              {{ reformatDate(expense.signed_date, "date") }}
            </span>
            <span v-else>
              {{
                new Date().toLocaleDateString("de-DE", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })
              }}
            </span>
            <hr class="m-0" />
            <span class="text-xs">Datum</span>
          </div>
          <div class="col">
            <button
              v-if="!this.signature"
              class="btn btn-sm btn-primary text-primary-contrast mb-0"
              @click="this.$router.push(`/users/${this.$route.params.user_id}`)"
            >
              Unterschrift hinzufügen
            </button>
            <span v-else>
              <img
                :src="this.signature"
                style="width: 250px; height: 100px; margin-top: -3rem"
              />
              <hr style="margin: -26px 0 0 0" />
              <span class="text-xs">Unterschrift Reisender</span>
            </span>
          </div>
        </div>

        <!-- Admin information -->

        <div class="row mt-4 text-black">
          <table class="mytable mytable mytable-body">
            <tr>
              <td width="25%" colspan="4" class="fw-bold text-center">
                Reisekostenabrechnung
              </td>
            </tr>
            <tr>
              <td width="25%">Genehmigt am:</td>
              <td width="25%">
                {{ reformatDate(expense.accepted_date, "datetime") }}
              </td>
              <td width="25%">Gezahlt am:</td>
              <td width="25%">
                {{ reformatDate(expense.paid_date, "datetime") }}
              </td>
            </tr>
            <tr>
              <td width="25%">Genehmigt durch:</td>
              <td width="25%">{{ expense.accepted_by }}</td>
              <td width="25%">Gezahlt durch:</td>
              <td width="25%">{{ expense.paid_by }}</td>
            </tr>
            <tr>
              <td width="25%">Kostenstelle:</td>
              <td width="25%" colspan="3">{{ expense.cost_center }}</td>
            </tr>
          </table>
        </div>
      </div>
    </template>
  </BaseExpense>
</template>

<script>
import BaseExpense from "./BaseExpense.vue";
import IconBtn from "@/components/buttons/IconBtn.vue";

export default {
  components: {
    BaseExpense,
    IconBtn,
  },
  props: {
    travelExpense: {
      type: Object,
      default: () => {},
    },
    user: {
      type: Object,
      default: () => {},
    },
    expenseDates: {
      type: Object,
      default: () => {},
    },
    countries: {
      type: Array,
      default: () => [],
    },
    type: {
      type: Object,
      default: () => {},
    },
    signatureImg: {
      type: String,
      required: false,
    },
    format: {
      type: String,
      default: "leomax",
    },
  },
  data() {
    return {
      isEditable: false,
      ShowPrompt: false,
      promptMsg: "",
      promptInputString: "",
      ShowModal: false,
      dates: {},
      expense: {},
    };
  },
  computed: {
    totalSumm: function () {
      let totalSumm = 0;
      if (this.expense.transport_expenses) {
        this.expense.transport_expenses.forEach((expense) => {
          totalSumm += parseFloat(expense.price);
        });
      }
      if (this.expense.other_expenses) {
        this.expense.other_expenses.forEach((expense) => {
          totalSumm += parseFloat(expense.price);
        });
      }
      totalSumm += parseFloat(this.expense.overnight_expenses) || 0.0;
      totalSumm += parseFloat(this.expense.allowance_expenses.total_price_8h) || 0.0;
      totalSumm += parseFloat(this.expense.allowance_expenses.total_price_24h) || 0.0;
      return totalSumm || 0.0;
    },
    totalSummWithoutAdvance: function () {
      let advance = parseFloat(this.expense.advance) || 0.0;
      return this.totalSumm - advance || 0.0;
    },
    signature: function () {
      return this.signatureImg;
    },
  },
  methods: {
    convertToFixed(num) {
      try {
        return parseFloat(num).toFixed(2);
      } catch (error) {
        return "0.00";
      }
    },
    reformatDate(date, format) {
      if (!date) return "";
      // reformat date from "2023-02-17T11:46:17.698666" to "17.02.2023 11:46:17"
      if (format === "date") {
        return date.split("T")[0].split("-").reverse().join(".");
      } else if (format === "datetime") {
        return (
          date.split("T")[0].split("-").reverse().join(".") +
          " " +
          date.split("T")[1].split(".")[0]
        );
      }
    },
    declineExpense() {
      console.log("declineExpense");
    },
    getExpensesByType(type) {
      if (!this.expense.transport_expenses) return [];
      if (["car", "public_transport", "other"].includes(type)) {
        return this.expense.transport_expenses.filter((expense) => expense.type === type);
      } else if (type === "other_expense") {
        return this.expense.other_expenses;
      }
      return [];
    },
    addExpenseByType(type) {
      if (["car", "public_transport", "other"].includes(type)) {
        this.expense.transport_expenses.push({
          type,
          price: 0,
          distance: 0,
        });
      } else if (type === "other_expense") {
        this.expense.other_expenses.push({
          description: "",
          price: 0,
        });
      }
    },
    deleteExpenseByType(type, item) {
      let index;
      if (["car", "public_transport", "other"].includes(type)) {
        index = this.expense.transport_expenses.findIndex((expense) => expense === item);
        this.expense.transport_expenses.splice(index, 1);
      } else if (type === "other_expense") {
        index = this.expense.other_expenses.findIndex((expense) => expense === item);
        this.expense.other_expenses.splice(index, 1);
      }
    },
    getSelectedCountry() {
      return this.countries.find((country) => country.country === this.expense.country);
    },
    getCountryById(id) {
      const country = this.countries.find((country) => country.id === id);
      return country ? country : "";
    },
  },
  created() {
    this.dates = this.expenseDates;
    this.expense = this.travelExpense;
  },
};
</script>
<style scoped>
.card {
  background: rgba(255, 255, 255, 0.5);
}
.title {
  font-size: 1.4rem;
  font-weight: 500;
  padding-top: 1rem;
  color: #000000;
}
.minimalistic-input {
  color: black;
}
</style>
