<template>
  <sidenav
    :custom_class="this.$store.state.mcolor"
    :class="[this.$store.state.isRTL ? 'fixed-end' : 'fixed-start']"
    :key="refreshKey"
    v-if="this.$store.state.showSidenav"
  />
  <main
    class="main-content position-relative max-height-vh-100 h-100 border-radius-lg"
    :style="this.$store.state.isRTL ? 'overflow-x: hidden' : ''"
    :key="refreshKey"
  >
    <!-- nav -->
    <navbar
      :textWhite="this.$store.state.isAbsolute ? 'text-white opacity-8' : ''"
      :minNav="navbarMinimize"
      v-if="this.$store.state.showNavbar"
    />
    <router-view />

    <!-- alerts -->
    <div class="container">
      <soft-alert
        icon="ni ni-like-2 ni-lg"
        dismissible
        :hidden="this.$store.state.successAlert.hidden"
        class="alert-success"
      >
        <strong>{{ $t("success") }}</strong>
        {{ this.$store.state.successAlert.message }}
      </soft-alert>
      <soft-alert
        icon="fas fa-exclamation-triangle ni-lg"
        dismissible
        :hidden="this.$store.state.errorAlert.hidden"
        class="alert-danger"
      >
        <strong class="text-dark">{{ $t("error") }}:</strong>
        {{ this.$store.state.errorAlert.message }}
      </soft-alert>
      <soft-alert
        icon="fas fa-warning ni-lg"
        dismissible
        :hidden="this.$store.state.warningAlert.hidden"
        class="alert-warning"
      >
        <strong class="text-dark">{{ $t("warning") }}:</strong>
        {{ this.$store.state.warningAlert.message }}
      </soft-alert>
    </div>

    <!-- <app-footer v-show="this.$store.state.showFooter" /> -->
  </main>
</template>
<script>
import Sidenav from "./examples/Sidenav";
import Navbar from "@/examples/Navbars/Navbar.vue";
import SoftAlert from "@/components/SoftAlert.vue";
import { mapMutations } from "vuex";
import axios from "axios";

export default {
  name: "App",
  components: {
    Sidenav,
    SoftAlert,
    Navbar,
  },
  data() {
    return {
      refreshKey: 0,
    };
  },
  methods: {
    ...mapMutations(["toggleConfigurator", "navbarMinimize"]),
  },
  computed: {
    navClasses() {
      return {
        "position-sticky blur shadow-blur left-auto top-1 z-index-sticky":
          this.$store.state.isNavFixed,
        "position-absolute px-4 mx-0 w-100 z-index-2":
          this.$store.state.isAbsolute,
        "px-0 mx-4 mt-4": !this.$store.state.isAbsolute,
      };
    },
  },
  beforeMount() {
    // this.$store.state.isTransparent = "bg-transparent";
    this.$store.commit("setIsTransparent", "bg-transparent");
  },
  created() {
    this.$store.replaceState({
      ...this.$store.state,
      ...JSON.parse(sessionStorage.getItem("store")),
    });
    sessionStorage.removeItem("store");

    const cookieToken = this.$cookies.get("token");
    if (cookieToken) {
      const tokenType = this.$cookies.get("tokenType");
      axios.defaults.headers.common["Authorization"] = `${tokenType} ${cookieToken}`;
      this.$store.commit("setToken", cookieToken);
      this.$store.commit("setIsAuthenticated", true);
    }
    else {
      this.$store.commit("setToken", null);
      this.$store.commit("setIsAuthenticated", false);
    }
    if (cookieToken && !this.$store.state.user.id) {
      this.$store.dispatch("getMe").then(() => {
        this.refreshKey++;
      });
    }

    window.addEventListener("beforeunload", () => {
      let store = JSON.stringify(this.$store.state);
      sessionStorage.setItem("store", store);
    });
  },
  mounted() {
    const companyName = process.env.VUE_APP_COMPANY_NAME || '';
    document.title = `${companyName} Web app`;
    const faviconPath = `icons/favicon_${companyName.toLowerCase()}.png`;
    document.getElementById('favicon').setAttribute('href', faviconPath);
  },
};
</script>
<style>
@font-face {
  font-family: "Open Sans";
  src: url("@/assets/fonts/OpenSans-Regular.ttf");
}
</style>
