import axios from "axios";

const travelExpenses = {
  state: {
    travelExpenses: [],
  },
  mutations: {
    setTravelExpenses(state, data) {
      state.travelExpenses = data.data;
    },
  },
  actions: {
    getExpense(context, data) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/v1/users/${data.userId}/travel_expenses/${data.expenseId}/`).then((response) => {
          return resolve(response.data);
        }).catch((error) => {
          return reject(error);
        });
      });
    },

    getAllTravelExpenses() {
      return new Promise((resolve, reject) => {
        axios.get("/api/v1/travel_expenses/").then((response) => {
          return resolve(response.data);
        }).catch((error) => {
          return reject(error);
        });
      });
    },

    loadTravelExpenses(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/travel_expenses/?event=${data.eventId}`)
          .then((response) => {
            return resolve(response.data);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
    updateUsersTravelExpenses(context, data) {
      return new Promise((resolve) => {
        for (let user of data.users) {
          for (let expense of data.expenses) {
            if (user.id === expense.user_id) {
              user.travel_expense = expense;
              user.expense_type = expense.expense_type;
            }
          }
        }
        return resolve(data.users);
      });
    },
  },
  getters: {
    getTravelExpenses(state) {
      return state.travelExpenses;
    },
  },
};

export default travelExpenses;
