<template>
  <nav
    class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl mt-0"
    v-bind="$attrs"
    id="navbarBlur"
    data-scroll="true"
  >
    <div class="px-3 py-1 container-fluid text-bg-contrast align-middle">
      <breadcrumbs :currentPage="currentRouteName" />
      <div
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="this.$store.state.isRTL ? 'px-0' : 'me-sm-4'"
        id="navbar"
      >
        <div
          class="pe-md-3 d-flex align-items-center text-center"
          :class="'ms-md-auto'"
        >
          <div class="">
            <a href="/profile" class=" nav-link invisible-btn">
              <span class="text-bg-contrast font-weight-bold">{{ this.$store.state.user.full_name }}</span>
            </a>
          </div>
        </div>
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item d-flex align-items-center mx-2">
            <button
              class="nav-link invisible-btn"
              @click="goToChangePassword()"
            >
              <i
                class="text-sm fa fa-key text-bg-contrast"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :title="$t('change_password')"
              ></i>
            </button>
          </li>
          <li class="nav-item d-flex align-items-center">
            <LocaleSwitcher />
          </li>
          <li class="nav-item d-flex align-items-center">
            <router-link
              :to="{ name: 'log_in' }"
              class="nav-link invisible-btn"
              @click="logout()"
            >
              <i
                class="fa fa-user text-bg-contrast"
                :class="this.$store.state.isRTL ? 'ms-sm-2' : 'me-sm-1'"
              ></i>

              <span class="d-sm-inline d-none text-bg-contrast font-weight-bold">{{ $t("sign_out") }}</span>
            </router-link>
          </li>
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a
              href="#"
              @click="toggleSidebar"
              class="p-0 nav-link text-body"
              id="iconNavbarSidenav"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import axios from "axios";
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapMutations } from "vuex";
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
// import { ref } from "vue";

export default {
  name: "navbar-item",
  data() {
    return {
      showMenu: true,
    };
  },
  props: ["minNav", "textWhite"],
  created() {
    this.minNav;
  },
  methods: {
    ...mapMutations(["navbarMinimize"]),

    goToChangePassword() {
      const isAdEnabled = this.$store.state.user.active_dir_sync_enabled;
      if (isAdEnabled) {
        this.$store.dispatch("showAlert", {
          message: this.$t("you_have_active_directory_sync"),
          type: "warning",
          timeout: 5000,
        });
      } else {
        this.$router.push({ name: "change_password" });
      }
    },

    toggleSidebar() {
      this.navbarMinimize();
    },
    async logout() {
      await axios.post("/api/token/logout/").catch((error) => {
        console.log(JSON.stringify(error));
      });

      axios.defaults.headers.common["Authorization"] = "";
      this.$cookies.remove("token");
      this.$cookies.remove("tokenType");

      localStorage.clear();
      this.$store.commit("removeToken");

      this.$router.push("/login");
    },
  },
  components: {
    Breadcrumbs,
    LocaleSwitcher,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>
