<template>
  <div class="container">
    <TableCard :headers="headers" :tableItems="filteredUsers" searchBy="full_name" :sortAliases="{
      name: 'last_name',
    }">
      <template #cardHeader>
        <div class="mb-4">
          <select class="minimalistic-input" v-model="selectedStatus" @change="this.filterUsersByStatus">
            <option v-for="status in [
              'all',
              'initial',
              'not_submitted',
              'submitted',
              'in_edit',
            ]" :key="status" :value="status">
              {{ $t(status) }}
            </option>
          </select>
        </div>
      </template>

      <template #tableBody="slotItem">
        <td class="p-0">
          <user-button :item="slotItem.item" />
        </td>

        <td width="30%" class="align-middle text-center text-sm">
          <span :class="this.statuses[slotItem.item.availability.status]">
            {{ $t(slotItem.item.availability.status) }}
          </span>

        </td>

        <td width="30%" class="align-middle text-center text-sm text-bg-contrast">
          <small>
            {{ slotItem.item.availability.year }}
          </small>
        </td>

        <td width="10%" class="align-middle text-center text-sm">
          <button class="invisible-btn text-info" @click="
            this.$router.push(
              `/users/${slotItem.item.id}?tab=availabilities`
            )
          ">
            <i class="fas fa-info text-bg-contrast"></i>
          </button>
        </td>
      </template>
    </TableCard>
  </div>
</template>

<script lang="js">
import UserButton from "@/components/UserButton.vue";
import TableCard from "@/components/TableCard.vue";
import { useStore } from "vuex";
// import axios from "axios";

export default {
  name: "users-availabilities-table",
  components: {
    UserButton,
    TableCard,
  },
  data() {
    return {
      headers: ["name", "status", "year"],
      users: [],
      usersWithAvailabilities: [],
      selectExpenseType: false,
      selectedUser: null,
      expenseTypes: [],
      selectedStatus: localStorage.getItem("AllAvailabilitiesSelectedStatus")
        ? localStorage.getItem("AllAvailabilitiesSelectedStatus")
        : "all",
      statuses:
      {
        "in_edit": "badge badge-sm bg-warning text-dark",
        "submitted": "badge badge-sm bg-success text-dark",
        "not_submitted": "badge badge-sm bg-danger text-white",
        "initial": "badge badge-sm bg-info text-dark",
      },
    };
  },
  setup() {
    return {
      getters: useStore().getters,
    };
  },
  computed: {

    sortedUsers() {
      return [...this.usersWithAvailabilities].sort((a, b) => {
        if (a.last_name < b.last_name) {
          return -1;
        }
        if (a.last_name > b.last_name) {
          return 1;
        }
        return 0;
      });
    },
    filteredUsers() {
      if (this.selectedStatus === "all") {
        return this.sortedUsers;
      } else {
        return this.sortedUsers.filter(
          (user) => user.availability && user.availability.status === this.selectedStatus
        );
      }
    },
  },
  methods: {
    async getUsers() {
      await this.$store.dispatch("getUsersFromAllPagesShort").then(() => {
        this.users = this.getters.getUsersShort;
      });
    },

    async updateUsersAvailabilities() {
      this.$store.dispatch("fetchAllAvailabilities")
        .then((response) => {
          response.results.forEach((availability) => {
            const user = this.users.find((user) => user.id === availability.user);
            user.availability = availability;
            this.usersWithAvailabilities.push(user);
          });
        });
    },

    sortUsersList() {
      this.users.sort((a, b) => {
        if (a.last_name < b.last_name) {
          return -1;
        }
        if (a.last_name > b.last_name) {
          return 1;
        }
        return 0;
      });
    },
    paginate() {
      this.$refs.tableChild.paginateByPage();
    },
  },
  mounted() {
    this.getUsers().then(() => {
      this.updateUsersAvailabilities()
    })
  },

};
</script>
