<template>
  <div class="row">
    <div class="col">
      <p class="text-sm fw-bold text-primary mb-0">{{ $t(label) }}:</p>
    </div>
    <div class="col text-start">
      <select
        class="minimalistic-input"
        @change="this.$emit('filter', $event.target.value)"
      >
        <option value="all">{{ $t("all") }}</option>
        <option v-for="item in items" :key="item" :value="item">
          <span v-if="translateOptions">{{ $t(item) }}</span>
          <span v-else>{{ item }}</span>
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterSelection",
  props: {
    label: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    translateOptions: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>
