<template>
  <DeleteAlert @deleteEvent="deleteSerie" :msg="deleteMsg" :visible="ShowModal" />

  <div v-if="this.addChampionshipIsOpened" class="card mb-3">
    <div class="card-body">
      <div class="row flex-column-reverse flex-md-row">
        <div class="col">
          <ul class="list-group">
            <CustomInput label="name" :editable="this.editable" :placeholder="selectedChampionship.name"
              bindObj="name" />
            <CustomInput label="abbreviation" :editable="this.editable" :placeholder="selectedChampionship.name"
              bindObj="abbreviation" />
            <ColorInput :color="generateRandomColor()" @changeColor="changeColor" label="color" />
          </ul>
        </div>
        <div class="col">
          <ul class="list-group">
            <SelectInput label="year" :editable="this.editable" :placeholder="selectedChampionship.year" bindObj="year"
              :items="years">
            </SelectInput>

            <SelectInput label="type" :editable="this.editable" :placeholder="selectedChampionship.types" bindObj="type"
              :items="types" valueObj="name" displayObj="name">
            </SelectInput>
          </ul>
        </div>
      </div>
      <div class="row m-0 p-0">
        <div class="col-md-12 text-end">
          <IconBtn title="save" icon="fas fa-2x fa-save text-primary" @click="saveChampionship()" />
          <IconBtn title="cancel" icon="fas fa-2x fa-times-circle text-primary" @click="cancelAddChampionship()" />
        </div>
      </div>
    </div>
  </div>

  <TableCard :tableItems="this.championship" :headers="this.headers" searchBy="name" filterPlaceholder="search_by_name">
    <template #cardHeader>
      <div class="col-12 text-end mb-2">
        <button class="btn btn-sm btn-primary text-primary-contrast" @click="openAddChampionshipCard()">
          {{ $t("create_serie") }}
        </button>
      </div>
    </template>

    <template #tableBody="slotItem">
      <td>
        <button v-if="this.getters.isSuperuser" class="invisible-btn text-bg-contrast full-size"
          @click="this.$router.push(`/championship/${slotItem.item.id}`)">
          <p class="text-sm font-weight-thin mb-0">
            {{ slotItem.item.name }}
          </p>
        </button>
        <p v-else class="text-sm font-weight-thin mb-0">
          {{ slotItem.item.name }}
        </p>
      </td>

      <td v-for="el in this.shortHeaders" :key="el">
        <p class="text-sm font-weight-thin mb-0 mx-4">
          {{ slotItem.item[el] }}
        </p>
      </td>

      <td class="text-center">
        <button class="invisible-btn" @click="
          () => {
            this.ShowModal = true;
            this.selectedChampionship = slotItem.item;
          }
        ">
          <i class="text-sm fas fa-trash text-white" data-bs-toggle="tooltip" data-bs-placement="top"
            :title="$t('delete')"></i>
        </button>
      </td>
    </template>
  </TableCard>
</template>

<script>
import axios from "axios";
import ColorInput from "@/components/inputs/ColorInput.vue";
import CustomInput from "@/components/inputs/CustomInput.vue";
import SelectInput from "@/components/inputs/SelectInput.vue";
import IconBtn from "@/components/buttons/IconBtn.vue";
import TableCard from "@/components/TableCard.vue";
import DeleteAlert from "@/components/alerts/DeleteAlert.vue";
import { useStore } from "vuex";

export default {
  name: "ChampionshipList",
  components: {
    CustomInput,
    SelectInput,
    ColorInput,
    IconBtn,
    TableCard,
    DeleteAlert,
  },
  setup() {
    return {
      getters: useStore().getters,
    };
  },
  data() {
    return {
      deleteMsg: "want_to_delete",
      ShowModal: false,
      addChampionshipIsOpened: false,
      newObj: {},
      editable: false,
      allJobs: [],
      championship: [],
      selectedChampionship: {},
      types: [],
      headers: ["name", "abbreviation", "year", "type"],
      shortHeaders: ["abbreviation", "year", "type"],
      years: [...Array(20).keys()].map((i) => i + new Date().getFullYear()),
    };
  },

  methods: {
    openAddChampionshipCard() {
      this.getChampionshipTypes();
      this.addChampionshipIsOpened = true;
      this.editable = true;
    },
    generateRandomColor() {
      return "#" + Math.floor(Math.random() * 16777215).toString(16);
    },

    cancelAddChampionship() {
      this.addChampionshipIsOpened = false;
      this.editable = false;
      this.selectedChampionship = {};
    },

    async getChampionship() {
      await axios
        .get(`api/v1/championship/`)
        .then((res) => {
          this.championship = res.data.results;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getAllJobs() {
      await axios
        .get("/api/v1/jobs/")
        .then((response) => {
          this.allJobs = response.data.results.map((job) => job.name);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getChampionshipTypes() {
      await axios
        .get("/api/v1/championshipTypes/")
        .then((response) => {
          this.types = response.data.results;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    changeColor(color) {
      this.newObj.color = color;
    },

    async saveChampionship() {
      await axios
        .post("/api/v1/championship/", this.newObj)
        .then((resp) => {
          this.getChampionship(resp);
          this.championship.unshift(resp.data);
          this.addChampionshipIsOpened = false;
          this.editable = false;
          this.selectedChampionship = {};
          this.newObj = {};
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async deleteSerie() {
      const id = this.selectedChampionship.id;

      await axios
        .delete(`/api/v1/championship/${id}/`)
        .then(() => {
          this.ShowModal = false;
          this.championship = this.championship.filter((item) => item.id !== id);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    cancelChampionship(serie) {
      this.championship = this.championship.filter((item) => item !== serie);
    },
  },

  created() {
    this.getChampionship();
  },
};
</script>
<style scoped>

</style>
