import "./assets/js/nav-pills.js";

async function importStyles() {
  const companyName = process.env.VUE_APP_COMPANY_NAME?.toLowerCase();

  if (companyName) {
    await import(`./assets/scss/styles_${companyName}.scss`);
  } else {
    console.error('VUE_APP_COMPANY_NAME is not set.');
  }
}

importStyles();

export default {
  install() {},
};
