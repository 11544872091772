<template>
  <DeleteAlert
    @deleteEvent="deleteChampionshipEvent"
    :msg="deleteMsg"
    :visible="ShowModal"
  />

  <div v-if="this.addChampionshipEventIsOpened" class="card mb-3">
    <div class="card-body">
      <div class="row flex-column-reverse flex-md-row">
        <div class="col">
          <ul class="list-group">
            <SelectInput
              label="location"
              :editable="this.editable"
              :placeholder="selectedChampionshipEvent.location"
              bindObj="location"
              :items="locations"
              valueObj="name"
              displayObj="name"
              :useEmit="true"
              @changeVal="updateNewEventName()"
            >
              <template v-slot:optionAddition="slotItem">
                <span class="text-danger"> ({{ slotItem.item.country }})</span>
              </template>
            </SelectInput>

            <SelectInput
              label="championship"
              :editable="this.editable"
              :placeholder="selectedChampionshipEvent.championship"
              bindObj="championship"
              :items="championship"
              valueObj="name"
              displayObj="name"
              :useEmit="true"
              @changeVal="updateNewEventName()"
            >
            </SelectInput>
            <CustomInput label="name" :editable="this.editable" bindObj="name" />
          </ul>
        </div>
        <div class="col">
          <ul class="list-group">
            <CustomInput
              type="date"
              label="start_date"
              :editable="this.editable"
              :placeholder="selectedChampionshipEvent.start_date"
              bindObj="start_date"
            />

            <CustomInput
              type="date"
              label="end_date"
              :editable="this.editable"
              :placeholder="selectedChampionshipEvent.end_date"
              bindObj="end_date"
            />
            <CustomInput
              type="checkbox"
              label="is_test"
              :editable="this.editable"
              :placeholder="selectedChampionshipEvent.is_test"
              bindObj="is_test"
            />
          </ul>
        </div>
      </div>
      <div class="row m-0 p-0">
        <div class="col-md-12 text-end">
          <IconBtn
            title="save"
            icon="fas fa-2x fa-save text-primary"
            @click="saveChampionshipEvent()"
          />
          <IconBtn
            title="cancel"
            icon="fas fa-2x fa-times-circle text-primary"
            @click="cancelAddChampionshipEvent()"
          />
        </div>
      </div>
    </div>
  </div>

  <TableCard
    :tableItems="this.filteredChampionshipEvents"
    :headers="this.headers"
    searchBy="name"
    filterPlaceholder="search_by_name"
  >
    <template #cardHeader>
      <div class="row">
        <div class="col-5">
          <FilterSelection
            :items="this.championship.map((el) => el.name)"
            @filter="this.filterTableByChampionship"
            :translateOptions="false"
            label="championship"
          />
        </div>
        <div class="col-7 text-end mb-2">
          <button
            class="btn btn-sm btn-primary text-primary-contrast"
            @click="openAddChampionshipEventCard()"
          >
            {{ $t("add_championship_event") }}
          </button>
        </div>
      </div>
    </template>

    <template #tableBody="slotItem">
      <td>
        <button
          v-if="this.getters.isSuperuser"
          class="invisible-btn text-white full-size"
          @click="this.$router.push(`/championship_event/${slotItem.item.id}`)"
        >
          <p class="text-sm font-weight-thin mb-0">
            {{ slotItem.item.name }}
          </p>
        </button>
        <p v-else class="text-sm font-weight-thin mb-0">
          {{ slotItem.item.name }}
        </p>
      </td>

      <td v-for="el in this.shortHeaders" :key="el">
        <p class="text-sm font-weight-thin mb-0 mx-4">
          {{ slotItem.item[el] }}
        </p>
      </td>

      <td class="text-center">
        <button
          class="invisible-btn"
          @click="
            () => {
              this.ShowModal = true;
              this.selectedChampionshipEvent = slotItem.item;
            }
          "
        >
          <i
            class="text-sm fas fa-trash text-white"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            :title="$t('delete')"
          ></i>
        </button>
      </td>
    </template>
  </TableCard>
</template>

<script>
import axios from "axios";

import CustomInput from "@/components/inputs/CustomInput.vue";
import SelectInput from "@/components/inputs/SelectInput.vue";
import FilterSelection from "@/components/inputs/FilterSelection.vue";
import IconBtn from "@/components/buttons/IconBtn.vue";
import TableCard from "@/components/TableCard.vue";
import DeleteAlert from "@/components/alerts/DeleteAlert.vue";
import { useStore } from "vuex";

export default {
  name: "ChampionshipEventsList",
  components: {
    CustomInput,
    SelectInput,
    FilterSelection,
    IconBtn,
    TableCard,
    DeleteAlert,
  },
  setup() {
    return {
      getters: useStore().getters,
    };
  },
  data() {
    return {
      deleteMsg: "want_to_delete",
      ShowModal: false,
      championship_events: {},
      filteredChampionshipEvents: {},
      allJobs: [],
      locations: [],
      championship: [],
      selectedChampionshipEvent: {},
      addChampionshipEventIsOpened: false,
      newObj: {},
      editable: false,
      headers: ["name", "championship", "location", "start_date", "end_date"],
      shortHeaders: ["championship", "location", "start_date", "end_date"],
    };
  },

  methods: {
    updateNewEventName() {
      if (this.newObj.championship && this.newObj.location) {
        this.newObj.name = `${this.newObj.championship} ${this.newObj.location}`;
      }
    },
    openAddChampionshipEventCard() {
      this.getLocations();
      this.getChampionship();
      this.addChampionshipEventIsOpened = true;
      this.editable = true;
    },
    cancelAddChampionshipEvent() {
      this.addChampionshipEventIsOpened = false;
      this.editable = false;
      this.selectedChampionshipEvent = {};
    },

    async getChampionshipEvents() {
      await axios
        .get(`api/v1/championship_events/`)
        .then((res) => {
          this.championship_events = res.data.results;
          this.filteredChampionshipEvents = res.data.results;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getAllJobs() {
      await axios
        .get("/api/v1/jobs/")
        .then((response) => {
          this.allJobs = response.data.results.map((job) => job.name);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getLocations() {
      await this.$store.dispatch("fetchLocations").then(() => {
        this.locations = this.getters.getLocations;
      });
    },

    async getChampionship() {
      await axios
        .get("/api/v1/championship/?fields=id,name,jobs")
        .then((response) => {
          this.championship = response.data.results;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    addChampionshipEventToList() {
      this.getChampionshipEventTracks();
      this.getAllJobs().then(() => {
        this.filteredChampionshipEvents.unshift({
          name: "",
          championship: "",
          location: "",
          start_date: "",
          end_date: "",
          jobs: this.allJobs.map((job) => {
            return { job: job, members: 0 };
          }),
          notSaved: true,
        });
      });
    },

    async saveChampionshipEvent() {
      await axios
        .post("/api/v1/championship_events/", this.newObj)
        .then((resp) => {
          this.getChampionshipEvents();
          this.filteredChampionshipEvents.unshift(resp.data);
          this.addChampionshipEventIsOpened = false;
          this.editable = false;
          this.selectedChampionshipEvent = {};
          this.newObj = {};
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async deleteChampionshipEvent() {
      const id = this.selectedChampionshipEvent.id;
      await axios
        .delete(`/api/v1/championship_events/${id}/`)
        .then(() => {
          this.ShowModal = false;
          this.championship_events = this.championship_events.filter(
            (championship_event) => championship_event.id !== id
          );
          this.filterTableByChampionship("all");
        })
        .catch((err) => {
          console.log(err);
        });
    },

    cancelChampionshipEvent(championship_event) {
      this.filteredChampionshipEvents = this.filteredChampionshipEvents.filter(
        (item) => item !== championship_event
      );
    },

    filterTableByChampionship(value) {
      if (value === "all") {
        this.filteredChampionshipEvents = this.championship_events;
      } else {
        this.filteredChampionshipEvents = this.championship_events.filter(
          (championship_event) => championship_event.championship === value
        );
      }
    },
  },

  created() {
    this.getChampionshipEvents();
    this.getChampionship();
  },
};
</script>
<style scoped></style>
