import axios from "axios";

const userStore = {
  actions: {
    patchUserProfile(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/api/v1/users/${data.userId}/`, data.data)
          .then((response) => {
            return resolve(response.data);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
    createUserClothesObj(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/users/${data.userId}/clothes/`, data.data)
          .then((response) => {
            return resolve(response.data);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
    patchUserClothesObj(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/api/v1/users/${data.userId}/clothes/`, data.data)
          .then((response) => {
            return resolve(response.data);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
  },
};

export default userStore;
