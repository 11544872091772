<template>
  <div v-if="this.apiData.length > 0">
    <div class="container">
      <div class="row my-4">
        <div class="col-md-2">
          <select
            class="form-control success"
            v-model="this.choosenConfigIndex"
            @change="
              this.renderRpmChart();
              this.renderThrottleChart();
            "
          >
            <option
              v-for="config in apiData"
              :key="config.name"
              :value="apiData.indexOf(config)"
            >
              {{ config.name }}
            </option>
          </select>
        </div>
        <div class="col-md-5"></div>

        <div class="col-sm-5 right">
          <button
            type="button"
            class="btn mb-0 btn-sm btn-outline-dark"
            data-bs-toggle="modal"
            data-bs-target="#updateModal"
          >
            Update
          </button>
          <small>|</small>
          <button
            type="button"
            class="btn mb-0 btn-sm btn-outline-dark"
            data-bs-toggle="modal"
            data-bs-target="#saveModal"
          >
            Save as
          </button>
          <small>|</small>

          <button
            type="button"
            @click="downloadConfig()"
            class="btn mb-0 btn-sm btn-outline-dark"
          >
            Download
          </button>
        </div>
      </div>

      <!-- Update btn modal -->
      <div
        class="modal"
        id="updateModal"
        tabindex="1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Update config?</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              You are updating
              <strong>{{ this.apiData[this.choosenConfigIndex].name }}</strong>
              e-cart configuration. <br /><br />
              Are you sure?
            </div>
            <div class="modal-footer justify-content-between">
              <button
                type="button"
                class="btn btn-outline-dark"
                data-bs-dismiss="modal"
              >
                No
              </button>
              <button
                type="button"
                class="btn bg-gradient-success"
                data-bs-dismiss="modal"
                @click="updateConfig()"
              >
                Update config
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Save btn modal -->
      <div
        class="modal pt-4"
        id="saveModal"
        tabindex="1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Create new config?
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              You are creating a new e-cart configuration.
              <br /><br />
              <label for="configName">Configuration name</label>
              <input
                type="text"
                class="form-control"
                id="configName"
                placeholder="Enter config name"
                v-model="this.newConfigName"
              />
            </div>
            <div class="modal-footer justify-content-between">
              <button
                type="button"
                class="btn btn-outline-dark"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn bg-gradient-success"
                data-bs-dismiss="modal"
                @click="saveConfig()"
              >
                Save config
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row my-4">
        <div class="col-lg-12 col-md-6 mb-md-0 mb-4">
          <div class="card">
            <div class="card-body pt-0">
              <div class="row">
                <div class="col-md-6">
                  <div class="row my-2">
                    <div class="col-sm">
                      <label class="form-check-label">
                        <small>Map amount: </small>
                      </label>
                      <div class="btn-group">
                        <soft-button
                          :size="'sm'"
                          :color="'dark'"
                          variant="outline"
                          @click="addMap('throttle')"
                        >
                          <i class="fa fa-plus"></i>
                        </soft-button>
                        <soft-button
                          :size="'sm'"
                          :color="'dark'"
                          variant="outline"
                          @click="deleteMap('throttle')"
                        >
                          <i class="fa fa-minus"></i>
                        </soft-button>
                      </div>
                    </div>
                    <div class="col-sm">
                      <label class="form-check-label">
                        <small>Choose map</small>
                      </label>
                      <select
                        class="form-control success"
                        v-model="this.choosenMapThrottle"
                        @change="this.renderThrottleChart()"
                      >
                        <option
                          v-for="map in apiData[this.choosenConfigIndex].maps
                            .throttle"
                          :key="map"
                          :value="
                            apiData[
                              this.choosenConfigIndex
                            ].maps.throttle.indexOf(map) + 1
                          "
                        >
                          {{
                            apiData[
                              this.choosenConfigIndex
                            ].maps.throttle.indexOf(map) + 1
                          }}
                        </option>
                      </select>
                    </div>
                    <div class="col-sm">
                      <label class="form-check-label">
                        <small>Points amount</small>
                      </label>
                      <input
                        type="number"
                        @change="this.renderThrottleChart()"
                        v-model="
                          this.apiData[this.choosenConfigIndex].maps.throttle[
                            this.choosenMapThrottle - 1
                          ].y.length
                        "
                        class="form-control col-md-2"
                      />
                    </div>
                  </div>
                  <gradient-line-chart
                    :key="this.throttleChartKey"
                    id="chart-angle"
                    title="Torque % per Throttle angle %"
                    description=""
                    :chart="{
                      labels:
                        this.apiData[this.choosenConfigIndex].maps.throttle[
                          this.choosenMapThrottle - 1
                        ].x,
                      datasets: [
                        {
                          label: 'Torque %',
                          data: this.apiData[this.choosenConfigIndex].maps
                            .throttle[this.choosenMapThrottle - 1].y,
                        },
                      ],
                    }"
                  />
                  <div class="col-md-12 text-center">
                    <div class="btn-group">
                      <soft-button
                        :size="'sm'"
                        :color="'dark'"
                        variant="outline"
                        @click="
                          assignExpThrottle(
                            this.apiData[this.choosenConfigIndex].maps.throttle[
                              this.choosenMapThrottle - 1
                            ].y.length
                          )
                        "
                      >
                        Exponential
                      </soft-button>
                      <soft-button
                        :size="'sm'"
                        :color="'dark'"
                        variant="outline"
                        @click="
                          assignExpThrottle(
                            this.apiData[this.choosenConfigIndex].maps.throttle[
                              this.choosenMapThrottle - 1
                            ].y.length
                          )
                        "
                      >
                        Linear
                      </soft-button>
                      <soft-button
                        :size="'sm'"
                        :color="'dark'"
                        variant="outline"
                        @click="
                          assignExpThrottle(
                            this.apiData[this.choosenConfigIndex].maps.throttle[
                              this.choosenMapThrottle - 1
                            ].y.length
                          )
                        "
                      >
                        Logarythmic
                      </soft-button>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row my-2">
                    <label class="form-check-label">
                      <small>Throttle angle % (X axis):</small>
                    </label>
                    <div class="row">
                      <div
                        class="col-sm-3"
                        v-for="point in this.apiData[this.choosenConfigIndex]
                          .maps.throttle[this.choosenMapThrottle - 1].y.length"
                        :key="point"
                      >
                        <input
                          type="number"
                          class="form-control"
                          :key="point"
                          v-model="
                            this.apiData[this.choosenConfigIndex].maps.throttle[
                              this.choosenMapThrottle - 1
                            ].x[point - 1]
                          "
                          min="0"
                          max="100"
                          @change="this.renderThrottleChart()"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row my-2">
                    <label class="form-check-label">
                      <small>Torque % (Y axis):</small>
                    </label>
                    <div class="row">
                      <div
                        class="col-sm-3"
                        v-for="point in this.apiData[this.choosenConfigIndex]
                          .maps.throttle[this.choosenMapThrottle - 1].y.length"
                        :key="point"
                      >
                        <input
                          type="number"
                          class="form-control"
                          :key="point"
                          v-model="
                            this.apiData[this.choosenConfigIndex].maps.throttle[
                              this.choosenMapThrottle - 1
                            ].y[point - 1]
                          "
                          @change="this.renderThrottleChart()"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card my-4">
            <div class="card-body pt-0">
              <div class="row">
                <div class="col-md-6">
                  <div class="row my-2">
                    <div class="col-sm">
                      <label class="form-check-label">
                        <small>Map amount: </small>
                      </label>
                      <div class="btn-group">
                        <soft-button
                          :size="'sm'"
                          :color="'dark'"
                          variant="outline"
                          @click="this.addMap('rpm')"
                        >
                          <i class="fa fa-plus"></i>
                        </soft-button>
                        <soft-button
                          :size="'sm'"
                          :color="'dark'"
                          variant="outline"
                          @click="this.deleteMap('rpm')"
                        >
                          <i class="fa fa-minus"></i>
                        </soft-button>
                      </div>
                    </div>
                    <div class="col-sm">
                      <label class="form-check-label">
                        <small>Choose map</small>
                      </label>
                      <select
                        class="form-control success"
                        v-model="this.choosenMapRpm"
                        @change="this.renderRpmChart()"
                      >
                        <option
                          v-for="map in this.apiData[this.choosenConfigIndex]
                            .maps.rpm"
                          :key="map"
                          :value="
                            this.apiData[
                              this.choosenConfigIndex
                            ].maps.rpm.indexOf(map) + 1
                          "
                        >
                          {{
                            this.apiData[
                              this.choosenConfigIndex
                            ].maps.rpm.indexOf(map) + 1
                          }}
                        </option>
                      </select>
                    </div>
                    <div class="col-sm">
                      <label class="form-check-label">
                        <small>Points amount</small>
                      </label>
                      <input
                        type="number"
                        @change="this.renderRpmChart()"
                        v-model="
                          this.apiData[this.choosenConfigIndex].maps.rpm[
                            this.choosenMapRpm - 1
                          ].y.length
                        "
                        class="form-control col-md-2"
                      />
                    </div>
                  </div>
                  <gradient-line-chart
                    :key="this.rpmChartKey"
                    id="chart-rpm"
                    title="Torque per RPM"
                    description=""
                    :chart="{
                      labels:
                        this.apiData[this.choosenConfigIndex].maps.rpm[
                          this.choosenMapRpm - 1
                        ].x,
                      datasets: [
                        {
                          label: 'Torque %',
                          data: this.apiData[this.choosenConfigIndex].maps.rpm[
                            this.choosenMapRpm - 1
                          ].y,
                        },
                      ],
                    }"
                  />
                </div>
                <div class="col-md-6">
                  <div class="row my-2">
                    <label class="form-check-label">
                      <small>RPM values (X axis):</small>
                    </label>
                    <div class="row">
                      <div
                        class="col-sm-3"
                        v-for="point in this.apiData[this.choosenConfigIndex]
                          .maps.rpm[this.choosenMapRpm - 1].y.length"
                        :key="point"
                      >
                        <input
                          type="number"
                          class="form-control"
                          :key="point"
                          v-model="
                            this.apiData[this.choosenConfigIndex].maps.rpm[
                              this.choosenMapRpm - 1
                            ].x[point - 1]
                          "
                          @change="this.renderRpmChart()"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row my-2">
                    <label class="form-check-label">
                      <small>Torque % (Y axis):</small>
                    </label>
                    <div class="row">
                      <div
                        class="col-sm-3"
                        v-for="point in this.apiData[this.choosenConfigIndex]
                          .maps.rpm[this.choosenMapRpm - 1].y.length"
                        :key="point"
                      >
                        <input
                          type="number"
                          class="form-control"
                          :key="point"
                          v-model="
                            this.apiData[this.choosenConfigIndex].maps.rpm[
                              this.choosenMapRpm - 1
                            ].y[point - 1]
                          "
                          min="0"
                          max="100"
                          @change="this.renderRpmChart()"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card my-4">
            <div class="card-body">
              <div class="row my-2">
                <div class="col-md ps-4">
                  <div class="row">
                    <label class="form-check-label">
                      <small>Choose engine: </small>
                    </label>
                    <select
                      class="form-control success"
                      v-model="this.apiData[this.choosenConfigIndex].engine"
                    >
                      <option>VALEO</option>
                      <option>BRM</option>
                    </select>
                  </div>
                  <div class="row">
                    <label class="form-check-label">
                      <small>Choose battery: </small>
                    </label>
                    <select
                      class="form-control success"
                      v-model="this.apiData[this.choosenConfigIndex].battery"
                    >
                      <option>BOSCH</option>
                      <option>SCIO</option>
                    </select>
                  </div>
                  <div class="row">
                    <label class="form-check-label">
                      <small>Input type: </small>
                    </label>
                    <select
                      class="form-control success"
                      v-model="this.apiData[this.choosenConfigIndex].input_type"
                    >
                      <option>PEDAL</option>
                      <option>SPACE_DRIVE</option>
                    </select>
                  </div>
                  <div class="row">
                    <label class="form-check-label">
                      <small>Enable Drive: </small>
                    </label>
                    <select
                      class="form-control success"
                      v-model="
                        this.apiData[this.choosenConfigIndex].enable_drive
                      "
                    >
                      <option>true</option>
                      <option>false</option>
                    </select>
                  </div>
                  <div class="row">
                    <label class="form-check-label">
                      <small>Enable reverse: </small>
                    </label>
                    <select
                      class="form-control success"
                      v-model="
                        this.apiData[this.choosenConfigIndex].enable_reverse
                      "
                    >
                      <option>true</option>
                      <option>false</option>
                    </select>
                  </div>
                </div>

                <div class="col-md ps-4">
                  <div class="row">
                    <label class="form-check-label">
                      <small>Startup relais time (s): </small>
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="
                        this.apiData[this.choosenConfigIndex]
                          .startup_relais_time
                      "
                      min="0"
                    />
                  </div>
                  <div class="row">
                    <label class="form-check-label">
                      <small>Startup relais duration (s): </small>
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="
                        this.apiData[this.choosenConfigIndex]
                          .startup_relais_duration
                      "
                      min="0"
                    />
                  </div>
                  <div class="row">
                    <label class="form-check-label">
                      <small>Startup DC/DC conv time (s): </small>
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="
                        this.apiData[this.choosenConfigIndex]
                          .startup_DCDC_conv_time
                      "
                      min="0"
                    />
                  </div>
                  <div class="row">
                    <label class="form-check-label">
                      <small>IDLE duration (s): </small>
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="
                        this.apiData[this.choosenConfigIndex].IDLE_duration
                      "
                      min="0"
                    />
                  </div>
                  <div class="row">
                    <label class="form-check-label">
                      <small>Reverse IDLE duration (s): </small>
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="
                        this.apiData[this.choosenConfigIndex]
                          .reverse_IDLE_duration
                      "
                      min="0"
                    />
                  </div>
                  <div class="row">
                    <label class="form-check-label">
                      <small>Button Duration (s): </small>
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="
                        this.apiData[this.choosenConfigIndex].button_duration
                      "
                      min="0"
                    />
                  </div>
                </div>
                <div class="col-md ps-4">
                  <div class="row">
                    <div class="col-sm ps-0">
                      <label class="form-check-label">
                        <small>Max power (V): </small>
                      </label>
                      <input
                        type="number"
                        class="form-control"
                        v-model="
                          this.apiData[this.choosenConfigIndex].max_power_volts
                        "
                        min="0"
                      />
                    </div>
                    <div class="col-sm">
                      <label class="form-check-label">
                        <small>Max power (A): </small>
                      </label>
                      <input
                        type="number"
                        class="form-control"
                        v-model="
                          this.apiData[this.choosenConfigIndex].max_power_amps
                        "
                        min="0"
                      />
                    </div>
                    <div class="col-sm">
                      <label class="form-check-label pb-2">
                        <small>Max power: </small>
                      </label>
                      <p class="pb-0">
                        {{
                          this.apiData[this.choosenConfigIndex]
                            .max_power_volts *
                          this.apiData[this.choosenConfigIndex].max_power_amps
                        }}
                        W
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <label class="form-check-label">
                      <small>Max Torque (Nm): </small>
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="this.apiData[this.choosenConfigIndex].max_torque"
                      min="0"
                    />
                  </div>
                  <div class="row">
                    <label class="form-check-label">
                      <small>Emergency brake level (%): </small>
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="
                        this.apiData[this.choosenConfigIndex].emerg_brake_level
                      "
                      min="0"
                      max="100"
                    />
                  </div>
                  <div class="row">
                    <label class="form-check-label">
                      <small>SOC warning (%): </small>
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="
                        this.apiData[this.choosenConfigIndex].SOC_warning
                      "
                      min="0"
                      max="100"
                    />
                  </div>
                  <div class="row">
                    <label class="form-check-label">
                      <small>SOC critical (%): </small>
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="
                        this.apiData[this.choosenConfigIndex].SOC_critical
                      "
                      min="0"
                      max="100"
                    />
                  </div>
                  <div class="row">
                    <label class="form-check-label">
                      <small>Reverse Level (%): </small>
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="
                        this.apiData[this.choosenConfigIndex].reverse_level
                      "
                      min="0"
                      max="100"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
import SoftButton from "@/components/SoftButton.vue";
import axios from "axios";

export default {
  components: {
    GradientLineChart,
    SoftButton,
  },
  methods: {
    async saveConfig() {
      this.apiData[this.choosenConfigIndex].name = this.newConfigName;
      await axios.post(
        `/api/ecart_config/`,
        this.apiData[this.choosenConfigIndex]
      );
    },
    async updateConfig() {
      await axios
        .put(
          `/api/ecart_config/${this.apiData[this.choosenConfigIndex].id}/`,
          this.apiData[this.choosenConfigIndex]
        )
        .catch((error) => {
          console.log(error);
        });
    },

    async loadConfigs() {
      await axios
        .get(`/api/ecart_config/`)
        .then((response) => {
          if (response.data.length > 0) {
            this.apiData = response.data;
            this.choosenConfigIndex = 0;
          } else {
            this.apiData = [
              {
                name: "Default config",
                engine: "VALEO",
                battery: "SCIO",
                input_type: "PEDAL",
                enable_drive: "true",
                enable_reverse: "true",
                startup_relais_time: 5,
                startup_relais_duration: 1,
                startup_DCDC_conv_time: 2,
                IDLE_duration: 5,
                reverse_IDLE_duration: 1,
                button_duration: 2,
                max_power_volts: 50,
                max_power_amps: 60,
                max_torque: 35,
                emerg_brake_level: 80,
                SOC_warning: 30,
                SOC_critical: 10,
                reverse_level: 20,
                maps: {
                  throttle: [
                    {
                      x: [0, 1, 5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
                      y: [
                        10, 15, 25, 40, 65, 100, 160, 250, 360, 490, 640, 810,
                        1000,
                      ],
                    },
                    {
                      x: [0, 100],
                      y: [10, 1000],
                    },
                  ],
                  rpm: [
                    {
                      x: [0, 96, 192, 480, 961, 1442, 1538, 1923, 2884],
                      y: [40, 50, 60, 80, 100, 25, 8, 2, 2],
                    },
                    {
                      x: [0],
                      y: [66.6],
                    },
                    {
                      x: [0],
                      y: [33.3],
                    },
                  ],
                },
              },
            ];
          }
        })
        .then(() => {});
    },

    async downloadConfig() {
      console.log(JSON.stringify(this.apiData[this.choosenConfigIndex]));
      await axios
        .post(
          "api/ecart_config/generate/",
          JSON.stringify(this.apiData[this.choosenConfigIndex]),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          axios({
            method: "get",
            url: "api/ecart_config/download/",
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
            },
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Config.hpp");
            document.body.appendChild(link);
            link.click();
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async renderThrottleChart() {
      this.throttleChartKey += 1;
    },
    async renderRpmChart() {
      this.rpmChartKey += 1;
    },
    async addMap(mapType) {
      if (mapType == "throttle") {
        this.apiData[this.choosenConfigIndex].maps.throttle.push({
          x: [],
          y: [],
        });
        this.choosenMapThrottle =
          this.apiData[this.choosenConfigIndex].maps.throttle.length;
        this.renderThrottleChart();
      } else if (mapType == "rpm") {
        this.apiData[this.choosenConfigIndex].maps.rpm.push({
          x: [],
          y: [],
        });
        this.choosenMapRpm =
          this.apiData[this.choosenConfigIndex].maps.rpm.length;
        this.renderRpmChart();
      }
    },
    async deleteMap(mapType) {
      if (mapType == "throttle") {
        this.apiData[this.choosenConfigIndex].maps.throttle.splice(
          this.choosenMapThrottle - 1,
          1
        );
        this.choosenMapThrottle = 1;
        this.renderThrottleChart();
      } else if (mapType == "rpm") {
        this.apiData[this.choosenConfigIndex].maps.rpm.splice(
          this.choosenMapRpm - 1,
          1
        );
        this.choosenMapRpm = 1;
        this.renderRpmChart();
      }
    },
    generateX(max_num, points) {
      let arr = [];
      let step = max_num / (points - 1);
      for (let i = 0; i < points; i++) {
        arr.push(Math.round(i * step));
      }
      return arr;
    },

    generateY(x_arr) {
      let init_offset = 5;
      let y_arr = x_arr.map((item) => {
        return (item * item) / 100 + init_offset;
      });
      return y_arr;
    },

    assignExpThrottle(points) {
      this.apiData[this.choosenConfigIndex].maps.throttle[
        this.choosenMapThrottle - 1
      ].x = this.generateX(100, points);
      this.apiData[this.choosenConfigIndex].maps.throttle[
        this.choosenMapThrottle - 1
      ].y = this.generateY(
        this.apiData[this.choosenConfigIndex].maps.throttle[
          this.choosenMapThrottle - 1
        ].x
      );
      this.renderThrottleChart();
    },
  },

  created() {
    this.loadConfigs();
    // generateLinearY
  },

  data() {
    return {
      apiData: [],
      throttleChartKey: 0,
      rpmChartKey: 0,
      choosenMapThrottle: 1,
      choosenMapRpm: 1,
      choosenConfigIndex: 0,
      newConfigName: "",
    };
  },
};
</script>

<style scoped>
.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 0 solid rgba(0, 0, 0, 0.125);
}

p {
  margin-bottom: 0;
  text-align: center;
}

.form-control {
  background-color: transparent;
  border-width: 2px;
}

.right {
  text-align: right;
}
</style>
