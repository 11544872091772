<template>
  <div class="py-4 container-fluid">
    <DeleteAlert @deleteEvent="deleteEvent" :msg="deleteMsg" :visible="ShowModal" />

    <div class="row">
      <div v-for="card in miniCards" :key="card.title" :class="getMinicardsClasses()">
        <mini-statistics-card :title="card.title" :value="card.value" :icon="{
          component: card.icon.component,
          background: card.icon.background,
        }" direction-reverse />
      </div>
      <div v-if="this.isSuperuser()" :class="getMinicardsClasses()">
        <mini-statistics-card title="not_submitted_availabilities" :value="this.availabilitiesToSubmit" :icon="{
          component: 'ni ni-calendar-grid-58',
          background: 'bordered-transparent'
        }" direction-reverse />
      </div>
    </div>
    <div class="row my-4">
      <div class="col-lg-12 col-md-12 mb-md-0 mb-4">
        <events-card v-if="isSuperuser()" class="events-card" ref="eventCard" />
        <!-- <user-hotels-card v-if="!isSuperuser()" /> -->
        <AvailabilityInfoCard v-else :user_id="this.$store.getters.getCurrentUser.id" />
      </div>
    </div>
  </div>
</template>

<script>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import EventsCard from "./components/EventsCard.vue";
// import UserHotelsCard from "./components/UserHotelsCard.vue";
import AvailabilityInfoCard from "./components/InfoCards/AvailabilityInfoCard.vue";
import DeleteAlert from "@/components/alerts/DeleteAlert.vue";
import axios from "axios";

import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
export default {
  name: "dashboard",
  data() {
    return {
      deleteMsg: "want_to_delete",
      ShowModal: false,
      selectedEvent: {},
      travelExpenses: [],
      availabilitiesToSubmit: 0,

      greenBackground: "bg-gradient-success",
      redBackground: "bg-gradient-danger",
      blueBackground: "bg-gradient-info",
      yellowBackground: "bg-gradient-warning",
      purpleBackground: "bg-gradient-primary",
      miniCards: [
        {
          title: "new_expenses",
          value: "",
          icon: {
            component: "ni ni-world",
            background: "bordered-transparent",
          },
        },
        {
          title: "pending_expenses",
          value: "",
          icon: {
            component: "ni ni-paper-diploma",
            background: "bordered-transparent",
          },
        },
        {
          title: "unpaid_expenses",
          value: "",
          icon: {
            component: "ni ni-cart",
            background: "bordered-transparent",
          },
        },

      ],
      iconBackground: "bordered-transparent",
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
    };
  },
  components: {
    MiniStatisticsCard,
    EventsCard,
    // UserHotelsCard,
    AvailabilityInfoCard,
    DeleteAlert,
  },
  methods: {
    getMinicardsClasses() {
      if (!this.isSuperuser()) {
        return "col-xl-4 col-sm-6 mb-xl-0 mb-4";
      } else {
        return "col-xl-3 col-sm-6 mb-xl-0 mb-4";
      }
    },

    isSuperuser() {
      return this.$store.getters.isSuperuser;
    },

    deleteEvent() {
      this.ShowModal = false;

      this.$store.dispatch("deleteEvent", this.selectedEvent).then(() => {
        this.$refs.eventCard.getEventData();
      });
    },

    async getTravelExpensesData() {
      let url;
      if (this.isSuperuser()) {
        url = "/api/v1/travel_expenses/";
      } else {
        url = `/api/v1/users/${this.$store.state.user.id}/travel_expenses/`;
      }
      await axios
        .get(url)
        .then((response) => {
          const expenses = response.data.results;
          this.miniCards[0].value = expenses.filter(
            (expense) => expense.status === "new"
          ).length;
          this.miniCards[1].value = expenses.filter(
            (expense) => expense.status === "pending"
          ).length;
          this.miniCards[2].value = expenses.filter(
            (expense) =>
              expense.is_paid === false && expense.status === "accepted"
          ).length;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAvailabilitiesData() {
      if (this.isSuperuser()) {
        this.$store.dispatch("fetchAllAvailabilities").then((resp) => {
          this.availabilitiesToSubmit = resp.results.filter(
            (availability) => availability.status === "not_submitted"
          ).length;
        });
      }
    },
  },
  created() {
    this.getTravelExpensesData();
    this.getAvailabilitiesData();
  },
};
</script>
<style>
.small {
  height: 45px;
  width: 45px;
}

</style>
