import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
// import axios from "axios";
import AuthService from '@/services/authService';

import Dashboard from "@/views/Dashboard.vue";
import Login from "@/views/Login.vue";

import Availability from "@/views/Availabilities.vue";
import Cart_Configurator from "@/views/Cart_Configurator.vue";
import Hotels from "@/views/Hotels.vue";
import Events from "@/views/Events.vue";
import ChampionshipEvents from "@/views/ChampionshipEvents.vue";
import Users from "@/views/Users.vue";
import SingleEvent from "@/views/SingleEvent.vue";
import SingleChampionshipEvent from "@/views/SingleChampionshipEvent.vue";
import SingleChampionship from "@/views/SingleChampionship.vue";
import AddEvent from "@/views/AddEvent.vue";
import Calendar from "@/views/Calendar.vue";
import SingleUser from "@/views/SingleUser.vue";
import AddUser from "@/views/AddUser.vue";
import HotelRoom from "@/views/HotelRoom.vue";
import GeneratedHotelReport from "@/views/GeneratedHotelReport.vue";
import TravelExpenses from "@/views/UserTravelExpenses.vue";
import ChangePassword from "@/views/ChangePassword.vue";
import AddExpense from "@/views/AddExpense.vue";
import AllExpenses from "@/views/AllExpenses.vue";

const routes = [
  {
    path: '/auth/callback',
    name: 'authCallback',
    beforeEnter: (to, from, next) => {
      console.log('authCallback');
      const queryParams = new URLSearchParams(window.location.search);
      const code = queryParams.get('code');
      const authService = new AuthService();
      authService.handleCallback(code).then(() => {
        console.log('authCallback success');
      }).catch(err => {
        console.error(err);
        next({ name: "log_in" });
      });
    }
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Page404",
    component: Dashboard,
  },
  {
    path: "/",
    name: "/",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
  },

  {
    path: "/login",
    name: "log_in",
    component: Login,
  },
  {
    path: "/logout",
    name: "log_out",
    component: Login,
  },
  {
    path: "/availabilities",
    name: "availabilities",
    component: Availability,
  },
  {
    path: "/e-cart",
    name: "e-cart",
    component: Cart_Configurator,
  },
  {
    path: "/hotels",
    name: "hotels",
    component: Hotels,
  },
  {
    path: "/hotels/room/:id",
    name: "hotel_room",
    component: HotelRoom,
  },
  {
    path: "/events",
    name: "events",
    component: Events,
  },
  {
    path: "/events/:id",
    name: "single_event",
    component: SingleEvent,
  },
  {
    path: "/championship_event/:id",
    name: "single_championship_event",
    component: SingleChampionshipEvent,
  },
  {
    path: "/championship/:id",
    name: "single_championship",
    component: SingleChampionship,
  },
  {
    path: "/events/:id/hotel_report",
    name: "Hotels Report",
    component: GeneratedHotelReport,
    props: { event: {} },
  },

  {
    path: "/calendar",
    name: "calendar",
    component: Calendar,
  },

  {
    path: "/add-event",
    name: "add_event",
    component: AddEvent,
  },

  {
    path: "/championship_events",
    name: "championship_events",
    component: ChampionshipEvents,
  },
  {
    path: "/users",
    name: "users",
    component: Users,
  },
  {
    path: "/users/:id",
    name: "single_user",
    component: SingleUser,
  },
  {
    path: "/profile",
    name: "profile",
    component: SingleUser,
  },

  {
    path: "/add-user",
    name: "add_user",
    component: AddUser,
  },
  {
    path: "/travel-expenses",
    name: "travel_expenses",
    component: TravelExpenses,
  },
  {
    path: "/travel-expenses/:id",
    name: "travel_expenses_detail",
    component: AddExpense,
  },
  {
    path: "/user/:user_id/travel-expenses/:id",
    name: "travel_expenses_detail_user",
    component: AddExpense,
  },
  {
    path: "/change-password",
    name: "change_password",
    component: ChangePassword,
  },
  {
    path: "/add-expense",
    name: "add_expense",
    component: AddExpense,
  },
  {
    path: "/all-expenses",
    name: "all_expenses",
    component: AllExpenses,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  const userAllowedPages = [
    "log_in",
    "authCallback",
    "log_out",
    "change_password",
    "dashboard",
    "profile",
    "add_expense",
    "travel_expenses",
    "travel_expenses_detail",
  ];
  const isAuthenticated = store.state.isAuthenticated;

  const isSuperuser = store.getters.isSuperuser;

  const allowedUrlNames = ['authCallback', 'log_in', 'log_out'];

  if (!isAuthenticated && !allowedUrlNames.includes(to.name)) {
    console.log('not authenticated, redirecting to login');
    next({ name: "log_in" });
  } else if (!userAllowedPages.includes(to.name) && !isSuperuser) {
    next({ name: "dashboard" });
  } else {
    store.commit("openSidenav");
    next();
  }
});

export default router;
