<template>
  <div>
    <li class="text-sm border-0 list-group-item ps-0">
      <div class="row">
        <div class="col-2">
          <strong class="label text-white fw-normal">{{ $t(label) }}:</strong>
        </div>
        <div class="col-10 p-0">
          <input type="color" v-model="this.newColor" @change="changeColor" />
        </div>
      </div>
    </li>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newColor: "",
    };
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
  methods: {
    changeColor() {
      this.$emit("changeColor", this.newColor);
    },
  },
  mounted() {
    this.newColor = this.color;
  },
};
</script>
