<template>
  <div class="mb-n4 mx-4 overflow-hidden card card-body blur shadow-blur">
    <div class="row gx-4">
      <div class="col-auto">
        <h6 class="mb-1">{{ $t("room_in") }} {{ room.hotel }}</h6>
        <p class="text-sm mb-1">
          {{ room.readableCheck_in }} -
          {{ room.readableCheck_out }}
        </p>
      </div>
      <!-- back button -->
      <div class="col-auto ms-auto">
        <button
          class="btn btn-sm btn-white btn-icon-only rounded-circle"
          type="button"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :title="$t('back')"
          @click="this.$router.push(`/events/${room.eventId}?tab=event_rooms`)"
        >
          <span class="btn-inner--icon">
            <i class="fas fa-arrow-left"></i>
          </span>
        </button>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="card mt-5 h-200">
      <div class="p-3 pb-0 card-header">
        <div class="row">
          <div class="col-md-8 d-flex align-items-center">
            <h6 class="mb-0 text-bg-contrast">{{ $t("booked_room") }}</h6>
          </div>
          <div class="col-md-4 text-end">
            <button
              v-if="!this.editable"
              class="invisible-btn"
              @click="this.editable = true"
            >
              <i
                class="text-sm fas fa-pen text-primary"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :title="$t('edit')"
              ></i>
            </button>

            <button
              v-if="this.editable"
              class="invisible-btn"
              @click="editRoom()"
            >
              <i
                class="text-sm fas fa-check text-success"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :title="$t('save')"
              ></i>
            </button>
            <button
              v-if="this.editable"
              class="invisible-btn"
              @click="this.editable = false"
            >
              <i
                class="text-sm fas fa-ban text-danger"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :title="$t('cancel')"
              ></i>
            </button>
            <!-- TODO: add modal window with delete confirmation -->
            <button class="invisible-btn" @click="deleteRoom()">
              <i
                class="text-sm fas fa-trash text-danger"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :title="$t('delete')"
              ></i>
            </button>
          </div>
        </div>
      </div>
      <div class="p-3 card-body">
        <div class="row">
          <div class="col">
            <ul class="list-group">
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-bg-contrast">{{ $t("hotel_name") }}:</strong>
                &nbsp;
                <span v-if="!this.editable" class="text-primary">{{
                  room.hotel
                }}</span>
                <select
                  v-if="this.editable"
                  class="minimalistic-input"
                  v-model="editedRoom.hotel"
                >
                  <option
                    v-for="hotel in hotels"
                    :key="hotel.name"
                    :value="hotel.name"
                  >
                    {{ hotel.name }}
                  </option>
                </select>
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-bg-contrast">{{ $t("room_type") }}:</strong>
                &nbsp;
                <span v-if="!this.editable" class="text-primary">{{
                  room.room_type
                }}</span>
                <select
                  v-if="this.editable"
                  class="minimalistic-input"
                  v-model="editedRoom.room_type"
                >
                  <option
                    v-for="roomType in roomTypes"
                    :key="roomType"
                    :value="roomType"
                  >
                    {{ roomType }}
                  </option>
                </select>
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-bg-contrast">{{ $t("event_name") }}:</strong>
                &nbsp;
                <span class="text-primary">{{ room.event }}</span>
              </li>
            </ul>
          </div>
          <div class="col">
            <ul class="list-group">
              <li class="pt-0 text-sm border-0 list-group-item ps-0">
                <strong class="text-bg-contrast">{{ $t("check_in") }}:</strong> &nbsp;
                <span v-if="!this.editable" class="text-primary">{{
                  room.readableCheck_in
                }}</span>
                <input
                  v-if="this.editable"
                  type="datetime-local"
                  class="minimalistic-input"
                  :placeholder="room.check_in"
                  v-model="editedRoom.check_in"
                />
              </li>
              <li class="pt-0 text-sm border-0 list-group-item ps-0">
                <strong class="text-bg-contrast">{{ $t("check_out") }}:</strong>
                &nbsp;
                <span v-if="!this.editable" class="text-primary">{{
                  room.readableCheck_out
                }}</span>
                <input
                  v-if="this.editable"
                  type="datetime-local"
                  class="minimalistic-input"
                  :placeholder="room.check_out"
                  v-model="editedRoom.check_out"
                />
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-bg-contrast">{{ $t("comment") }}:</strong>
                &nbsp;
                <span v-if="!this.editable" class="text-primary">{{
                  room.comment
                }}</span>
                <input
                  v-if="this.editable"
                  type="text"
                  class="minimalistic-input"
                  :placeholder="room.comment"
                  v-model="editedRoom.comment"
                />
              </li>
            </ul>
          </div>
          <div class="col">
            <ul class="list-group mt-n2">
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-bg-contrast">{{ $t("free") }}:</strong>
                &nbsp;
                <span v-if="room.is_free">
                  <span class="badge bg-success">{{ $t("yes") }}</span>
                </span>
                <span v-else>
                  <span class="badge bg-danger">{{ $t("no") }}</span>
                </span>
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-bg-contrast">{{ $t("partly_occupied") }}:</strong>
                &nbsp;
                <span v-if="room.is_partly_occupied">
                  <span class="badge bg-success">{{ $t("yes") }}</span>
                </span>
                <span v-else>
                  <span class="badge bg-danger">{{ $t("no") }}</span>
                </span>
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-bg-contrast">{{ $t("fully_occupied") }}:</strong>
                &nbsp;
                <span v-if="room.is_fully_occupied">
                  <span class="badge bg-success">{{ $t("yes") }}</span>
                </span>
                <span v-else>
                  <span class="badge bg-danger">{{ $t("no") }}</span>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-4">
      <div class="p-3 pb-0 card-header">
        <div class="row">
          <div class="col-md-8 d-flex align-items-center">
            <h6 class="mb-0 text-bg-contrast">{{ $t("assigned_users") }}</h6>
          </div>
        </div>
      </div>
      <div class="card-body">
        <Timeline
          v-if="this.room['assigned_users']"
          :roomCheckIn="this.room.check_in"
          :roomCheckOut="this.room.check_out"
          :assignedUsers="this.room.assigned_users"
          :key="this.room"
        />

        <div v-if="checkAssignedUsers()" class="table-responsive">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-bg-contrast text-xxs font-weight-bolder opacity-7"
                >
                  <span class="d-flex justify-content-center">{{
                    $t("name")
                  }}</span>
                </th>
                <th
                  class="text-uppercase text-bg-contrast text-xxs font-weight-bolder opacity-7"
                >
                  {{ $t("check_in") }}
                </th>
                <th
                  class="text-uppercase text-bg-contrast text-xxs font-weight-bolder opacity-7"
                >
                  {{ $t("check_out") }}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody :key="room.assigned_users">
              <tr v-for="user in room.assigned_users" :key="user.user.id">
                <td class="d-flex justify-content-center">
                  <button
                    v-if="!user.notSaved"
                    class="invisible-btn full-size"
                    @click="this.$router.push('/users/' + user.user.id)"
                  >
                    <p class="text-sm font-weight-thin text-primary mb-0">
                      {{ user.user.full_name }}
                    </p>
                  </button>
                  <select
                    v-else
                    class="minimalistic-input full-size"
                    v-model="newUser.user"
                  >
                    <option
                      v-for="user in this.users"
                      :key="user.id"
                      :value="user.id"
                    >
                      {{ user.first_name }} {{ user.last_name }}
                    </option>
                  </select>
                </td>
                <td>
                  <p
                    v-if="!user.notSaved"
                    class="text-sm font-weight-thin mb-0"
                  >
                    {{ user.readableCheck_in }}
                  </p>
                  <input
                    v-if="user.notSaved"
                    type="date"
                    class="minimalistic-input"
                    :min="room.check_in.split('T')[0]"
                    :max="room.check_out.split('T')[0]"
                    v-model="newUser.check_in"
                  />
                </td>
                <td>
                  <p
                    v-if="!user.notSaved"
                    class="text-sm font-weight-thin mb-0"
                  >
                    {{ user.readableCheck_out }}
                  </p>
                  <input
                    v-if="user.notSaved"
                    type="date"
                    class="minimalistic-input"
                    :min="room.check_in.split('T')[0]"
                    :max="room.check_out.split('T')[0]"
                    v-model="newUser.check_out"
                  />
                </td>
                <td class="d-flex justify-content-center">
                  <span v-if="!user.notSaved">
                    <button
                      class="invisible-btn"
                      @click="this.$router.push('/users/' + user.user.id)"
                    >
                      <i class="fas fa-info text-primary"></i>
                    </button>
                    <button
                      class="invisible-btn"
                      @click="deleteUserAssignement(user.id)"
                      :title="$t('remove_user_from_room')"
                    >
                      <i class="fas fa-minus-circle text-danger"></i>
                    </button>
                  </span>
                  <span v-else>
                    <button
                      class="invisible-btn"
                      @click="saveUserAssignement()"
                      :title="$t('save')"
                    >
                      <i class="fas fa-save text-success"></i>
                    </button>
                    <button
                      class="invisible-btn"
                      @click="this.room.assigned_users.pop()"
                      :title="$t('cancel')"
                    >
                      <i class="fas fa-times-circle text-danger"></i>
                    </button>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="!room.is_fully_occupied" class="text-center">
          <div class="mb-1 mt-2">
            <i class="fas fa-plus fa-2x"></i>
          </div>
          <button class="btn btn-sm btn-primary" @click="addNewUser()">
            <span class="text-primary-contrast">{{ $t("add_user") }} </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Timeline from "@/components/Timeline.vue";

export default {
  name: "HotelRoom",
  components: {
    Timeline,
  },
  data() {
    return {
      room: {},
      editedRoom: {},
      editable: false,
      hotels: [],
      roomTypes: ["single", "double", "triple", "quad"],
      roomStats: [],
      newUser: {},
      users: [],
      defaultCheckIn: "",
      defaultCheckOut: "",
    };
  },
  methods: {
    checkAssignedUsers() {
      if (this.room.assigned_users) {
        return this.room.assigned_users.length > 0;
      }
      return false;
    },

    reformatDate(date) {
      return new Date(date).toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
    },

    async getRoomDetails() {
      await axios
        .get(`/api/v1/hotels/rooms/${this.$route.params.id}/`)
        .then((response) => {
          console.log(response.data);
          this.room = response.data;

          this.room.readableCheck_in = this.reformatDate(this.room.check_in);
          this.room.readableCheck_out = this.reformatDate(this.room.check_out);

          // reformat dates for room.assiged_users
          this.room.assigned_users.forEach((user) => {
            user.readableCheck_in = this.reformatDate(user.check_in);
            user.readableCheck_out = this.reformatDate(user.check_out);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getRoomStats() {
      await axios
        .get(`/api/v1/hotels/rooms/${this.$route.params.id}/get_stats/`)
        .then((response) => {
          this.roomStats = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getHotelsList() {
      await axios
        .get("/api/v1/hotels/?page_size=100&fields=name")
        .then((response) => {
          this.hotels = response.data.results;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async editRoom() {
      await axios
        .patch(`api/v1/hotels/rooms/${this.room.id}/`, this.editedRoom)
        .then(() => {
          this.editable = false;
          this.getRoomDetails();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async deleteRoom() {
      await axios
        .delete(`api/v1/hotels/rooms/${this.room.id}/`)
        .then(() => {
          this.$router.push(`/events/${this.room.eventId}?tab=event_rooms`);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async deleteUserAssignement(id) {
      await axios
        .delete(`api/v1/hotels/rooms/${this.room.id}/assignements/${id}/`)
        .then(() => {
          this.getRoomDetails();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getAllUsers() {
      await axios
        .get("/api/v1/users/?page_size=500&fields=id,first_name,last_name")
        .then((response) => {
          // sort users by last_name
          response.data.results.sort((a, b) => {
            if (a.last_name < b.last_name) {
              return -1;
            }
            if (a.last_name > b.last_name) {
              return 1;
            }
            return 0;
          });
          this.users = response.data.results;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async addNewUser() {
      this.getAllUsers();

      const tempUser = {
        user: {
          id: null,
          full_name: "",
        },
        check_in: "",
        check_out: "",
        notSaved: true,
      };

      this.room.assigned_users.push(tempUser);
    },

    async saveUserAssignement() {
      const data = {
        user: this.newUser.user,
        check_in: this.newUser.check_in,
        check_out: this.newUser.check_out,
      };

      await axios
        .post(`api/v1/hotels/rooms/${this.room.id}/assignements/`, data)
        .then(() => {
          this.getRoomDetails();
          this.newUser = {};
        })
        .catch((err) => {
          this.$store.dispatch("showAlert", {
            type: "error",
            message: err.response.data,
            timeout: 5000,
          });
        });
    },
  },
  created() {
    this.getRoomDetails();
    this.getHotelsList();
  },
};
</script>

<style scoped></style>
