<template>
  <div class="card h-100">
    <div class="card-header pb-0">
      <div class="row">
        <div class="col-lg-6 col-7">
          <h6 class="text-bg-contrast">{{ $t(this.cardName) }}</h6>
        </div>
        <div class="col text-end mx-auto">
          <select
            class="minimalistic-input"
            v-model="selectedStatus"
            @change="filterTable()"
          >
            <option value="all_statuses">{{ $t("all_statuses") }}</option>
            <option
              v-for="status in ['new', 'pending', 'accepted', 'declined']"
              :key="status"
              :value="status"
            >
              {{ $t(status) }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="card-body px-4 pb-2 pt-0">
      <div class="table-responsive">
        <table class="table align-items-center mb-0 h-100">
          <thead class="text-center">
            <tr>
              <th
                v-for="header in this.headers"
                :key="header"
                class="text-uppercase text-bg-contrast text-xxs font-weight-bolder opacity-7"
              >
                {{ $t(header) }}
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody class="text-center">
            <tr v-for="expense in this.filterTable()" :key="expense.id">
              <td>
                <button
                  class="invisible-btn full-size"
                  @click="this.$router.push(`/travel-expenses/${expense.id}`)"
                >
                  <p class="text-sm font-weight-bold mb-0 text-primary">
                    <span v-if="this.type === 'event_expenses'"
                      >{{ expense.event }}
                    </span>
                    <span v-else>{{ expense.trip_reason }}</span>
                  </p>
                </button>
              </td>
              <td>
                <h6 class="mb-0 text-sm text-bg-contrast">
                  {{ formatDate(expense.trip_start) }}
                </h6>
              </td>
              <td>
                <h6 class="mb-0 text-sm text-bg-contrast">
                  {{ formatDate(expense.trip_end) }}
                </h6>
              </td>
              <td>
                <span>
                  <span
                    v-if="expense.status === 'new'"
                    class="text-dark badge badge-sm bg-info"
                  >
                    {{ $t("new") }}
                  </span>
                  <span
                    v-if="expense.status === 'pending'"
                    class="text-dark badge badge-sm bg-warning"
                  >
                    {{ $t("pending") }}
                  </span>
                  <span
                    v-if="expense.status === 'accepted'"
                    class="text-dark badge badge-sm bg-success"
                  >
                    {{ $t("accepted") }}
                  </span>
                  <span
                    v-if="expense.status === 'declined'"
                    class="text-bg-contrast badge badge-sm bg-danger"
                    :title="expense.comment"
                  >
                    {{ $t("declined") }}
                  </span>
                </span>
              </td>

              <td class="align-middle text-center text-sm">
                <button
                  class="invisible-btn full-size"
                  @click="this.$router.push(`travel-expenses/${expense.id}`)"
                >
                  <i class="fas fa-eye text-bg-contrast"></i>
                </button>
              </td>
            </tr>
          </tbody>
          <div
            v-if="this.type === 'non_event_expenses'"
            class="position-absolute bottom-0 start-50 translate-middle-x"
          >
            <tr>
              <td colspan="5" class="text-center">
                <button
                  class="btn btn-sm btn-primary text-primary-contrast"
                  @click="createNotEventExpense()"
                >
                  {{ $t("add_expense") }}
                </button>
              </td>
            </tr>
          </div>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "travel-expenses-user-card",
  props: {
    cardName: {
      type: String,
      default: "travel_expenses",
    },
    headers: {
      type: Array,
      default: () => ["event", "from", "till", "status"],
    },
    expenses: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "event_expenses",
    },
    expensesTypes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedStatus: "all_statuses",
    };
  },
  methods: {
    formatDate(datetime) {
      if (datetime === null) return this.$t("not_set");
      return new Date(datetime).toLocaleString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });
    },

    filterTable() {
      if (this.selectedStatus === "all_statuses") {
        return this.expenses;
      } else {
        return this.expenses.filter((expense) => expense.status === this.selectedStatus);
      }
    },
    createNotEventExpense() {
      const user = this.$store.state.user;
      const type = this.expensesTypes.find((type) => type.is_default).type;
      const expense = {
        user: user.id,
        type: type,
        trip_start: null,
        trip_end: null,
      };
      axios
        .post(`api/v1/users/${user.id}/travel_expenses/`, expense)
        .then((resp) => {
          this.$router.push(`travel-expenses/${resp.data.id}`);
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("showAlert", {
            type: "danger",
            message: this.$t("error"),
            timeout: 3000,
          });
        });
    },
  },
};
</script>
<style scoped></style>
