<template>
    <div>
        <button class="invisible-btn d-flex d-inline" v-tooltip="text">
            <i class="text-xs fas fa-question-circle text-primary"></i>
        </button>
    </div>
</template>

<script>
import 'floating-vue/dist/style.css'

export default {
    name: "Tooltip",
    props: {
        text: {
            type: String,
            required: true
        }
    }
}
</script>
