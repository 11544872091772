<template>
  <div class="container-fluid">
    <div class="mb-n4 mx-4 overflow-hidden card card-body blur shadow-blur mt-0">
      <h6 class="mb-1">{{ $t("add_user") }}</h6>
    </div>
  </div>

  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="mb-4 card">
          <div class="card-body">
            <div class="container">
              <div class="row">
                <h6 class="text-bg-contrast">{{ $t("general_information") }}</h6>
              </div>
              <div class="row">
                <hr class="mb-4" />
                <div class="div col">
                  <input
                    name="first_name"
                    type="text"
                    class="form-control"
                    v-model="this.user.first_name"
                    required
                  />
                  <span class="small-label-up">{{ $t("first_name") }}</span>
                </div>
                <div class="div col">
                  <input
                    name="last_name"
                    type="text"
                    class="form-control"
                    v-model="this.user.last_name"
                    required
                  />
                  <span class="small-label-up">{{ $t("last_name") }}</span>
                </div>

                <div class="div col">
                  <input
                    name="username"
                    type="text"
                    class="form-control"
                    v-model="this.user.username"
                  />
                  <span class="small-label-up">{{ $t("username") }}</span>
                </div>
                <div class="div col">
                  <input
                    name="email"
                    type="email"
                    class="form-control"
                    v-model="this.user.email"
                    required
                  />
                  <span class="small-label-up">{{ $t("email") }}</span>
                </div>
              </div>
              <div class="row">
                <h6 class="text-bg-contrast">{{ $t("specific_information") }}</h6>
              </div>
              <div class="row">
                <hr class="mb-4" />
                <div class="div col">
                  <select
                    name="nationality"
                    class="form-control"
                    v-model="this.user.nationality"
                    required
                  >
                    <option
                      v-for="country in countries"
                      :key="country.code"
                      :value="country.code"
                    >
                      {{ country.name }}
                    </option>
                  </select>
                  <span class="small-label-up">{{ $t("nationality") }}</span>
                </div>
                <div class="div col">
                  <select
                    name="gender"
                    class="form-control"
                    v-model="this.user.gender"
                    required
                  >
                    <option
                      v-for="gender in ['M', 'F', 'D']"
                      :key="gender"
                      :value="gender"
                    >
                      {{ gender }}
                    </option>
                  </select>
                  <span class="small-label-up">{{ $t("gender") }}</span>
                </div>
                <div class="div col">
                  <input
                    name="date_of_birth"
                    type="date"
                    class="form-control"
                    v-model="this.user.date_of_birth"
                    required
                  />
                  <span class="small-label-up">{{ $t("date_of_birth") }}</span>
                </div>

                <div class="div col">
                  <input
                    name="company"
                    type="text"
                    class="form-control"
                    v-model="this.user.company"
                  />
                  <span class="small-label-up">{{ $t("company") }}</span>
                </div>
              </div>
              <div class="row">
                <h6 class="text-bg-contrast">{{ $t("address_information") }}</h6>
              </div>
              <div class="row">
                <hr class="mb-4" />
                <div class="div col">
                  <select
                    name="country"
                    class="form-control"
                    v-model="this.user.country"
                    required
                  >
                    <option
                      v-for="country in countries"
                      :key="country.code"
                      :value="country.code"
                    >
                      {{ country.name }}
                    </option>
                  </select>
                  <span class="small-label-up">{{ $t("country") }}</span>
                </div>
                <div class="div col">
                  <input
                    name="city"
                    type="text"
                    class="form-control"
                    v-model="this.user.city"
                  />
                  <span class="small-label-up">{{ $t("city") }}</span>
                </div>
                <div class="div col">
                  <input
                    name="address"
                    type="text"
                    class="form-control"
                    v-model="this.user.address"
                  />
                  <span class="small-label-up">{{ $t("address") }}</span>
                </div>
                <div class="div col">
                  <input
                    name="zip"
                    type="text"
                    class="form-control"
                    v-model="this.user.zip"
                  />
                  <span class="small-label-up">{{ $t("zipcode") }}</span>
                </div>
              </div>
              <div class="row">
                <small class="text-bg-contrast"
                  ><strong>*</strong>{{ $t("default_pass_is_users_username") }}.
                  {{ $t("user_will_be_prompted_to_change_pass") }}
                </small>
              </div>

              <div class="row">
                <div class="col-sm-12 pt-4 mb-n3">
                  <button
                    v-if="
                      this.user.first_name == '' ||
                      this.user.last_name == '' ||
                      this.user.email == ''
                    "
                    class="btn btn-sm btn-outline-success right"
                    @click="addUser"
                    disabled
                  >
                    {{ $t("add") }}
                  </button>
                  <button
                    v-else
                    class="btn btn-sm btn-outline-success right"
                    @click="addUser"
                  >
                    {{ $t("add") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  components: {},
  data() {
    return {
      countries: this.$store.state.countries,
      user: {},
      ErrorText: "",
    };
  },

  methods: {
    async addUser() {
      // TODO: change password assignemt - SECURITY!!!
      console.log(this.user);
      await axios.post(`/api/v1/users/`, this.user).then(
        (response) => {
          console.log(response);
          delete this.user.password;
          this.user = {};
          this.$store.dispatch("showAlert", {
            type: "success",
            message: this.$t("user_added_successfully"),
            timeout: 5000,
          });
        },
        (error) => {
          // Show error message below each input field
          console.log(error.response.data);
          let errorText = "";
          for (const [key, value] of Object.entries(error.response.data)) {
            errorText = `${key}: ${value};\n`;
            this.ErrorText += errorText;
          }
          this.$store.dispatch("showAlert", {
            type: "error",
            message: this.ErrorText,
            timeout: 5000,
          });
        }
      );
    },
  },
  created() {},
};
</script>
