<template>
  <div class="row">
    <div class="col-6 col-md-6 col-xl-6">
      <div class="card h-200">
        <div class="p-3 pb-0 card-header">
          <div class="row">
            <div class="col-md-8 d-flex align-items-center">
              <h6 class="mb-0 text-bg-contrast">{{ $t("unassigned_users") }}</h6>
            </div>
          </div>
        </div>
        <div class="p-3 card-body"></div>
      </div>
    </div>
    <div class="col-6 col-md-6 col-xl-6">
      <div class="card h-200">
        <div class="p-3 pb-0 card-header">
          <div class="row">
            <div class="col-md-8 d-flex align-items-center">
              <h6 class="mb-0 text-bg-contrast">{{ $t("assigned_users") }}</h6>
            </div>
          </div>
        </div>
        <div class="p-3 card-body"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "EventChampionshipEventsCard",
  components: {},
  data() {
    return {
      championship_events: {},
      unassignedChampionshipEvents: {},
      allJobs: [],
      championship_eventTracks: [],
      championship: [],
    };
  },

  methods: {
    // setChampionshipEventInfo() {
    //   this.championship_events = this.info;
    // },

    async unassignChampionshipEvent(championship_eventId) {
      await axios
        .post(`api/v1/championship_events/${championship_eventId}/unassign/`)
        .then(() => {
          this.unassignedChampionshipEvents.push(
            this.championship_events.find((championship_event) => championship_event.id === championship_eventId)
          );

          this.championship_events = this.championship_events.filter(
            (championship_event) => championship_event.id !== championship_eventId
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async assignChampionshipEvent(championship_eventId) {
      await axios
        .patch(`api/v1/championship_events/${championship_eventId}/`, {
          event: this.$parent.event.name,
        })
        .then(() => {
          this.championship_events.push(
            this.unassignedChampionshipEvents.find((championship_event) => championship_event.id === championship_eventId)
          );
          this.unassignedChampionshipEvents = this.unassignedChampionshipEvents.filter(
            (championship_event) => championship_event.id !== championship_eventId
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getUnassignedChampionshipEvents() {
      await axios
        .get(
          `api/v1/championship_events/?min_date=${this.$parent.event.start_date}&max_date=${this.$parent.event.end_date}&championship_event_track=${this.$parent.event.championship_event_track}&event_isnull=true`
        )
        .then((res) => {
          this.unassignedChampionshipEvents = res.data.results;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getEventChampionshipEvents() {
      await axios
        .get(`/api/v1/championship_events/?event_id=${this.$route.params.id}`)
        .then((response) => {
          this.championship_events = response.data.results;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getAllJobs() {
      await axios
        .get("/api/v1/jobs/")
        .then((response) => {
          this.allJobs = response.data.results.map((job) => job.name);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getChampionshipEventTracks() {
      await axios
        .get("/api/v1/raceways/?page_size=150")
        .then((response) => {
          this.championship_eventTracks = response.data.results;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getChampionship() {
      await axios
        .get("/api/v1/championship/?fields=id,name")
        .then((response) => {
          this.championship = response.data.results;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    addChampionshipEventToList() {
      // this.getChampionshipEventTracks();
      this.getChampionship();
      this.getAllJobs().then(() => {
        this.unassignedChampionshipEvents.push({
          name: "",
          championship: "",
          championship_event_track: this.$parent.event.championship_event_track,
          start_date: "",
          end_date: "",
          jobs: this.allJobs.map((job) => {
            return { job: job, members: 0 };
          }),
          notSaved: true,
        });
      });
    },

    async saveChampionshipEvent(championship_event) {
      delete championship_event.notSaved;
      championship_event.jobs = championship_event.jobs.filter((job) => job.members > 0);
      await axios
        .post("/api/v1/championship_events/", championship_event)
        .then(() => {
          this.getEventChampionshipEvents();
          this.getUnassignedChampionshipEvents();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    cancelChampionshipEvent(championship_event) {
      this.unassignedChampionshipEvents = this.unassignedChampionshipEvents.filter(
        (item) => item !== championship_event
      );
    },
  },

  props: {
    title: {
      type: String,
      required: true,
    },
  },
  created() {
    // this.setChampionshipEventInfo();
    this.getUnassignedChampionshipEvents();
    this.getEventChampionshipEvents();
  },
};
</script>
<style scoped></style>
