<template>
    <div class="card mt-4">
        <div class="p-3 pb-0 card-header">
            <div class="row">
                <div class="col-md-7 d-flex align-items-center">
                    <h6 class="mb-0 text-white">{{ $t(this.title) }}</h6>
                </div>
                <div v-if="this.getters.isSuperuser" class="col-md-5 text-end">
                    <slot name="headerBtn">
                        <IconBtn title="add_job" icon="fas fa-plus text-white" @click="addJob" />
                    </slot>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="table-responsive p-0 tableFixHead">
                <table class="table align-items-center mb-0">
                    <thead class="text-center">
                        <tr>
                            <th v-for="header in this.getHeaders()" :key="header"
                                class="text-uppercase text-primary fw-bold text-xxs px-0">
                                {{ $t(header) }}
                            </th>
                        </tr>
                    </thead>
                    <tbody class="text-center">
                        <tr v-for="job in this.jobs" :key="job.id">
                            <td>{{ job.job }}</td>
                            <td>
                                <span v-if="job.type === 'championship_event'">
                                    <div class="row mx-auto d-block">{{ $t(job.type) }}</div>
                                    <div class="row mx-auto d-block">{{ job.championship_event }}</div>
                                </span>
                                <span v-else>
                                    {{ $t(job.type) }}
                                </span>
                            </td>
                            <td>{{ job.members }}</td>
                            <td v-if="this.display_assigned">{{ job.assigned_users }}</td>
                            <td>
                                <slot name="actionBtn" :item="job">
                                    <IconBtn v-if="job.type === typeToDelete" title="delete"
                                        icon="fas fa-trash text-white" @click="openDeleteJobModal(job)" />
                                    <IconBtn v-else title="info" icon="fas fa-info text-white"
                                        @click="redirectTo(job)" />
                                </slot>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import IconBtn from "@/components/buttons/IconBtn.vue";
import { useStore } from "vuex";

export default {
    name: "JobsCard",
    components: {
        IconBtn,
    },
    data() {
        return {
            headers: ["job", "job_type", "members", "assigned"],
            jobEditable: false,
            jobsList: [],
            updatedJob: {
                job: "",
                members: "",
            },
        };
    },
    setup() {
        return {
            getters: useStore().getters,
        };
    },
    props: {
        mainObj: {
            type: Object,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
        typeToDelete: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            default: "jobs",
        },
        display_assigned: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        jobs() {
            return this.mainObj;
        },
    },
    methods: {
        getHeaders() {
            if (this.display_assigned) {
                return this.headers;
            } else {
                return this.headers.filter((header) => header !== "assigned");
            }
        },
        patchJob(job) {
            this.$emit("patchJob", job);
        },
        openDeleteJobModal(job) {
            this.$parent.ShowDeleteModal = true;
            this.$parent.selectedJobId = job.id;
        },

        addJob() {
            this.$parent.ShowModal = true;
        },
        redirectTo(job) {
            console.log(job);
            this.$emit("redirectTo", job)
        },

    },
};
</script>

<style scoped>
.card {
    min-height: 49vh;
    height: fit-content;
    max-height: 49vh;
    overflow-y: auto;
    overflow-x: auto;
}

.tableFixHead {
    height: 100%;
    max-width: 100%;
}
</style>
