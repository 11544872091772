<template>
  <AddJobModal
    @save="saveJob"
    :visible="ShowModal"
    v-if="ShowModal"
    title_text="add_job_for_event"
  />
  <DeleteAlert
    @deleteEvent="deleteJob"
    :msg="deleteMsg"
    :visible="ShowDeleteModal"
    v-if="ShowDeleteModal"
    :customClose="true"
    @closeModal="closeDeleteModal"
  />
  <div class="card h-200">
    <div class="p-3 pb-0 card-header">
      <div class="row">
        <div class="col-md-8 d-flex align-items-center">
          <h6 class="mb-0 text-bg-contrast">{{ this.title }}</h6>
        </div>
        <div class="col-md-4 text-end">
          <button
            v-if="!this.editable"
            class="invisible-btn"
            @click="this.editable = true"
          >
            <i
              class="text-sm fas fa-pen text-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :title="$t('edit')"
            ></i>
          </button>

          <button v-if="this.editable" class="invisible-btn" @click="patchEvent">
            <i
              class="text-sm fas fa-check text-success"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :title="$t('save')"
            ></i>
          </button>
          <button
            v-if="this.editable"
            class="invisible-btn"
            @click="this.editable = false"
          >
            <i
              class="text-sm fas fa-ban text-danger"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :title="$t('cancel')"
            ></i>
          </button>
        </div>
      </div>
    </div>
    <div class="p-3 card-body">
      <div class="row flex-column-reverse flex-md-row flex-sm-row">
        <div class="col-5">
          <ul class="list-group">
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast">{{ $t("event_name") }}:</strong> &nbsp;
              <span v-if="!this.editable" class="text-primary">{{ eventInfo.name }}</span>
              <input
                v-if="this.editable"
                type="text"
                class="minimalistic-input"
                :placeholder="eventInfo.name"
                v-model="updatedEventInfo.name"
              />
            </li>
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast">{{ $t("location") }}:</strong>
              &nbsp;
              <span v-if="!this.editable" class="text-primary">{{
                eventInfo.location
              }}</span>

              <select
                v-if="this.editable"
                class="minimalistic-input"
                v-model="updatedEventInfo.location"
                :placeholder="eventInfo.location"
              >
                <option v-for="track in locations" :value="track.name" :key="track">
                  {{ track.name }}<span class="text-danger"> ({{ track.country }})</span>
                </option>
              </select>
            </li>
          </ul>
        </div>

        <div class="col">
          <ul class="list-group">
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast">{{ $t("start_date") }}:</strong> &nbsp;
              <span v-if="!this.editable" class="text-primary">{{
                eventInfo.readable_start_date
              }}</span>
              <input
                v-if="this.editable"
                type="date"
                class="minimalistic-input"
                :placeholder="eventInfo.readable_start_date"
                v-model="updatedEventInfo.start_date"
              />
            </li>
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast">{{ $t("end_date") }}:</strong> &nbsp;
              <span v-if="!this.editable" class="text-primary">{{
                eventInfo.readable_end_date
              }}</span>
              <input
                v-if="this.editable"
                type="date"
                class="minimalistic-input"
                :placeholder="eventInfo.readable_end_date"
                v-model="updatedEventInfo.end_date"
              />
            </li>
          </ul>
        </div>
        <div class="col">
          <ul class="list-group">
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast">{{ $t("completion") }}:</strong>
              &nbsp;

              <span class="text-xs font-weight-bold mx-2 text-primary"
                >{{ eventInfo.percentage }}%</span
              >
              &nbsp;
              <span class="w-25 d-inline-block">
                <soft-progress
                  color="primary"
                  :percentage="parseInt(eventInfo.percentage)"
                />
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="row" v-if="this.eventInfo">
    <div class="col-md-6 col-sm-12">
      <JobsCard
        ref="jobCard"
        :mainObj="parseJobs(this.eventInfo)"
        type="event"
        typeToDelete="event"
        @redirectTo="redirectToChampionshipEvent"
      />
    </div>
    <div class="col-md-6 col-sm-12">
      <AssignUsersCard
        :mainObj="this.eventInfo"
        :parsedJobs="parseJobs(this.eventInfo)"
        ref="assignUsersCard"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AddJobModal from "@/components/alerts/AddJobModal.vue";
import DeleteAlert from "@/components/alerts/DeleteAlert.vue";
import SoftProgress from "@/components/SoftProgress.vue";
import JobsCard from "@/components/JobsCard.vue";
import AssignUsersCard from "@/components/AssignUsersCard.vue";

export default {
  name: "EventInfoCard",
  components: {
    SoftProgress,
    JobsCard,
    AddJobModal,
    DeleteAlert,
    AssignUsersCard,
  },
  data() {
    return {
      deleteMsg: "want_to_delete",
      eventInfo: {},
      updatedEventInfo: {},
      editable: false,
      jobEditable: false,
      ShowModal: false,
      ShowDeleteModal: false,
      ShowModalAssignement: false,
      selectedJobId: "",
    };
  },

  methods: {
    setEventInfo() {
      this.eventInfo = this.info;
    },
    closeDeleteModal() {
      this.ShowDeleteModal = false;
      this.selectedJobId = "";
    },

    saveJob(jobs) {
      const data = {
        jobs: jobs,
        elementId: this.eventInfo.id,
        type: "events",
      };
      this.$store.dispatch("addJob", data).then((resp) => {
        if (resp.status === 200) {
          this.$store.dispatch("showAlert", {
            message: this.$t("job_added"),
            type: "success",
            timeout: 3000,
          });
          this.$parent.getEventDetails();
        }
      });
    },

    saveAssignement(users) {
      console.log("saveAssignement");
      console.log(users);
      // const data = {
      //   users: users,
      //   elementId: this.eventInfo.id,
      //   type: "events",
      // };
      // this.$store.dispatch("addAssignement", data).then((resp) => {
      //   if (resp.status === 200) {
      //     this.$store.dispatch("showAlert", {
      //       message: this.$t("assignement_added"),
      //       type: "success",
      //       timeout: 3000,
      //     });
      //     this.$parent.getEventDetails();
      //   }

      // });
    },

    deleteJob() {
      const data = {
        jobId: this.selectedJobId,
        elementId: this.eventInfo.id,
        type: "events",
      };
      this.selectedJobId = "";
      this.ShowDeleteModal = false;
      this.$store.dispatch("removeJob", data).then((resp) => {
        if (resp.status === 204) {
          this.$store.dispatch("showAlert", {
            message: this.$t("job_deleted"),
            type: "success",
            timeout: 3000,
          });
          this.$parent.getEventDetails();
        }
      });
    },

    async patchEvent() {
      Object.keys(this.updatedEventInfo).forEach(
        (key) => this.updatedEventInfo[key] === "" && delete this.updatedEventInfo[key]
      );
      try {
        const response = await axios.patch(
          `/api/v1/events/${this.eventInfo.id}/`,
          this.updatedEventInfo
        );

        Object.keys(response.data).forEach((key) => {
          this.eventInfo[key] = response.data[key];
        });
        this.editable = false;
      } catch (error) {
        console.log(error);
      }
    },

    parseJobs(event) {
      let jobs = [];
      if (Array.isArray(event.jobs)) {
        for (let i = 0; i < event.jobs.length; i++) {
          jobs.push({
            id: event.jobs[i].id,
            job: event.jobs[i].job,
            members: event.jobs[i].members,
            assigned_users: event.jobs[i].assigned_users.length || 0,
            type: "event",
          });
        }
      }
      if (Array.isArray(event.championship_events)) {
        for (let i = 0; i < event.championship_events.length; i++) {
          if (Array.isArray(event.championship_events[i].jobs)) {
            for (let j = 0; j < event.championship_events[i].jobs.length; j++) {
              jobs.push({
                id: event.championship_events[i].jobs[j].id,
                job: event.championship_events[i].jobs[j].job,
                members: event.championship_events[i].jobs[j].members,
                assigned_users:
                  event.championship_events[i].jobs[j].assigned_users.length,
                type: "championship_event",
                championship_event: event.championship_events[i].name,
                championship_event_id: event.championship_events[i].id,
              });
            }
          }
        }
      }
      return jobs;
    },
    redirectToChampionshipEvent(job) {
      this.$router.push({
        name: "single_championship_event",
        params: {
          id: job.championship_event_id,
        },
      });
    },
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "event",
    },
    locations: {
      type: Array,
      default: () => [],
    },

    info: {
      type: Object,
      default: () => {},
    },
    action: {
      type: Object,
      route: String,
      tooltip: String,
      default: () => ({
        route: "javascript:;",
      }),
    },
  },
  mounted() {
    this.setEventInfo();
  },
};
</script>
<style scoped></style>
