<template>
  <div class="container">
    <div class="card w-100 d-inline-block overflow-auto" style="height: 85vh">
      <div class="card-header pb-0">
        <div class="row">
          <div class="col-md-4">
            <h6 class="text-primary">{{ $t(`${title}_expense`) }}</h6>
          </div>
          <div class="col-md-5 text-center">
            <div v-if="this.$parent.expense.status === 'new' && !this.$parent.isEditable">
              <button
                class="btn bg-primary text-info"
                @click="this.$parent.isEditable = true"
                :title="$t('edit')"
              >
                <i class="fas fa-pen"></i>
              </button>
              <button
                v-if="this.$parent.expense.id"
                class="btn bg-primary text-success"
                @click="approveExpense()"
                :title="$t('approve')"
              >
                <i class="fas fa-check"></i>
              </button>
              <button
                v-if="this.$parent.expense.id"
                class="btn bg-primary text-danger"
                @click="deleteExpense()"
                :title="$t('delete')"
              >
                <i class="fas fa-trash"></i>
              </button>
            </div>
            <div v-if="this.$parent.expense.status === 'new' && this.$parent.isEditable">
              <button
                v-if="!this.$parent.expense.id"
                class="btn invisible-btn text-success"
                @click="saveExpense()"
                :title="$t('save')"
              >
                <i class="fas fa-save"></i>
              </button>
              <button
                v-else
                class="btn bg-primary text-success"
                @click="updateExpense()"
                :title="$t('save')"
              >
                <i class="fas fa-save"></i>
              </button>
              <button
                class="btn bg-primary text-danger"
                @click="this.$parent.isEditable = false"
                :title="$t('cancel')"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>

            <span
              v-if="
                this.$store.state.user.is_superuser === true && this.$route.params.user_id
              "
            >
              <div v-if="this.$parent.expense.status === 'pending'">
                <button
                  class="btn bg-primary text-black bg-success"
                  @click="adminApproveExpense()"
                  :title="$t('approve')"
                >
                  {{ $t("approve") }}
                </button>
                <!-- button decline -->
                <button
                  class="btn bg-primary text-secondary bg-danger"
                  @click="adminDeclineExpense()"
                  :title="$t('decline')"
                >
                  {{ $t("decline") }}
                </button>
              </div>
              <div
                v-else-if="
                  this.$parent.expense.status === 'accepted' &&
                  !this.$parent.expense.is_paid
                "
              >
                <button
                  class="btn bg-primary text-black bg-info"
                  @click="adminPayExpense()"
                  :title="$t('pay')"
                >
                  {{ $t("pay") }}
                </button>
              </div>
            </span>
          </div>
          <div class="col-md-3 text-end">
            <!-- button back -->
            <button
              class="btn bg-primary text-info"
              @click="
                this.$router.push(
                  `/events/${this.$parent.expense.eventId}?tab=travel_expenses`
                )
              "
              :title="$t('to_expenses')"
            >
              <i class="fas fa-undo-alt"></i>
            </button>
            <button
              v-if="this.$parent.expense.status === 'accepted'"
              class="btn invisible-btn text-primary"
              @click="generatePDF()"
              :title="$t('download_pdf')"
            >
              <i class="fas fa-file-pdf"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="d-flex justify-content-center">
          <!-- modal window -->
          <PromptAlert
            @confirm="declineExpense"
            @closeModal="ShowModal = false"
            :msg="promptMsg"
            :visible="ShowPrompt"
            :inputString="promptInputString"
          />
          <vue3-html2pdf
            :show-layout="false"
            :float-layout="false"
            :enable-download="false"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            filename="expenses_report"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            :pdf-margin="20"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            ref="html2Pdf"
            :key="this.$parent.travelExpense.id"
          >
            <template v-slot:pdf-content>
              <slot name="pdf-content"></slot>
            </template>
          </vue3-html2pdf>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue3Html2pdf from "@erst-vg/vue3-html2pdf";
import PromptAlert from "@/components/alerts/PromptAlert.vue";
import axios from "axios";

export default {
  components: {
    Vue3Html2pdf,
    PromptAlert,
  },
  props: {
    title: {
      type: String,
      default: "leomax",
    },
  },
  data() {
    return {
      ShowPrompt: false,
      promptMsg: "",
      promptInputString: "",
      ShowModal: false,
    };
  },
  methods: {
    generatePDF() {
      this.$refs.html2Pdf.generatePdf();
    },
    async approveExpense() {
      if (this.$parent.user.bankdara === false || !this.$parent.signatureImg) {
        this.$store.dispatch("showAlert", {
          type: "error",
          message: this.$t("please_add_missing_data"),
          timeout: 5000,
        });
        return;
      }

      this.$parent.isEditable = false;
      await axios
        .patch(
          `/api/v1/users/${this.$parent.user.id}/travel_expenses/${this.$route.params.id}/`,
          {
            status: "pending",
          }
        )
        .then((response) => {
          this.$parent.expense = response.data;
          this.$store.dispatch("showAlert", {
            type: "success",
            message: this.$t("expense_approved"),
            timeout: 5000,
          });
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("showAlert", {
            type: "error",
            message: error.response.data,
            timeout: 5000,
          });
        });
    },
    async deleteExpense() {
      await axios
        .delete(
          `/api/v1/users/${this.$parent.user.id}/travel_expenses/${this.$route.params.id}/`
        )
        .then(() => {
          this.$store.dispatch("showAlert", {
            type: "success",
            message: this.$t("expense_deleted"),
            timeout: 5000,
          });
          this.$router.back();
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("showAlert", {
            type: "error",
            message: error.response.data,
            timeout: 5000,
          });
        });
    },

    cleanExpense(exspenseToSave) {
      exspenseToSave.type = this.$parent.type.type;
      exspenseToSave.trip_start = this.$parent.dates.startTime
        ? this.$parent.dates.startDate + "T" + this.$parent.dates.startTime
        : this.$parent.dates.startDate;
      exspenseToSave.trip_end = this.$parent.dates.endTime
        ? this.$parent.dates.endDate + "T" + this.$parent.dates.endTime
        : this.$parent.dates.endDate;

      if (
        exspenseToSave.other_expenses.length == 1 &&
        !exspenseToSave.other_expenses[0].length
      ) {
        delete exspenseToSave.other_expenses;
      }
      const car_expense = exspenseToSave.transport_expenses.find(
        (expense) => expense.type === "car"
      );
      if (car_expense.price == 0) {
        exspenseToSave.transport_expenses = exspenseToSave.transport_expenses.filter(
          (expense) => expense.type !== "car"
        );
      }
      const public_transport_expense = exspenseToSave.transport_expenses.find(
        (expense) => expense.type === "public_transport"
      );
      if (public_transport_expense.price == 0) {
        exspenseToSave.transport_expenses = exspenseToSave.transport_expenses.filter(
          (expense) => expense.type !== "public_transport"
        );
      }
      if (!exspenseToSave.transport_expenses.length) {
        delete exspenseToSave.transport_expenses;
      }
      if (!exspenseToSave.allowance_expenses.length) {
        delete exspenseToSave.allowance_expenses;
      }
      return exspenseToSave;
    },

    async saveExpense() {
      let exspenseToSave = { ...this.$parent.expense };
      this.$parent.isEditable = false;
      exspenseToSave = this.cleanExpense(exspenseToSave);

      await axios
        .post(`/api/v1/users/${this.$parent.user.id}/travel_expenses/`, exspenseToSave)
        .then(() => {
          this.$router.push({
            name: "travel_expenses",
          });
          this.$store.dispatch("showAlert", {
            type: "success",
            message: this.$t("expense_saved"),
            timeout: 5000,
          });
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("showAlert", {
            type: "error",
            message: error.response.data.message,
            timeout: 5000,
          });
        });
    },
    async updateExpense() {
      this.$parent.isEditable = false;
      if (this.$parent.dates.startDate) {
        this.$parent.expense.trip_start = this.$parent.dates.startTime
          ? this.$parent.dates.startDate + "T" + this.$parent.dates.startTime
          : this.$parent.dates.startDate;
      }
      if (this.$parent.dates.endDate) {
        this.$parent.expense.trip_end = this.$parent.dates.endTime
          ? this.$parent.dates.endDate + "T" + this.$parent.dates.endTime
          : this.$parent.dates.endDate;
      }
      if (this.$parent.expense.allowance_expenses) {
        delete this.$parent.expense.allowance_expenses.travel_expense;
        delete this.$parent.expense.allowance_expenses.id;
      }
      if (this.$parent.expense.transport_expenses) {
        this.$parent.expense.transport_expenses.forEach((expense) => {
          delete expense.travel_expense;
          delete expense.id;
        });
      }
      if (this.$parent.expense.other_expenses) {
        this.$parent.expense.other_expenses.forEach((expense) => {
          delete expense.travel_expense;
          delete expense.id;
        });
      }

      await axios
        .put(
          `/api/v1/users/${this.$parent.user.id}/travel_expenses/${this.$route.params.id}/`,
          this.$parent.expense
        )
        .then((response) => {
          this.$parent.expense = response.data;
          this.$store.dispatch("showAlert", {
            type: "success",
            message: this.$t("expense_updated"),
            timeout: 5000,
          });
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("showAlert", {
            type: "error",
            message: error.response.data,
            timeout: 5000,
          });
        });
    },
    adminApproveExpense() {
      axios
        .post(
          `/api/v1/users/${this.$parent.user.id}/travel_expenses/${this.$route.params.id}/accept/`
        )
        .then((response) => {
          this.$parent.expense.status = response.data.status;
          this.$parent.expense.accepted_by = response.data.accepted_by;
          this.$parent.expense.accepted_date = response.data.accepted_date;
          this.$store.dispatch("showAlert", {
            type: "success",
            message: this.$t("expense_approved"),
            timeout: 5000,
          });
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("showAlert", {
            type: "error",
            message: this.$t("expense_not_approved"),
            timeout: 5000,
          });
        });
    },
    adminPayExpense() {
      axios
        .post(
          `/api/v1/users/${this.$parent.user.id}/travel_expenses/${this.$route.params.id}/pay/`
        )
        .then((response) => {
          this.$parent.expense.is_paid = response.data.is_paid;
          this.$parent.expense.paid_by = response.data.paid_by;
          this.$parent.expense.paid_date = response.data.paid_date;
          this.$store.dispatch("showAlert", {
            type: "success",
            message: this.$t("expense_paid"),
            timeout: 5000,
          });
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("showAlert", {
            type: "error",
            message: this.$t("expense_paid_error"),
            timeout: 5000,
          });
        });
    },

    adminDeclineExpense() {
      this.ShowPrompt = true;
      this.promptMsg = "decline_expense_prompt";
    },
    async declineExpense(message) {
      await axios
        .patch(
          `/api/v1/users/${this.$parent.user_id}/travel_expenses/${this.$route.params.id}/`,
          {
            status: "declined",
            comment: message,
          }
        )
        .then(() => {
          this.$store.dispatch("showAlert", {
            type: "success",
            message: this.$t("expense_declined"),
            timeout: 5000,
          });
          this.$router.back();
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("showAlert", {
            type: "error",
            message: this.$t("error_occured"),
            timeout: 5000,
          });
        });
    },
  },
  created() {},
};
</script>
<style scoped>
.card {
  background: rgba(255, 255, 255, 0.5);
}
.title {
  font-size: 1.4rem;
  font-weight: 500;
  padding-top: 1rem;
  color: #000000;
}
.minimalistic-input {
  color: black;
}
</style>
