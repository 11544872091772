export default {
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalender"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "hotels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotels"])},
  "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veranstaltungen"])},
  "racings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rennen"])},
  "championship_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meisterschaftsevents"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
  "availabilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbarkeiten"])},
  "e-cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Cart-Konfigurator"])},
  "submited_expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingereichte Reisekosten"])},
  "pending_expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anstehende Reisekosten"])},
  "unpaid_expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbezahlte Reisekosten"])},
  "pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seiten"])},
  "sign_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
  "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einloggen"])},
  "next_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächste Veranstaltungen"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "racing_track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rennstrecke"])},
  "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startdatum"])},
  "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enddatum"])},
  "assigned_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugewiesene Benutzer"])},
  "assigned_racings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugewiesene Rennen"])},
  "assigned_championship_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugewiesene Meisterschaftsevents"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
  "add_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veranstaltung hinzufügen"])},
  "completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fertigstellung"])},
  "event_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name der Veranstaltung"])},
  "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veranstaltung"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
  "assigned_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugewiesene Aufträge"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
  "want_to_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie diesen Eintrag löschen?"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswählen"])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolg"])},
  "event_added_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veranstaltung erfolgreich hinzugefügt"])},
  "users_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer Tabelle"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "is_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ist Admin"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
  "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])},
  "profile_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilinformationen"])},
  "banking_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankinformationen"])},
  "travel_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reiseinformationen"])},
  "licenses_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lizenzinformationen"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
  "my_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Profil"])},
  "banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banking"])},
  "travel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisen"])},
  "licenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lizenzen"])},
  "date_of_birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsdatum"])},
  "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobil"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
  "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort bestätigen"])},
  "current_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelles Passwort"])},
  "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort"])},
  "confirm_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort bestätigen"])},
  "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ändern"])},
  "change_password_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort erfolgreich geändert"])},
  "change_password_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort konnte nicht geändert werden"])},
  "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ"])},
  "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
  "last_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Anmeldung"])},
  "is_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ist aktiv"])},
  "is_superuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ist Superuser"])},
  "is_staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ist Mitarbeiter"])},
  "edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil bearbeiten"])},
  "edit_bank_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankdetails bearbeiten"])},
  "edit_travel_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reiseinformationen bearbeiten"])},
  "edit_licenses_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lizenzinformationen bearbeiten"])},
  "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
  "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])},
  "bank_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankname"])},
  "holder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoinhaber"])},
  "cc_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreditkartennummer"])},
  "cc_expiration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreditkarte gültig bis"])},
  "cc_holder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreditkarteninhaber"])},
  "travel_passport_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisepassnummer"])},
  "travel_passport_validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisepass gültig bis"])},
  "travel_passport_scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisepass-Scan"])},
  "not_uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht hochgeladen"])},
  "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochladen"])},
  "personal_id_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalausweisnummer"])},
  "personal_id_validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalausweis gültig bis"])},
  "personal_id_scan_front": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalausweis-Scan (Vorderseite)"])},
  "personal_id_scan_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalausweis-Scan (Rückseite)"])},
  "driving_license_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Führerscheinnummer"])},
  "driving_license_validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Führerschein gültig bis"])},
  "driving_license_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Führerscheinart"])},
  "driving_license_scan_front": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Führerschein-Scan (Vorderseite)"])},
  "driving_license_scan_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Führerschein-Scan (Rückseite)"])},
  "preferred_airport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevorzugter Flughafen"])},
  "alternative_airport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternative Flughafen"])},
  "preferred_start_point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevorzugter Startpunkt"])},
  "license_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lizenznummer"])},
  "license_stage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lizenzstufe"])},
  "license_validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lizenz gültig bis"])},
  "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollen"])},
  "license_scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lizenz-Scan"])},
  "add_license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lizenz hinzufügen"])},
  "add_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer hinzufügen"])},
  "add_bankdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankdaten hinzufügen"])},
  "add_travel_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reiseinformationen hinzufügen"])},
  "general_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeine Informationen"])},
  "specific_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spezifische Informationen"])},
  "address_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adressinformationen"])},
  "user_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzereinstellungen"])},
  "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
  "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
  "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationalität"])},
  "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschlecht"])},
  "select_nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationalität auswählen"])},
  "select_gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschlecht auswählen"])},
  "select_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land auswählen"])},
  "user_added_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer wurde erfolgreich hinzugefügt"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
  "default_pass_is_users_username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standardpasswort ist Benutzername"])},
  "user_will_be_prompted_to_change_pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Benutzer wird bei der ersten Anmeldung aufgefordert, sein Passwort zu ändern"])},
  "event_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veranstaltungsinformationen"])},
  "edit_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veranstaltung bearbeiten"])},
  "event_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event-Infos"])},
  "event_racings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event-Rennen"])},
  "event_championship_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event-Meisterschaftsevents"])},
  "event_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event-Benutzer"])},
  "event_rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event-Unterkunft"])},
  "jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobs"])},
  "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "series": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rennserie"])},
  "championship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meisterschaft"])},
  "unassign_from_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von Event entfernen"])},
  "event_has_no_racings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veranstaltung hat keine zugewiesenen Rennen"])},
  "event_has_no_championship_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veranstaltung hat keine zugewiesenen Meisterschaftsevents"])},
  "unassigned_racings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht zugewiesene Rennen"])},
  "unassigned_championship_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht zugewiesene Meisterschaftsevents"])},
  "assign_to_the_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu Event hinzufügen"])},
  "no_racing_for_this_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine verfügbaren Rennen für diese Veranstaltung"])},
  "no_championship_event_for_this_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine verfügbaren Meisterschaftsevents für diese Veranstaltung"])},
  "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Völlig frei"])},
  "partly_occupied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilweise frei"])},
  "fully_occupied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ist voll"])},
  "event_has_no_rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veranstaltung hat keine zugewiesenen Räume"])},
  "room_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimmernummer"])},
  "room_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimmertyp"])},
  "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
  "check_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check In"])},
  "check_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Out"])},
  "booked_rooms_for_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchte Räume für Veranstaltung"])},
  "add_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchung hinzufügen"])},
  "export_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF exportieren"])},
  "remove_user_from_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer aus Zimmer entfernen"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
  "hotel_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotelname"])},
  "booked_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchtes Zimmer"])},
  "room_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimmer im"])},
  "create_racing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rennen erstellen"])},
  "create_championship_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meisterschaftsevent erstellen"])},
  "add_racing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rennen hinzufügen"])},
  "add_championship_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meisterschaftsevent hinzufügen"])},
  "location_has_no_hotels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort hat keine Hotels. Bitte fügen Sie zuerst ein Hotel hinzu"])},
  "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warnung"])},
  "all_hotels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Hotels"])},
  "add_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel hinzufügen"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
  "hotels_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotelliste"])},
  "rooms_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimmerliste"])},
  "all_racings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Rennen"])},
  "all_championship_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Meisterschaftsevents"])},
  "racings_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rennliste"])},
  "championship_events_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meisterschaftseventsliste"])},
  "series_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serienliste"])},
  "championship_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meisterschaftsliste"])},
  "all_series": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Serien"])},
  "all_championship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Meisterschaften"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahr"])},
  "abbreviation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abkürzung"])},
  "create_serie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serie erstellen"])},
  "accomodation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterkunft"])},
  "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
  "add_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimmer hinzufügen"])},
  "select_hotel_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie zuerst ein Hotel"])},
  "no_events_for_this_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Events in der Nähe dieses Hotels"])},
  "download_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht herunterladen"])},
  "nights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächte"])},
  "hotels_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotelsbericht"])},
  "previous_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorherige Veranstaltungen"])},
  "unassigned_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht zugewiesene Benutzer"])},
  "event_availabilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbarkeiten der Veranstaltung"])},
  "never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie"])},
  "travel_expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisekosten"])},
  "my_next_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine nächsten Veranstaltungen"])},
  "old_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altes Passwort"])},
  "passwords_not_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwörter stimmen nicht überein"])},
  "password_changed_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wurde erfolgreich geändert"])},
  "password_changed_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort konnte nicht geändert werden"])},
  "old_password_incorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altes Passwort ist falsch. Bitte versuchen Sie es erneut"])},
  "please_change_default_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte ändern Sie das Standardpasswort"])},
  "you_have_active_directory_sync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben Active Directory Sync. aktiviert. Bitten Sie den Administrator, Ihr Passwort im aktiven Verzeichnis zu ändern."])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen"])},
  "event_expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veranstaltungsbezogene Reisekosten"])},
  "my_travel_expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Reisekosten"])},
  "non_event_expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstige Reisekosten"])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von"])},
  "till": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bis"])},
  "add_expense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisekosten hinzufügen"])},
  "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund"])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neu"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausstehend"])},
  "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akzeptiert"])},
  "declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgelehnt"])},
  "all_statuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Status"])},
  "adac_expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADAC Reisekosten"])},
  "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genehmigen"])},
  "expense_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisekosten gespeichert"])},
  "expense_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisekosten aktualisiert"])},
  "expense_approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisekosten genehmigt"])},
  "expense_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisekosten gelöscht"])},
  "search_by_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche nach Name"])},
  "search_by_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche nach Hotel"])},
  "admin_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin Panel"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
  "number_of_rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Zimmer"])},
  "select_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel auswählen"])},
  "select_room_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimmertyp auswählen"])},
  "select_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status auswählen"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
  "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzen"])},
  "password_reset_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wurde erfolgreich zurückgesetzt"])},
  "password_reset_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort konnte nicht zurückgesetzt werden"])},
  "password_reset_error_active_directory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Benutzer wird mit Active Directory synchronisiert. Das Passwort kann nicht zurückgesetzt werden."])},
  "download_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF herunterladen"])},
  "no_expense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Reisekosten"])},
  "travel_expense_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisekosten hinzugefügt"])},
  "travel_expense_not_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisekosten nicht hinzugefügt"])},
  "select_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ auswählen"])},
  "add_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse hinzufügen"])},
  "per_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro Seite"])},
  "please_add_missing_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte fügen Sie fehlende Daten hinzu"])},
  "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablehnen"])},
  "next_expense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächste Reisekosten"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächste"])},
  "to_expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu Reisekosten"])},
  "no_more_expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine weiteren Reisekosten"])},
  "decline_expense_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie die Kosten ablehnen? Bitte geben Sie unten den Ablehnungsgrund ein"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablehnungsgrund"])},
  "expense_declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisekosten abgelehnt"])},
  "error_occured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Fehler ist aufgetreten"])},
  "new_expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Reisekosten"])},
  "my_next_booked_rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine nächsten gebuchten Hotelzimmer"])},
  "no_booked_rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine gebuchten Hotelzimmer"])},
  "draw_signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterschrift zeichnen"])},
  "signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterschrift"])},
  "edit_signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterschrift bearbeiten"])},
  "signature_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterschrift gespeichert"])},
  "signature_not_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterschrift nicht gespeichert"])},
  "alone_in_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allein im Zimmer"])},
  "shares_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilt Zimmer mit"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorherige"])},
  "Mon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mo."])},
  "Tue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Di."])},
  "Wed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi."])},
  "Thu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do."])},
  "Fri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fr."])},
  "Sat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sa."])},
  "Sun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So."])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woche"])},
  "earliest_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frühester Start"])},
  "latest_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spätestes Ende"])},
  "display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeige"])},
  "search_by_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche nach Monat"])},
  "select_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tage auswählen"])},
  "click to change status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie, um den Status zu ändern"])},
  "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbar"])},
  "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht verfügbar"])},
  "not_sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht sicher"])},
  "not_set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht gesetzt"])},
  "edit_weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wochen bearbeiten"])},
  "edit_selected_weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgewählte Wochen bearbeiten"])},
  "select_weeks_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie zuerst die zu bearbeitenden Wochen aus"])},
  "add_availabilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbarkeiten hinzufügen"])},
  "in_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Bearbeitung"])},
  "initial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initial"])},
  "not_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht akzeptiert"])},
  "submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akzeptiert"])},
  "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akzeptieren"])},
  "availability_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbarkeit akzeptiert"])},
  "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job"])},
  "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglieder"])},
  "select_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job auswählen"])},
  "add_job_for_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job für Veranstaltung hinzufügen"])},
  "racing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rennen"])},
  "championship_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meisterschaftsevent"])},
  "job_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job-Typ"])},
  "job_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job hinzugefügt für Veranstaltung"])},
  "add_event_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job für Veranstaltung hinzufügen"])},
  "job_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job gelöscht"])},
  "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugewiesen"])},
  "racing_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rennname"])},
  "championship_event_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meisterschaftseventsname"])},
  "racing_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renninformationen"])},
  "championship_event_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meisterschaftseventsinformationen"])},
  "edit_racing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rennen bearbeiten"])},
  "edit_championship_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meisterschaftsevent bearbeiten"])},
  "add_job_for_racing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job für Rennen hinzufügen"])},
  "add_job_for_championship_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job für Meisterschaftsevent hinzufügen"])},
  "default_series_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht zugewiesene Standardjobs"])},
  "defauld_championship_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht zugewiesene Standardjobs"])},
  "add_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job hinzufügen"])},
  "assign_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle zuweisen"])},
  "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuweisen"])},
  "series_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seriename"])},
  "championship_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meisterschaftsname"])},
  "series_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard-Serienjobs"])},
  "championship_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard-Meisterschaftsjobs"])},
  "series_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serieinformationen"])},
  "championship_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meisterschaftsinformationen"])},
  "edit_series": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serie bearbeiten"])},
  "edit_championship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meisterschaft bearbeiten"])},
  "add_jobs_to_racings?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobs für alle Rennen dieser Serie hinzufügen?"])},
  "add_jobs_to_championship_events?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobs für alle Meisterschaftsevents dieser Meisterschaft hinzufügen?"])},
  "add_job_for_series": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job für Serie hinzufügen"])},
  "add_job_for_championship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job für Meisterschaft hinzufügen"])},
  "assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugewiesen an"])},
  "unassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuweisung aufheben"])},
  "assign_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer zuweisen"])},
  "user_added_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer erfolgreich hinzugefügt"])},
  "something_went_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etwas ist schief gelaufen"])},
  "user_removed_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer wurde erfolgreich entfernt"])},
  "job_added_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job wurde erfolgreich hinzugefügt"])},
  "job_removed_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job wurde erfolgreich entfernt"])},
  "no_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben noch keine Jobs. Bitte kontaktieren Sie Ihren Administrator"])},
  "allowed_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erlaubte Jobs"])},
  "not_submitted_availabilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht akzeptierte Verfügbarkeiten"])},
  "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])},
  "hotel_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel hinzugefügt"])},
  "show_all_weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Wochen anzeigen"])},
  "show_edited_weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeitete Wochen anzeigen"])},
  "default_championship_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard-Meisterschaftsjobs"])},
  "January": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Januar"])},
  "February": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Februar"])},
  "March": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["März"])},
  "April": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
  "May": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai"])},
  "June": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juni"])},
  "July": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juli"])},
  "August": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
  "September": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
  "October": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oktober"])},
  "November": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
  "December": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezember"])},
  "download_excel_sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel-Datei herunterladen"])},
  "select_championships": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meisterschaften auswählen"])},
  "enter_to_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingabetaste zum Auswählen"])},
  "enter_to_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingabetaste zum Entfernen"])},
  "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgewählt"])},
  "username_or_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername oder E-Mail"])},
  "room_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimmer hinzugefügt"])},
  "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farbe"])},
  "clothes_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kleidergröße"])},
  "jacket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jacke"])},
  "pants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hose"])},
  "shirt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hemd"])},
  "profile_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil aktualisiert"])},
  "add_clothes_sizes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kleidergrößen hinzufügen"])},
  "travel_expense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisekostenabrechnung"])},
  "leomax_expense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leomax Reisekostenabrechnung"])},
  "adac_expense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADAC Reisekostenabrechnung"])},
  "x4in_expense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["x4in Reisekostenabrechnung"])},
  "sms_expense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS Reisekostenabrechnung"])},
  "is_test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ist Test"])},
  "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
  "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
  "undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht definiert"])},
  "type_car": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraftwagen"])},
  "type_public_transport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flug- und Bahnreisen"])},
  "type_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstige"])},
  "expense_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisekostenabrechnung bezahlt"])},
  "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezahlen"])},
  "expense_paid_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisekostenabrechnung konnte nicht bezahlt werden"])},
  "cost_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostenstelle"])},
  "new_expense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Reisekostenabrechnung"])},
  "expense_not_approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisekostenabrechnung nicht akzeptiert"])},
  "Leomax_expense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leomax Reisekostenabrechnung"])},
  "all_expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Reisekosten"])}
}