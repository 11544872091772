<template>
  <AddExpenseModal @save="saveTravelExpense" :visible="ShowModal" v-if="ShowModal" />
  <div class="container">
    <TableCard
      :headers="headers"
      :tableItems="filteredExpenses"
      searchBy="event"
      :sortAliases="{
        name: 'last_name',
        type: 'expense_type',
      }"
    >
      <template #cardHeader>
        <div class="mb-4">
          <select
            class="minimalistic-input"
            v-model="selectedStatus"
            @change="this.filterUsersByStatus"
          >
            <option
              v-for="status in ['all', 'new', 'pending', 'accepted', 'declined']"
              :key="status"
              :value="status"
            >
              {{ $t(status) }}
            </option>
          </select>
        </div>
      </template>

      <template #tableBody="slotItem">
        <td class="align-middle text-center text-sm">
          <button
            class="invisible-btn full-size"
            @click="this.$router.push(`/users/${slotItem.item.user}`)"
          >
            <p class="text-sm font-weight-bold mb-0 text-primary">
              {{ slotItem.item.user_full_name }}
            </p>
          </button>
        </td>
        <td class="align-middle text-center text-sm">
          <p class="text-sm font-weight-bold mb-0 text-primary">
            <span v-if="slotItem.item.event">{{ slotItem.item.event }} </span>
            <span v-else>{{ slotItem.item.trip_reason }}</span>
          </p>
        </td>
        <td class="align-middle text-center text-sm">
          <h6 class="mb-0 text-sm text-bg-contrast">
            {{ formatDate(slotItem.item.trip_start) }}
          </h6>
        </td>
        <td class="align-middle text-center text-sm">
          <h6 class="mb-0 text-sm text-bg-contrast">
            {{ formatDate(slotItem.item.trip_end) }}
          </h6>
        </td>
        <td class="align-middle text-center text-sm">
          <span :class="statusClasses[slotItem.item.status]">
            {{ $t(slotItem.item.status) }}
          </span>
        </td>

        <td class="align-middle text-center text-sm">
          <button
            class="invisible-btn full-size"
            @click="
              this.$router.push(
                `user/${slotItem.item.user}/travel-expenses/${slotItem.item.id}`
              )
            "
          >
            <i class="fas fa-eye text-bg-contrast"></i>
          </button>
        </td>
      </template>
    </TableCard>
  </div>
</template>

<script lang="js">
import AddExpenseModal from "@/components/alerts/AddExpenseModal.vue";
import TableCard from "@/components/TableCard.vue";
import { useStore } from "vuex";
import axios from "axios";

export default {
    name: "users-travelexpenses-table",
    components: {
        TableCard,
        AddExpenseModal,
    },
    data() {
        return {
            ShowModal: false,
            headers: ["user", "reason", "from", "till", "status"],
            expenses: [],
            users: [],
            expenseTypes: [],
            selectedStatus: localStorage.getItem("EventExpensesSelectedStatus")
                ? localStorage.getItem("EventExpensesSelectedStatus")
                : "all",
            statusClasses:
            {
                "pending": "badge badge-sm bg-warning text-dark",
                "accepted": "badge badge-sm bg-success text-dark",
                "declined": "badge badge-sm bg-danger text-white",
                "new": "badge badge-sm bg-info text-dark",
            },
        };
    },
    setup() {
        return {
            getters: useStore().getters,
        };
    },
    computed: {

        sortedExpenses() {
            return [...this.usersWithExpenses].sort((a, b) => {
                if (a.event < b.event) {
                    return -1;
                }
                if (a.event > b.event) {
                    return 1;
                }
                return 0;
            });
        },
        filteredExpenses() {
            if (this.selectedStatus === "all") {
                return this.expenses;
            } else {
                return this.expenses.filter(
                    (expense) =>
                        expense.status === this.selectedStatus
                );
            }
        }
    },
    methods: {
        formatDate(datetime) {
            if (datetime === null) return this.$t("not_set");
            return new Date(datetime).toLocaleString("de-DE", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
            });
        },
        async getUsers() {
            await this.$store.dispatch("getUsersFromAllPagesShort").then(() => {
                this.users = this.getters.getUsersShort;
            });
        },
        async getAllExpenses() {
            await this.$store.dispatch("getAllTravelExpenses").then((resp) => {
                this.expenses = resp.results;
            });
        },
        filterExpensesByStatus() {
            if (this.selectedStatus === "all") {
                this.filteredExpenses = this.expenses;
            } else {
                this.filteredExpenses = this.expenses.filter(
                    (expense) =>
                        expense.status === this.selectedStatus
                );
            }
        },

        async getExpensesTypes() {
            await axios.get("api/v1/travel_expenses/types/").then((response) => {
                this.expenseTypes = response.data.results;
            });
        },

        sortUsersList() {
            this.users.sort((a, b) => {
                if (a.last_name < b.last_name) {
                    return -1;
                }
                if (a.last_name > b.last_name) {
                    return 1;
                }
                return 0;
            });
        },
        paginate() {
            this.$refs.tableChild.paginateByPage();
        },
    },
    mounted() {
    },
    created() {
        this.getAllExpenses();
    },
};
</script>
