<template>
  <div class="card h-200">
    <div class="p-3 pb-0 card-header">
      <div class="row">
        <div class="col-md-8 d-flex align-items-center">
          <h6 class="mb-0 text-bg-contrast">{{ title }}</h6>
        </div>
        <div class="col-md-4 text-end">
          <button
            v-if="!travelData.editable"
            class="invisible-btn"
            @click="travelData.editable = true"
          >
            <i
              class="text-sm fas fa-pen text-bg-contrast"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :title="$t('edit')"
            ></i>
          </button>

          <button
            v-if="travelData.editable"
            class="invisible-btn"
            @click="travelData.createNew ? postTravelData() : patchTravelData()"
          >
            <i
              class="text-sm fas fa-check text-success"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :title="$t('save')"
            ></i>
          </button>
          <button
            v-if="travelData.editable"
            class="invisible-btn"
            @click="travelData.editable = false"
          >
            <i
              class="text-sm fas fa-ban text-danger"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :title="$t('cancel')"
            ></i>
          </button>
        </div>
      </div>
    </div>
    <div class="p-3 card-body">
      <div class="row">
        <div class="col">
          <ul class="list-group">
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast"
                >{{ $t("travel_passport_number") }}:</strong
              >
              &nbsp;
              <span v-if="!travelData.editable" class="text-primary fw-bold">{{
                travelData.travel_passport_number
              }}</span>
              <input
                v-if="travelData.editable"
                type="text"
                class="minimalistic-input"
                :placeholder="travelData.travel_passport_number"
                v-model="updatedTravelData.travel_passport_number"
              />
            </li>
            <li class="text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast"
                >{{ $t("travel_passport_validity") }}:</strong
              >
              &nbsp;
              <span v-if="!travelData.editable" class="text-primary fw-bold">{{
                travelData.travel_passport_validity
              }}</span>
              <input
                v-if="travelData.editable"
                type="date"
                class="minimalistic-input"
                :placeholder="travelData.travel_passport_validity"
                v-model="updatedTravelData.travel_passport_validity"
              />
            </li>

            <FileDownloadElement
              text="travel_passport_scan"
              :mainObj="travelData"
              :fileUrl="travelData.travel_passport_scan"
              :fileType="'travel_passport_scan'"
              @getFile="getFile"
              @upload="handleFileUpload"
            />

            <li class="text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast"
                >{{ $t("personal_id_number") }}:</strong
              >
              &nbsp;
              <span v-if="!travelData.editable" class="text-primary fw-bold">{{
                travelData.personal_id_number
              }}</span>
              <input
                v-if="travelData.editable"
                type="text"
                class="minimalistic-input"
                :placeholder="travelData.personal_id_number"
                v-model="updatedTravelData.personal_id_number"
              />
            </li>
            <li class="text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast"
                >{{ $t("personal_id_validity") }}:</strong
              >
              &nbsp;
              <span v-if="!travelData.editable" class="text-primary fw-bold">{{
                travelData.personal_id_validity
              }}</span>
              <input
                v-if="travelData.editable"
                type="date"
                class="minimalistic-input"
                :placeholder="travelData.personal_id_validity"
                v-model="updatedTravelData.personal_id_validity"
              />
            </li>

            <FileDownloadElement
              text="personal_id_scan_front"
              :mainObj="travelData"
              :fileUrl="travelData.personal_id_scan_front"
              :fileType="'personal_id_scan_front'"
              @getFile="getFile"
              @upload="handleFileUpload"
            />

            <FileDownloadElement
              text="personal_id_scan_back"
              :mainObj="travelData"
              :fileUrl="travelData.personal_id_scan_back"
              :fileType="'personal_id_scan_back'"
              @getFile="getFile"
              @upload="handleFileUpload"
            />
          </ul>
        </div>
        <div class="col">
          <ul class="list-group">
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast"
                >{{ $t("driving_license_number") }}:</strong
              >
              &nbsp;
              <span v-if="!travelData.editable" class="text-primary fw-bold">{{
                travelData.driving_license_number
              }}</span>
              <input
                v-if="travelData.editable"
                type="text"
                class="minimalistic-input"
                :placeholder="travelData.driving_license_number"
                v-model="updatedTravelData.driving_license_number"
              />
            </li>
            <li class="text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast"
                >{{ $t("driving_license_validity") }}:</strong
              >
              &nbsp;
              <span v-if="!travelData.editable" class="text-primary fw-bold">{{
                travelData.driving_license_validity
              }}</span>
              <input
                v-if="travelData.editable"
                type="date"
                class="minimalistic-input"
                :placeholder="travelData.driving_license_validity"
                v-model="updatedTravelData.driving_license_validity"
              />
            </li>
            <li class="text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast"
                >{{ $t("driving_license_category") }}:</strong
              >
              &nbsp;
              <span v-if="!travelData.editable" class="text-primary fw-bold">{{
                travelData.driving_license_category
              }}</span>
              <!-- select with choise A, B or D -->
              <select
                v-if="travelData.editable"
                class="minimalistic-input"
                :placeholder="travelData.driving_license_category"
                v-model="updatedTravelData.driving_license_category"
              >
                <option
                  v-for="category in this.drivingCategories"
                  :key="category"
                  :value="category"
                >
                  {{ category }}
                </option>
              </select>
            </li>

            <FileDownloadElement
              text="driving_license_scan_front"
              :mainObj="travelData"
              :fileUrl="travelData.driving_license_scan_front"
              :fileType="'driving_license_scan_front'"
              @getFile="getFile"
              @upload="handleFileUpload"
            />
            <FileDownloadElement
              text="driving_license_scan_back"
              :mainObj="travelData"
              :fileUrl="travelData.driving_license_scan_back"
              :fileType="'driving_license_scan_back'"
              @getFile="getFile"
              @upload="handleFileUpload"
            />
          </ul>
        </div>
        <div class="col">
          <ul class="list-group">
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast">{{ $t("preferred_airport") }}:</strong>
              &nbsp;
              <span v-if="!travelData.editable" class="text-primary fw-bold">{{
                travelData.preferred_airport
              }}</span>
              <input
                v-if="travelData.editable"
                type="text"
                class="minimalistic-input"
                :placeholder="travelData.preferred_airport"
                v-model="updatedTravelData.preferred_airport"
              />
            </li>
            <li class="text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast"
                >{{ $t("alternative_airport") }}:</strong
              >
              &nbsp;
              <span v-if="!travelData.editable" class="text-primary fw-bold">{{
                travelData.alternative_airport
              }}</span>
              <input
                v-if="travelData.editable"
                type="text"
                class="minimalistic-input"
                :placeholder="travelData.alternative_airport"
                v-model="updatedTravelData.alternative_airport"
              />
            </li>
            <li class="text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast"
                >{{ $t("preferred_start_point") }}:</strong
              >
              &nbsp;
              <span v-if="!travelData.editable" class="text-primary fw-bold">{{
                travelData.preferred_start_point
              }}</span>
              <input
                v-if="travelData.editable"
                type="text"
                class="minimalistic-input"
                :placeholder="travelData.preferred_start_point"
                v-model="updatedTravelData.preferred_start_point"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileDownloadElement from "@/components/FileDownloadElement.vue";
import axios from "axios";

export default {
  name: "ProfileInfoCard",
  components: {
    FileDownloadElement,
  },

  data() {
    return {
      editable: false,
      travelData: {},
      updatedTravelData: {},
      drivingCategories: [
        "AM",
        "A1",
        "A2",
        "A",
        "B1",
        "B",
        "C1",
        "C",
        "D1",
        "D",
        "BE",
        "C1E",
        "CE",
        "D1E",
        "DE",
        "L",
        "T",
      ],
    };
  },

  methods: {
    setTravelData() {
      this.travelData = this.info;
    },
    handleFileUpload(event, _, type) {
      const file = event.target.files[0];
      this.updatedTravelData[type] = file;
    },

    postTravelData() {
      // TODO: mark required fields with *
      const formData = new FormData();
      for (const key in this.updatedTravelData) {
        formData.append(key, this.updatedTravelData[key]);
      }
      axios
        .post(`/api/v1/users/${this.user_id}/travelsettings/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          Object.keys(response.data).forEach((key) => {
            this.travelData[key] = response.data[key];
          });
          this.travelData.editable = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    patchTravelData() {
      Object.keys(this.updatedTravelData).forEach(
        (key) =>
          this.updatedTravelData[key] === "" &&
          delete this.updatedTravelData[key]
      );

      const formData = new FormData();
      Object.keys(this.updatedTravelData).forEach((key) => {
        formData.append(key, this.updatedTravelData[key]);
      });

      axios
        .patch(`/api/v1/users/${this.user_id}/travelsettings/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          Object.keys(response.data).forEach((key) => {
            this.travelData[key] = response.data[key];
          });
          this.travelData.editable = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getFile(path) {
      const fileName = path.split("/").pop();
      await axios
        .get(`api/v1/files/${this.username}/${fileName}/`, {
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        });
    },
  },

  created() {
    this.setTravelData();
  },

  props: {
    user_id: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      default: "profile",
    },
    username: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    info: {
      type: Object,
      default: () => {},
    },
    action: {
      type: Object,
      route: String,
      tooltip: String,
      default: () => ({
        route: "javascript:;",
      }),
    },
  },
};
</script>
