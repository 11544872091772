<template>
  <AddJobModal
    @save="saveJob"
    :visible="ShowModal"
    v-if="ShowModal"
    title_text="add_job_for_championship"
  >
    <template #secondBtn>
      <div class="form-check form-switch px-0">
        <label class="form-check-label text-bg-contrast" for="flexSwitchCheckDefault">{{
          $t("add_jobs_to_championship_events?")
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="flexSwitchCheckDefault"
          v-model="this.applyToAllChampionshipEvents"
        />
      </div>
    </template>
  </AddJobModal>
  <DeleteAlert
    @deleteEvent="deleteJob"
    :msg="deleteMsg"
    :visible="ShowDeleteModal"
    v-if="ShowDeleteModal"
    :customClose="true"
    @closeModal="closeDeleteModal"
  />
  <div class="card h-200">
    <div class="p-3 pb-0 card-header">
      <div class="row">
        <div class="col-md-8 d-flex align-items-center">
          <h6 class="mb-0 text-bg-contrast">{{ this.title }}</h6>
        </div>
        <div class="col-md-4 text-end">
          <button
            v-if="!this.editable"
            class="invisible-btn"
            @click="this.editable = true"
          >
            <i
              class="text-sm fas fa-pen text-bg-contrast"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :title="$t('edit')"
            ></i>
          </button>

          <button v-if="this.editable" class="invisible-btn" @click="patchChampionship">
            <i
              class="text-sm fas fa-check text-success"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :title="$t('save')"
            ></i>
          </button>
          <button
            v-if="this.editable"
            class="invisible-btn"
            @click="this.editable = false"
          >
            <i
              class="text-sm fas fa-ban text-danger"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :title="$t('cancel')"
            ></i>
          </button>
        </div>
      </div>
    </div>
    <div class="p-3 card-body">
      <div class="row flex-column-reverse flex-md-row flex-sm-row">
        <div class="col-5">
          <ul class="list-group">
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast">{{ $t("championship_name") }}:</strong> &nbsp;
              <span v-if="!this.editable" class="text-primary fw-bold">{{
                championshipInfo.name
              }}</span>
              <input
                v-if="this.editable"
                type="text"
                class="minimalistic-input"
                :placeholder="championshipInfo.name"
                v-model="updatedchampionshipInfo.name"
              />
            </li>
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast">{{ $t("year") }}:</strong> &nbsp;
              <span v-if="!this.editable" class="text-primary fw-bold">{{
                championshipInfo.year
              }}</span>
              <select
                v-if="this.editable"
                class="minimalistic-input"
                v-model="updatedchampionshipInfo.year"
              >
                <option v-for="year in this.years" :value="year" :key="year">
                  {{ year }}
                </option>
              </select>
            </li>
          </ul>
        </div>

        <div class="col">
          <ul class="list-group">
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast">{{ $t("type") }}:</strong> &nbsp;
              <span v-if="!this.editable" class="text-primary fw-bold">{{
                championshipInfo.type
              }}</span>
              <select
                v-if="this.editable"
                class="minimalistic-input"
                v-model="updatedchampionshipInfo.type"
              >
                <option
                  v-for="type in this.championshipTypes"
                  :value="type.name"
                  :key="type.name"
                >
                  {{ type.name }}
                </option>
              </select>
            </li>
            <ColorInput
              v-if="championshipInfo.color"
              :color="championshipInfo.color"
              @changeColor="changeColor"
              label="color"
            />
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 col-sm-12">
      <JobsCard
        ref="jobCard"
        v-if="this.championshipInfo"
        :mainObj="parseJobs(this.championshipInfo)"
        type="championship"
        typeToDelete="championship"
        title="championship_jobs"
        :display_assigned="false"
      />
    </div>
  </div>
</template>

<script>
import AddJobModal from "@/components/alerts/AddJobModal.vue";
import DeleteAlert from "@/components/alerts/DeleteAlert.vue";
import JobsCard from "@/components/JobsCard.vue";
import ColorInput from "@/components/inputs/ColorInput.vue";

export default {
  name: "ChampionshipInfoCard",
  components: {
    JobsCard,
    AddJobModal,
    DeleteAlert,
    ColorInput,
  },
  data() {
    return {
      years: Array.from(new Array(10), (val, index) => index + new Date().getFullYear()),
      deleteMsg: "want_to_delete",
      championshipInfo: {},
      championshipTypes: [],
      updatedchampionshipInfo: {},
      editable: false,
      jobEditable: false,
      ShowModal: false,
      ShowDeleteModal: false,
      selectedJobId: "",
      applyToAllChampionshipEvents: false,
    };
  },

  methods: {
    changeColor(color) {
      console.log(color);
      this.updatedchampionshipInfo.color = color;
    },
    setChampionshipEventInfo() {
      this.championshipInfo = this.info;
    },
    closeDeleteModal() {
      this.ShowDeleteModal = false;
      this.selectedJobId = "";
    },

    getChampionshipTypes() {
      this.$store.dispatch("fetchChampionshipTypes").then((resp) => {
        if (resp.count > 0) {
          this.championshipTypes = resp.results;
        }
      });
    },

    saveJob(jobs) {
      const data = {
        jobs: jobs,
        elementId: this.championshipInfo.id,
        type: "championship",
        update_championship_events: this.applyToAllChampionshipEvents,
      };
      this.$store.dispatch("addJob", data).then((resp) => {
        if (resp.status === 200) {
          this.$store.dispatch("showAlert", {
            message: this.$t("job_added"),
            type: "success",
            timeout: 3000,
          });
          this.$parent.getChampionshipDetails();
        }
      });
    },

    deleteJob() {
      const data = {
        jobId: this.selectedJobId,
        elementId: this.championshipInfo.id,
        type: "championship",
      };
      this.selectedJobId = "";
      this.ShowDeleteModal = false;
      this.$store.dispatch("removeJob", data).then((resp) => {
        if (resp.status === 204) {
          this.$store.dispatch("showAlert", {
            message: this.$t("job_deleted"),
            type: "success",
            timeout: 3000,
          });
          this.$parent.getChampionshipDetails();
        }
      });
    },

    patchChampionship() {
      const data = {
        id: this.championshipInfo.id,
        championship: this.updatedchampionshipInfo,
      };
      this.$store.dispatch("patchChampionship", data).then((resp) => {
        if (resp.status === 200) {
          this.$store.dispatch("showAlert", {
            message: this.$t("championship_updated"),
            type: "success",
            timeout: 3000,
          });
          this.$parent.getChampionshipDetails();
        }
      });
    },

    parseJobs(championship) {
      let jobs = [];
      if (Array.isArray(championship.jobs)) {
        for (let i = 0; i < championship.jobs.length; i++) {
          jobs.push({
            id: championship.jobs[i].id,
            job: championship.jobs[i].job,
            members: championship.jobs[i].members,
            type: "championship",
          });
        }
      }
      return jobs;
    },
    assignAllChampionshipJobs() {
      let jobs = [];
      this.parseJobs(this.championshipInfo, "championship").forEach((job) => {
        jobs.push({
          job: job.job,
          members: job.members,
        });
      });
      console.log(jobs);
      this.saveJob(jobs);
    },

    assignSingleJob(slotItem) {
      this.saveJob([
        {
          job: slotItem.item.job,
          members: slotItem.item.members,
        },
      ]);
    },
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "championship",
    },
    championship_tracks: {
      type: Array,
      default: () => [],
    },

    info: {
      type: Object,
      default: () => {},
    },
    action: {
      type: Object,
      route: String,
      tooltip: String,
      default: () => ({
        route: "javascript:;",
      }),
    },
  },
  mounted() {
    this.setChampionshipEventInfo();
    this.getChampionshipTypes();
  },
};
</script>
<style scoped></style>
