<template>
  <div class="py-4 container-fluid">
    <div
      class="mb-n4 mx-4 overflow-hidden card card-body blur shadow-blur mt-n4"
    >
      <div class="row gx-4">
        <div class="col-auto">
          <h6 class="mb-1">{{ $t("accomodation") }}</h6>
        </div>
        <SwitchMenu
          :menuList="['hotels_list', 'rooms_list']"
          :switchMenuFunc="switchCard"
        />
      </div>
    </div>
    <div class="py-4 container-fluid">
      <div class="mt-4 row">
        <div
          v-if="this.activeCard == 'hotels_list'"
          class="col-12 col-md-12 col-xl-12 fixed-container"
        >
          <HotelsList />
        </div>
        <div
          v-if="this.activeCard == 'rooms_list'"
          class="col-12 col-md-12 col-xl-12 fixed-container"
        >
          <RoomsList />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HotelsList from "./components/HotelsList.vue";
import RoomsList from "./components/RoomsList.vue";
import SwitchMenu from "@/components/SwitchMenu.vue";

export default {
  components: {
    HotelsList,
    RoomsList,
    SwitchMenu,
  },
  data() {
    return {
      activeCard: "hotels_list",
    };
  },
  methods: {
    switchCard(card) {
      this.activeCard = card;
    },
  },
};
</script>

<style scoped></style>
