<template>
  <div class="py-4 container-fluid pb-0 pt-0 h-75">
    <div class="row">
      <div class="col-12">
        <users-table />
      </div>
    </div>
  </div>
</template>

<script>
import UsersTable from "./components/UsersTable";

export default {
  name: "tables-item",
  components: {
    UsersTable,
  },
};
</script>
