<template>
  <div class="row justify-content-center w-100">
    <div class="col-md-4 allign-center">
      <div class="card d-flex align-items-center">
        <div class="card-header">
          <h4 class="card-title text-bg-contrast">{{ $t("change_password") }}</h4>
        </div>

        <div class="card-body">
          <form class="text-center" @submit.prevent="submitForm">
            <div class="form-group">
              <label for="oldPassword" class="text-bg-contrast">{{
                $t("old_password")
              }}</label>
              <input
                type="password"
                id="oldPassword"
                class="form-control allign-center"
                v-model="user.password"
              />
            </div>
            <div class="form-group">
              <label for="newPassword" class="text-bg-contrast">{{
                $t("new_password")
              }}</label>
              <input
                type="password"
                id="newPassword"
                class="form-control"
                v-model="user.new_password"
              />
            </div>
            <div class="form-group">
              <label for="confirmPassword" class="text-bg-contrast">{{
                $t("confirm_password")
              }}</label>
              <input
                type="password"
                id="confirmPassword"
                class="form-control"
                v-model="user.confirm_password"
              />
            </div>
            <div class="form-group">
              <button type="submit" class="btn btn-primary text-primary-contrast">
                {{ $t("save") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ChangePassword",
  data() {
    return {
      user: {
        password: "",
        new_password: "",
        confirm_password: "",
      },
    };
  },
  methods: {
    submitForm() {
      if (this.user.new_password != this.user.confirm_password) {
        this.$store.dispatch("showAlert", {
          message: this.$t("passwords_not_match"),
          type: "error",
          timeout: 5000,
        });
        return;
      } else {
        axios
          .put("/api/users/me/change_password/", {
            old_password: this.user.password,
            new_password: this.user.new_password,
          })
          .then(() => {
            this.$store.dispatch("showAlert", {
              message: this.$t("password_changed_success"),
              type: "success",
              timeout: 5000,
            });
            this.$router.push("/profile");
          })
          .catch((error) => {
            if (error.response.data.old_password) {
              this.$store.dispatch("showAlert", {
                message: this.$t("old_password_incorrect"),
                type: "error",
                timeout: 5000,
              });
            } else {
              this.$store.dispatch("showAlert", {
                message: this.$t("password_changed_error"),
                type: "error",
                timeout: 5000,
              });
              console.log(error);
            }
          });
      }
    },
    checkIsPassChanged() {
      const isPassChanged = this.$store.state.user.init_pass_changed;
      if (!isPassChanged) {
        this.$store.dispatch("showAlert", {
          message: this.$t("please_change_default_password"),
          type: "warning",
          timeout: 10000,
        });
      }
    },
  },
  mounted() {
    this.checkIsPassChanged();
  },
};
</script>

<style scoped></style>
