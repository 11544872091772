<template>
  <div class="container-fluid">
    <div class="mt-n5 page-header min-height-200 border-radius-xl" :style="{
      backgroundImage:
        'url(' + require('@/assets/img/leomax-header-2.png') + ')',
      backgroundPositionY: '25%',
    }"></div>
    <div class="mb-n4 mx-4 overflow-hidden card card-body blur shadow-blur mt-n4">
      <div class="row gx-4">
        <div class="col-auto">
          <h6 class="mb-1">{{ $t("championship") }} {{ this.championship.name }} ({{ this.championship.abbreviation }})</h6>
          <p class="text-sm mb-1">
            <strong class="text-dark">{{ this.championship.type }}</strong>
          </p>
        </div>
        <div class="col text-end">
          <h6 class="mb-1">{{ $t("year") }}: {{ this.championship.year }}</h6>
        </div>
      </div>
    </div>
  </div>

  <div class="py-4 container-fluid">
    <div class="mt-4 row">

      <!-- TODO: move this element to the App.vue -->
      <div v-if="this.$store.state.isLoading" class="col-12">
        <div class="d-flex justify-content-center">
          <div class="spinner-border text-primary spinner-top" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      <div class="col-12">
        <championship-info-card :title="$t('championship_information')" :info="championship" :key="championship" :action="{
          route: 'javascript:;',
          tooltip: $t('edit_championship'),
        }" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useStore } from "vuex";
import ChampionshipInfoCard from "./components/ChampionshipInfoCard.vue";

export default {
  name: "SingleChampionshipEvent",
  components: {
    ChampionshipInfoCard,
  },

  data() {
    return {
      championship: {},
    };
  },
  setup() {
    return {
      getters: useStore().getters,
    };
  },
  methods: {
    goTo(link) {
      this.$router.push(link);
    },

    async getChampionshipDetails() {
      await axios
        .get(`/api/v1/championship/${this.$route.params.id}/`)
        .then((response) => {
          this.championship = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

  },

  mounted() {
    this.getChampionshipDetails();
    this.$store.dispatch("changeIsAbsolute", true);
  },
  beforeUnmount() {
    this.$store.dispatch("changeIsAbsolute", false)
  },
};
</script>
<style scoped>
.page-header {
  z-index: -100;
}
</style>
