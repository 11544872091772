<template>
  <soft-alert
    icon="ni ni-like-2 ni-lg"
    dismissible
    :hidden="this.hideAlert"
    class="alert-success"
  >
    <strong>{{ $t("success") }}</strong> {{ $t("event_added_success") }}
  </soft-alert>

  <div class="container-fluid">
    <div
      class="mt-3 page-header min-height-200 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/events_header.png') + ')',
        backgroundPositionY: '25%',
      }"
    ></div>
    <div
      class="mb-n4 mx-4 overflow-hidden card card-body blur shadow-blur mt-n4"
    >
      <h6 class="mb-1">{{ $t("add_event") }}</h6>
    </div>
  </div>

  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="mb-4 card">
          <div class="p-4 mb-n5 card-header">
            <button
              class="btn btn-sm btn-outline-primary"
              @click="$router.push('/events')"
            >
              <i class="fa fa-arrow-left"></i>
            </button>
          </div>
          <div class="card-body text-bg-contrast">
            <div class="row">
              <div class="div col-md-3">
                <label class="text-sm text-bg-contrast">
                  {{ $t("event_name") }}
                </label>
                <input
                  name="name"
                  class="form-control"
                  type="text"
                  v-model="event.name"
                  placeholder="Name"
                  aria-disabled
                />
              </div>
              <div class="div col-md-3">
                <label class="text-sm text-bg-contrast">
                  {{ $t("location") }}
                </label>

                <select
                  name="location"
                  class="form-control"
                  aria-label="Location"
                  v-model="event.location"
                >
                  <option
                    v-for="track in locations"
                    :value="track.name"
                    :key="track"
                  >
                    {{ track.name
                    }}<span class="text-danger"> ({{ track.country }})</span>
                  </option>
                </select>
              </div>
              <div class="div col-md-3">
                <label class="text-sm text-bg-contrast">
                  {{ $t("start_date") }}
                </label>
                <input
                  name="start_date"
                  class="form-control"
                  type="date"
                  v-model="event.start_date"
                  placeholder="01.01.1970"
                  aria-disabled
                />
              </div>
              <div class="div col-md-3">
                <label class="text-sm text-bg-contrast">
                  {{ $t("end_date") }}
                </label>
                <input
                  name="end_date"
                  class="form-control"
                  type="date"
                  v-model="event.end_date"
                  placeholder="01.01.1970"
                  aria-disabled
                />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 pt-4 mb-n3">
                <button
                  class="btn btn-sm btn-outline-success right"
                  @click="addEvent"
                >
                  {{ $t("add") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import SoftAlert from "@/components/SoftAlert.vue";

export default {
  components: {
    SoftAlert,
  },
  data() {
    return {
      event: {
        name: "",
        location: "",
        start_date: "",
        end_date: "",
      },
      locations: this.getters.getLocations,
      hideAlert: true,
    };
  },

  setup() {
    return {
      getters: useStore().getters,
    };
  },

  methods: {
    addEvent() {
      this.$store.dispatch("addEvent", this.event).then(
        () => {
          this.event = {
            name: "",
            location: "",
            start_date: "",
            end_date: "",
          };
          // redirect to events page
          this.$router.push("/events");
          this.hideAlert = false;
          setTimeout(() => {
            this.hideAlert = true;
          }, 3000);
        },
        (error) => {
          // Show error message below each input field
          for (const key in error.response.data) {
            const element = document.getElementsByName(key);
            element[0].classList.add("is-invalid");
            const errorMessage = document.createElement("div");
            errorMessage.classList.add("invalid-feedback");
            errorMessage.classList.add("text-danger");
            errorMessage.innerText = error.response.data[key];
            element[0].parentNode.appendChild(errorMessage);
          }
        }
      );
    },
  },
  created() {
    this.$store.dispatch("fetchLocations");
  },

  mounted() {
    this.$store.state.isAbsolute = true;
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
};
</script>

<style scoped></style>
