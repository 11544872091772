import axios from "axios";

const events = {

  actions: {
    fetchAllAvailableUsers(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/api/v1/events/${data.event_id}/available_users/`
          )
          .then((response) => {
            return resolve(response.data);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
    getEventJobs(context, event_id) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/api/v1/events/${event_id}/jobs/`
          )
          .then((response) => {
            return resolve(response.data);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
    getEventInfo(context, event_id) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/api/v1/events/${event_id}/`
          )
          .then((response) => {
            return resolve(response.data);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
    parseAssignedUsersFromEvent(context, event_id) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/api/v1/events/${event_id}/`
          )
          .then((response) => {
            let assignedUsers = [];
            if (response.data.jobs) {
              response.data.jobs.forEach((job) => {
                job.assigned_users.forEach((user) => {
                  user.job = job.job;
                  assignedUsers.push(user);
                });
              }
              );
            }
            if (response.data.championship_events) {
              response.data.championship_events.forEach((championship_event) => {
                if (championship_event.jobs) {
                  championship_event.jobs.forEach((job) => {
                    job.assigned_users.forEach((user) => {
                      user.job = job.job;
                      assignedUsers.push(user);
                    });
                  });
                }
              });
            }
            return resolve(assignedUsers);


          })
          .catch((error) => {
            return reject(error);
          });
      });
    },

  },

};

export default events;
