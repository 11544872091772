<template>
  <select class="customSelect text-center" v-model="$i18n.locale">
    <option
      v-for="locale in $i18n.availableLocales"
      :key="`locale-${locale}`"
      :value="locale"
    >
      {{ locale.toUpperCase() }}
    </option>
  </select>
</template>

<script>
export default {
  name: "LocaleSwitcher",
  data() {
    return {};
  },
};
</script>
<style scoped>
.customSelect {
  margin-right: 1rem;
  width: 2rem;
  background-color: transparent;
  color: white;
  border-radius: 5px;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.customSelect:focus {
  outline: none;
}

select option {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

select:hover {
  background: #c07d00;
}
</style>
