<template>
  <div
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
    id="sidenav-collapse-main"
  >
    <span>
      <ul class="navbar-nav">
        <li v-for="item in navItems" :key="item.title" class="nav-item">
          <sidenav-collapse :navText="item.title" :to="item.to">
            <template #icon>
              <font-awesome-icon :icon="item.icon" />
            </template>
          </sidenav-collapse>
        </li>
      </ul>
    </span>
  </div>
</template>
<script>
/* eslint-disable */
import SidenavCollapse from "./SidenavCollapse.vue";

export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  components: {
    SidenavCollapse,
  },
  data() {
    return {
      navItems: [],
      navItemsAdmin: [
        {
          title: "dashboard",
          to: { name: "dashboard" },
          icon: "fa-solid fa-gauge",
        },
        {
          title: "hotels",
          to: { name: "hotels" },
          icon: "fa-solid fa-bed",
        },
        {
          title: "events",
          to: { name: "events" },
          icon: "fa-solid fa-car-side",
        },
        {
          title: "calendar",
          to: { name: "calendar" },
          icon: "fa-solid fa-calendar",
        },
        {
          title: "championship_events",
          to: { name: "championship_events" },
          icon: "fa-solid fa-flag-checkered",
        },
        {
          title: "users",
          to: { name: "users" },
          icon: "fa-solid fa-user",
        },
        {
          title: "availabilities",
          to: { name: "availabilities" },
          icon: "fa-solid fa-calendar-check",
        },
        {
          title: "all_expenses",
          to: { name: "all_expenses" },
          icon: "fa-solid fa-coins",
        },
        {
          title: "my_travel_expenses",
          to: { name: "travel_expenses" },
          icon: "fa-solid fa-euro-sign",
        },
        {
          title: "admin_panel",
          to: "/admin",
          icon: "fa-solid fa-screwdriver-wrench",
        },
      ],
      navItemsUser: [
        {
          title: "dashboard",
          to: { name: "dashboard" },
          icon: "fa-solid fa-gauge",
        },
        {
          title: "my_profile",
          to: { name: "profile" },
          icon: "fa-solid fa-user",
        },
        {
          title: "travel_expenses",
          to: { name: "travel_expenses" },
          icon: "fa-solid fa-euro-sign",
        },
      ],

      title: "Leomax Dashboard",
      controls: "dashboardsExamples",
      isActive: "active",
    };
  },

  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },

  mounted() {
    // check if user is logged in and is_superuser
    // if (localStorage.getItem("is_superuser") === "true") {
    //   this.navItems = this.navItemsAdmin;
    // } else {
    //   this.navItems = this.navItemsUser;
    // }
    if (this.$store.state.user.is_superuser) {
      this.navItems = this.navItemsAdmin;
    } else {
      this.navItems = this.navItemsUser;
    }
  },
};
</script>
<style scoped></style>
