// const XLSX = require('sheetjs-style');


const helpers = {

  actions: {
    // exportToExcel(context, document) {
    //     const tables = document.querySelectorAll("table"); // select all tables in the component
    //     // add all tables to the workbook vertically stacked
    //     const wb = XLSX.utils.book_new();
    //     const ws = XLSX.utils.aoa_to_sheet([]);
    //     tables.forEach((table) => {
    //         XLSX.utils.sheet_add_aoa(ws, table, { origin: -1 });

    //     });

    //     XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    //     let wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    //     const blob = new Blob([wbout], { type: "application/octet-stream" });
    //     const link = document.createElement("a");
    //     link.href = window.URL.createObjectURL(blob);
    //     link.download = "export.xlsx";
    //     link.click();
    //     URL.revokeObjectURL(link.href);
    //   },
  },
};

export default helpers;
