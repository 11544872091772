<template>
  <AlertDefault @event="closeModal" :visible="visible">
    <template v-slot:title>
      <h5 class="modal-title text-white">{{ $t(title_text) }}</h5>
    </template>

    <template v-slot:body>
      <div class="container">
        <div class="row">
          <div class="col-6">
            <p class="text-white">{{ $t("select_job") }}</p>
          </div>
          <div class="col-6">
            <select class="form-select" v-model="jobToSave.job">
              <option v-for="job in this.jobsList" :key="job.name" :value="job.name">
                {{ job.name }} - ({{ job.category }})
              </option>
            </select>
          </div>
        </div>



        <div class="row">
          <div class="col-6">
            <p class="text-white">
              {{ $t("members") }}
            </p>
          </div>
          <div class="col-6">
            <input type="number" class="form-control" v-model="jobToSave.members" min="1" max="50" />
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <slot name="secondBtn"></slot>
      <button type="button" @click="saveItem" :class="'btn btn-outline-success'">
        {{ $t("save") }}
      </button>
    </template>
  </AlertDefault>
</template>

<script lang="js">
import AlertDefault from "./AlertDefault.vue";
export default {
  name: "AddJobModal",

  data() {
    return {
      jobsList: [],
      jobToSave: {
        job: "",
        members: 1,
      },
    };
  },

  methods: {
    closeModal() {
      this.$parent.editMode = false;
      this.$parent.ShowModal
        ? (this.$parent.ShowModal = false)
        : (this.$parent.ShowModal = true);
    },
    getAllJobs() {
      this.$store.dispatch("fetchJobs")
        .then((resp) => {
          this.jobsList = resp.results;
        })
        .catch((error) => {
          console.log(error);
        })
    },

    saveItem() {
      this.$emit("save", [this.jobToSave]);
      this.$parent.ShowModal = false;
    },
  },
  created() {
    this.getAllJobs();
  },
  components: {
    AlertDefault,
  },
  props: {
    visible: Boolean,
    title_text: String,
  },
};
</script>
<style scoped>
</style>
