<template>
  <div v-if="this.addRoomIsOpened" class="card mb-3">
    <div class="card-body">
      <div class="row flex-column-reverse flex-md-row">
        <div class="col">
          <ul class="list-group">
            <SelectInput
              label="hotel"
              bindParent="selectedHotel"
              :items="hotels.map((hotel) => hotel.name)"
              :useEmit="true"
              @changeVal="selectHotel"
              valueObj="name"
              displayObj="name"
            />

            <li class="text-sm border-0 list-group-item ps-0">
              <div class="row">
                <div class="col-5">
                  <strong class="text-bg-contrast fw-normal"
                    >{{ $t("event") }}:</strong
                  >
                </div>
                <div class="col-7 text-bg-contrast p-0 fw-bold">
                  <span
                    v-if="events.length === 0 && selectedChampionshipEventtrack !== ''"
                    >{{ $t("no_events_for_this_hotel") }}</span
                  >
                  <span v-else>
                    <select
                      v-if="selectedChampionshipEventtrack !== ''"
                      class="minimalistic-input"
                      v-model="selectedEvent"
                      @change="selectEvent()"
                    >
                      <option
                        v-for="event in events"
                        :key="event.name"
                        :value="event"
                      >
                        {{ event }}
                      </option>
                    </select>
                    <span v-else>{{ $t("select_hotel_first") }}</span>
                  </span>
                </div>
              </div>
            </li>

            <SelectInput
              label="room_type"
              :editable="this.editable"
              :placeholder="newObj.room_type"
              bindObj="room_type"
              :items="room_types"
            />
          </ul>
        </div>
        <div class="col">
          <ul class="list-group">
            <CustomInput
              label="comment"
              :editable="this.editable"
              :placeholder="newObj.comment"
              bindObj="comment"
            />
            <CustomInput
              type="date"
              label="check_in"
              :editable="this.editable"
              :placeholder="newObj.check_in"
              :date_min="
                selectedEvent.start_date ? selectedEvent.start_date : ''
              "
              :date_max="selectedEvent.end_date ? selectedEvent.end_date : ''"
              bindObj="check_in"
            />
            <CustomInput
              type="date"
              label="check_out"
              :editable="this.editable"
              :placeholder="newObj.check_out"
              :date_min="
                selectedEvent.start_date ? selectedEvent.start_date : ''
              "
              :date_max="selectedEvent.end_date ? selectedEvent.end_date : ''"
              bindObj="check_out"
            />
          </ul>
        </div>
      </div>
      <div class="row m-0 p-0">
        <div class="col-md-12 text-end">
          <IconBtn
            title="save"
            icon="fas fa-2x fa-save text-success"
            @click="addNewObj()"
          />
          <IconBtn
            title="cancel"
            icon="fas fa-2x fa-times-circle text-danger"
            @click="cancelAddRoom()"
          />
        </div>
      </div>
    </div>
  </div>

  <TableCard
    :tableItems="this.filteredRooms"
    :headers="this.headers"
    searchBy="hotel"
    filterPlaceholder="search_by_hotel"
  >
    <template #cardHeader>
      <div class="row">
        <div class="col-5">
          <FilterSelection
            :items="Object.keys(this.statuses)"
            @filter="this.filterRooms"
            label="status"
          />
          <FilterSelection
            :items="this.events"
            :translateOptions="false"
            @filter="this.filterRoomsByEvents"
            label="event"
          />
        </div>
        <div class="col-7 text-end mb-2">
          <button
            class="btn btn-sm btn-primary text-primary-contrast"
            @click="openAddRoomCard()"
          >
            {{ $t("add_room") }}
          </button>
        </div>
      </div>
    </template>

    <template #tableBody="slotItem">
      <td
        v-for="item in this.headers.filter((item) => item !== 'status')"
        :key="item"
        class="text-center"
      >
        <p
          :class="{
            'text-xs font-weight-thin mb-0': item !== 'event',
            'text-sm font-weight-thin mb-0': item === 'event',
          }"
        >
          {{ slotItem.item[item] }}
        </p>
      </td>

      <td class="text-center">
        <span
          style="width: 7rem"
          :class="{
            'text-dark badge badge-sm bg-success': slotItem.item.is_free,
            'text-white badge badge-sm bg-danger':
              slotItem.item.is_fully_occupied,
            'text-dark badge badge-sm bg-warning':
              slotItem.item.is_partly_occupied,
          }"
        >
          <span v-if="slotItem.item.is_free">{{ $t("free") }}</span>
          <span v-if="slotItem.item.is_fully_occupied">{{
            $t("fully_occupied")
          }}</span>
          <span v-if="slotItem.item.is_partly_occupied">{{
            $t("partly_occupied")
          }}</span>
        </span>
      </td>

      <td class="text-center">
        <IconBtn
          title="info"
          icon="fas fa-info text-white"
          @click="this.$router.push('/hotels/room/' + slotItem.item.id)"
        />
      </td>
    </template>
  </TableCard>
</template>

<script>
import CustomInput from "@/components/inputs/CustomInput.vue";
import SelectInput from "@/components/inputs/SelectInput.vue";
import FilterSelection from "@/components/inputs/FilterSelection.vue";
import IconBtn from "@/components/buttons/IconBtn.vue";
import TableCard from "@/components/TableCard.vue";

import axios from "axios";

export default {
  name: "RoomsList",
  components: {
    CustomInput,
    FilterSelection,
    SelectInput,
    IconBtn,
    TableCard,
  },
  data() {
    return {
      statuses: {
        free: "is_free",
        fully_occupied: "is_fully_occupied",
        partly_occupied: "is_partly_occupied",
      },
      rooms: [],
      filteredRooms: [],
      roomFilter: "all",
      eventFilter: "all",
      addRoomIsOpened: false,
      newObj: {},
      editable: false,
      hotels: [],
      selectedHotel: {},
      selectedEvent: {},
      selectedChampionshipEventtrack: "",
      events: [],
      room_types: ["single", "double", "triple", "quad"],
      headers: [
        "hotel",
        "event",
        "room_type",
        "check_in",
        "check_out",
        "status",
      ],
    };
  },
  methods: {
    async getHotels() {
      await axios
        .get(`api/v1/hotels/?page_size=150&fields=id,name,championship_eventtrack`)
        .then((res) => {
          this.hotels = res.data.results;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getEvents() {
      const events = this.rooms.map((room) => room.event);
      const uniqueEvents = [...new Set(events)];
      this.events = uniqueEvents;
    },

    async getRooms() {
      await axios
        .get(
          "/api/v1/hotels/rooms/?page_size=150&fields=id,event,hotel,room_type,room_number,check_in,check_out,is_fully_occupied,is_partly_occupied,is_free"
        )
        .then((response) => {
          this.rooms = response.data.results;
          this.rooms.forEach((room) => {
            room.check_in = this.$store.getters.formatDate(room.check_in);
            room.check_out = this.$store.getters.formatDate(room.check_out);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    filterRooms(value) {
      this.roomFilter = value;
      if (value === "all" && this.eventFilter === "all") {
        this.filteredRooms = this.rooms;
      } else if (value === "all" && this.eventFilter !== "all") {
        this.filteredRooms = this.rooms.filter(
          (room) => room.event === this.eventFilter
        );
      } else if (value !== "all" && this.eventFilter === "all") {
        this.filteredRooms = this.rooms.filter(
          (room) => room[this.statuses[value]]
        );
      } else {
        this.filteredRooms = this.rooms.filter(
          (room) =>
            room[this.statuses[value]] && room.event === this.eventFilter
        );
      }
    },

    filterRoomsByEvents(value) {
      this.eventFilter = value;
      this.filterRooms(this.roomFilter);
    },

    selectHotel(hotel) {
      hotel = this.hotels.find((el) => el.name === hotel);
      this.selectedEvent = {};
      this.newObj.hotel = hotel.id;
      this.selectedChampionshipEventtrack = hotel.championship_eventtrack;
      this.getEvents();
    },

    selectEvent() {
      this.newObj.event = this.selectedEvent;
    },

    openAddRoomCard() {
      this.getHotels();
      this.addRoomIsOpened = true;
      this.editable = true;
    },
    cancelAddRoom() {
      this.addRoomIsOpened = false;
      this.selectedHotel = {};
      this.selectedEvent = {};
      this.selectedChampionshipEventtrack = "";
      this.events = [];
      this.editable = false;
    },

    async addNewObj() {
      await axios
        .post("/api/v1/hotels/rooms/", this.newObj)
        .then(() => {
          this.getRooms().then(() => {
            this.filterRooms("all");
          });
          this.addRoomIsOpened = false;
          this.editable = false;
          this.$store.dispatch("showAlert", {
            type: "success",
            message: this.$t("room_added"),
            timeout: 3000,
          });
        })
        .catch((err) => {
          this.$store.dispatch("showAlert", {
            type: "error",
            message: err.response.data,
            timeout: 5000,
          });
        });
    },
  },
  created() {
    this.getRooms().then(() => {
      this.getEvents();
      this.filterRooms("all");
    });
  },
};
</script>

<style scoped>
.card {
  max-height: 75vh;
  overflow-y: hidden;
  overflow-x: auto;
}

.minimalistic-input {
  min-width: 100%;
}
</style>
