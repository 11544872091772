import { createStore } from "vuex";
import { createLogger } from "vuex";
import { ref } from "vue";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import axios from "axios";
import availabilities from "./api/availabilities";
import travelExpenses from "./api/travelExpenses";
import championship from "./api/championship";
import jobs from "./api/jobs";
import userStore from "./api/user";
import events from "./api/events";
import helpers from "./helpers";


const debug = process.env.NODE_ENV !== "production";

export default createStore({
  modules: {
    availabilities: availabilities,
    travelExpenses: travelExpenses,
    jobs: jobs,
    championship: championship,
    events: events,
    helpers: helpers,
    userStore: userStore,
  },
  // strict: debug,
  plugins: debug ? [createLogger()] : [],
  state: {
    eventsShort: {},
    eventsFuture: {},
    eventsPast: {},
    hotels: [],
    locations: [],
    isLoading: false,
    isAuthenticated: false,
    token: "",
    user: {},
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    isTransparent: "",
    isRTL: false,
    color: "card-background-mask bg-gradient-warning",
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    navbarFixed:
      "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
    bootstrap,
    users: [],
    usersShort: [],
    countries: require("./countries.json"),
    successAlert: { hidden: true, message: "" },
    errorAlert: { hidden: true, message: "" },
    warningAlert: { hidden: true, message: "" },
    deleteModal: { hidden: true, message: "" },
    confirmModal: { hidden: true, message: "" },
    promptModal: { hidden: true, message: "", inputString: "" },

    eventRooms: {},
  },
  mutations: {
    updateSuccessAlert(state, payload) {
      state.successAlert = { ...state.successAlert, ...payload };
    },
    updateErrorAlert(state, payload) {
      state.errorAlert = { ...state.errorAlert, ...payload };
    },
    updateWarningAlert(state, payload) {
      state.warningAlert = { ...state.warningAlert, ...payload };
    },
    setIsTransparent(state, isTransparent) {
      state.isTransparent = isTransparent;
    },
    // showAlert(state, params) {
    //   if (params.type === "success") {
    //     state.successAlert.hidden = false;
    //     state.successAlert.message = params.message;
    //     setTimeout(() => {
    //       state.successAlert.hidden = true;
    //     }, params.timeout);
    //   }
    //   if (params.type === "error") {
    //     state.errorAlert.hidden = false;
    //     state.errorAlert.message = params.message;
    //     setTimeout(() => {
    //       state.errorAlert.hidden = true;
    //     }, params.timeout);
    //   }
    //   if (params.type === "warning") {
    //     state.warningAlert.hidden = false;
    //     state.warningAlert.message = params.message;
    //     setTimeout(() => {
    //       state.warningAlert.hidden = true;
    //     }, params.timeout);
    //   }
    // },
    showModal(state, params) {
      if (params.type === "delete") {
        state.deleteModal.hidden = false;
        state.deleteModal.message = params.message;
      }
      if (params.type === "confirm") {
        state.confirmModal.hidden = false;
        state.confirmModal.message = params.message;
      }
      if (params.type === "prompt") {
        state.promptModal.hidden = false;
        state.promptModal.message = params.message;
        state.promptModal.input = params.inputString;
      }
    },
    hideModal(state) {
      state.deleteModal.hidden = true;
      state.confirmModal.hidden = true;
      state.promptModal.hidden = true;
    },

    // set event room in dictionary with key = event id
    setEventRooms(state, eventRooms) {
      if (eventRooms.length === 0) {
        return;
      }
      const eventId = eventRooms[0].eventId;
      state.eventRooms[eventId] = eventRooms;
    },

    setIsAuthenticated(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },

    setHotels(state, hotels) {
      state.hotels = hotels;
    },

    setLocations(state, locations) {
      state.locations = locations;
    },

    setUsers(state, users) {
      state.users = users;
    },

    setUsersShort(state, usersShort) {
      state.usersShort = usersShort;
    },

    setEventsShort(state, events) {
      state.eventsShort = events;
    },

    removeSelectedEvent(state, event) {
      state.eventsShort = state.eventsShort.filter((e) => e.id !== event.id);
      if (state.eventsFull) {
        state.eventsFull = state.eventsFull.filter((e) => e.id !== event.id);
      }
    },

    removeSelectedHotel(state, hotel) {
      state.hotels = state.hotels.filter((h) => h.id !== hotel.id);
    },

    updateEvent(state, event) {
      console.log(state.eventsShort);

      const eventIndex = state.eventsFull.findIndex(
        (event) => event.id == event.id
      );

      console.log(eventIndex);
      console.log(state.eventsShort);

      state.eventsShort[eventIndex].name = event.name;
      state.eventsShort[eventIndex].location = event.location;
      state.eventsShort[eventIndex].start_date = event.start_date;
      state.eventsShort[eventIndex].end_date = event.end_date;

      state.eventsFull[eventIndex].name = event.name;
      state.eventsFull[eventIndex].location = event.location;
      state.eventsFull[eventIndex].start_date = event.start_date;
      state.eventsFull[eventIndex].end_date = event.end_date;
    },

    setEventsFuture(state, events) {
      state.eventsFuture = events;
    },

    setEventsPast(state, events) {
      state.eventsPast = events;
    },

    setIsLoading(state, status) {
      state.isLoading = status;
    },
    setToken(state, token) {
      state.token = token;
      state.isAuthenticated = true;
    },
    removeToken(state) {
      state.token = "";
      state.isAuthenticated = false;
    },
    setUser(state, user) {
      state.user = user;
    },

    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const windowWidth = ref(window.innerWidth).value;

      const sidenav_show = document.querySelector(".g-sidenav-show");
      if (!(windowWidth > 991)) {
        if (sidenav_show.classList.contains("g-sidenav-hidden")) {
          sidenav_show.classList.remove("g-sidenav-hidden");
          sidenav_show.classList.add("g-sidenav-pinned");
          state.isPinned = true;
        } else {
          sidenav_show.classList.add("g-sidenav-hidden");
          sidenav_show.classList.remove("g-sidenav-pinned");
          state.isPinned = false;
        }
      }
    },
    sidebarType(state, payload) {
      state.isTransparent = payload;
    },
    cardBackground(state) {
      state.color = "primary";
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    toggleEveryDisplay(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    closeSidenav(state) {
      state.showSidenav = false;
    },
    openSidenav(state) {
      state.showSidenav = true;
    },
    hideEveryDisplay(state) {
      state.showNavbar = false;
      state.showSidenav = false;
      state.showFooter = false;
      state.showMain = false;
      state.showConfig = false;
      state.showNavs = false;
    },
    toggleHideConfig(state) {
      state.hideConfigButton = !state.hideConfigButton;
    },
    addHotelToList(state, hotel) {
      state.hotels.unshift(hotel);
    },
    isAbsolute(state, payload) {
      state.isAbsolute = payload;
    }
  },
  actions: {
    showAlert({ commit }, params) {
      if (params.type === "success") {
        commit("updateSuccessAlert", {
          hidden: false,
          message: params.message,
        });
        setTimeout(() => {
          commit("updateSuccessAlert", { hidden: true });
        }, params.timeout);
      }
      if (params.type === "error") {
        commit("updateErrorAlert", { hidden: false, message: params.message });
        setTimeout(() => {
          commit("updateErrorAlert", { hidden: true });
        }, params.timeout);
      }
      if (params.type === "warning") {
        commit("updateWarningAlert", {
          hidden: false,
          message: params.message,
        });
        setTimeout(() => {
          commit("updateWarningAlert", { hidden: true });
        }, params.timeout);
      }
    },

    async getMe(context) {
      await axios.get("/api/users/me/").then((response) => {
        context.commit("setUser", response.data);
      });
    },

    async getUsersShortByPage(context, page) {
      context.commit("setIsLoading", true);
      await axios
        .get(
          `/api/v1/users/?fields=id,first_name,full_name,profile_image,email,last_name,company,country,is_superuser,username,last_login,is_active&page=${page}`
        )
        .then((response) => {
          context.commit("setUsers", response.data.results);
          context.commit("setIsLoading", false);
        });
    },

    async getUsersFromAllPages(context) {
      let users = [];
      let page = 1;
      let hasMore = true;
      do {
        await axios
          .get(
            `/api/v1/users/?fields=id,first_name,full_name,profile_image,email,last_name,company,country,is_superuser,username,last_login,is_active&page=${page}`
          )
          .then((response) => {
            if (!response.data.next) {
              hasMore = false;
            }
            page++;
            users = [...users, ...response.data.results];
          });
      } while (hasMore);

      context.commit("setUsers", users);
    },

    async getUsersFromAllPagesShort(context) {
      let users = [];
      let page = 1;
      let hasMore = true;
      do {
        await axios
          .get(
            `/api/v1/users/?fields=id,first_name,full_name,email,last_name&page=${page}`
          )
          .then((response) => {
            if (!response.data.next) {
              hasMore = false;
            }
            page++;
            users = [...users, ...response.data.results];
          });
      } while (hasMore);

      context.commit("setUsersShort", users);
    },

    async getHotelsShort(context) {
      context.commit("setIsLoading", true);
      await axios
        .get("/api/v1/hotels/?page_size=150&fields=id,name,location,country")
        .then((response) => {
          context.commit("setHotels", response.data.results);
          context.commit("setIsLoading", false);
        });
    },

    async deleteEvent(context, event) {
      context.commit("setIsLoading", true);
      await axios.delete(`/api/v1/events/${event.id}/`).then(() => {
        context.commit("setIsLoading", false);
        context.commit("removeSelectedEvent", event);
      });
    },

    async deleteHotel(context, hotel) {
      context.commit("setIsLoading", true);
      await axios.delete(`/api/v1/hotels/${hotel.id}/`).then(() => {
        context.commit("setIsLoading", false);
        context.commit("removeSelectedHotel", hotel);
      });
    },

    async addEvent(context, event) {
      context.commit("setIsLoading", true);
      await axios.post(`/api/v1/events/`, event).then(() => {
        context.commit("setIsLoading", false);
      });
    },

    async addHotel(context, hotel) {
      context.commit("setIsLoading", true);
      await axios.post(`/api/v1/hotels/`, hotel).then((resp) => {
        context.commit("setIsLoading", false);

        context.commit("addHotelToList", resp.data);
      });
    },

    async addUser(context, user) {
      context.commit("setIsLoading", true);
      await axios.post(`/api/users/`, user).then((resp) => {
        context.commit("setIsLoading", false);
        return resp.data;
      });
    },

    async updateUser(context, id, user) {
      context.commit("setIsLoading", true);
      await axios.patch(`/api/v1/users/${id}/`, user).then(() => {
        context.commit("setIsLoading", false);
      });
    },

    async fetchLocations(context) {
      context.commit("setIsLoading", true);
      await axios
        .get(`/api/v1/locations/?fields=name,country,id&page_size=150`)
        .then((response) => {
          context.commit("setLocations", response.data.results);
          context.commit("setIsLoading", false);
        });
    },

    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },
    setCardBackground({ commit }, payload) {
      commit("cardBackground", payload);
    },
    changeIsAbsolute({ commit }, payload) {
      commit("isAbsolute", payload);
    }
  },
  getters: {
    isSuperuser: (state) => {
      return state.user.is_superuser;
    },
    isAuthenticated: (state) => {
      return state.isAuthenticated;
    },
    getCurrentUser: (state) => state.user,
    getHotels: (state) => state.hotels,
    getEventsShort: (state) => state.eventsShort,
    getEventsFuture: (state) => state.eventsFuture,
    getEventsPast: (state) => state.eventsPast,
    getLocations: (state) => state.locations,
    getUsers: (state) => state.users,
    getUsersShort: (state) => state.usersShort,
    getCountries: (state) => state.countries,
    formatDate: () => (date) => {
      return new Date(date).toLocaleDateString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
  },
});
