<template>
  <div class="card h-200">
    <div class="p-3 pb-0 card-header">
      <div class="row">
        <div class="col-md-6 d-flex align-items-center">
          <h6 class="mb-0 text-bg-contrast">{{ this.title }}</h6>
        </div>
        <div class="col-md-6 d-flex justify-content-end">
          <div class="btn-group">
            <router-link v-if="bookedRooms" class="mb-0 btn btn-sm btn-primary text-dark text-bg-contrast" :to="{
              name: 'Hotels Report',
              params: {
                eventName: this.$parent.event.name,
                eventStartDate: this.$parent.event.start_date,
                eventEndDate: this.$parent.event.end_date,
              },
            }">
              {{ $t("export_pdf") }}
            </router-link>

            <button class="mb-0 btn btn-sm btn-primary text-primary-contrast" @click="addNewRoom()">
              {{ $t("add_booking") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="p-3 card-body">
      <div class="row flex-column-reverse flex-md-row flex-sm-row">
        <div class="container">
          <div class="table-responsive">
            <table v-if="Array.isArray(bookedRooms)" class="table align-items-center mb-0">
              <thead>
                <tr>
                  <th></th>
                  <th v-for="header in this.headers" :key="header"
                    class="text-uppercase align-text-top text-center text-primary text-xxs font-weight-bolder opacity-7 p-1">
                    <button v-if="header !== 'status'"
                      class="invisible-btn text-xs text-uppercase fw-normal text-primary"
                      @click="sortTable(header, true)">
                      {{ $t(header) }}
                      <span v-if="this.sortColumn === header">
                        <i v-if="this.sortDirection === 'asc'" class="fas fa-sort-down"></i>
                        <i v-else class="fas fa-sort-up"></i>
                      </span>
                    </button>
                    <span class="text-xs fw-normal text-uppercase" v-else>{{
                      $t(header)
                    }}</span>

                    <div v-if="header === 'hotel'">
                      <select class="minimalistic-input" v-model="selectedHotel">
                        <option value="all">{{ $t("all") }}</option>
                        <option v-for="hotel in getRoomsHotels()" :key="hotel" :value="hotel">
                          {{ hotel }}
                        </option>
                      </select>
                    </div>
                    <div v-if="header === 'room_type'">
                      <select class="minimalistic-input" v-model="selectedType">
                        <option value="all">{{ $t("all") }}</option>
                        <option v-for="roomType in roomTypes" :key="roomType" :value="roomType">
                          {{ roomType }}
                        </option>
                      </select>
                    </div>
                    <div v-if="header === 'status'">
                      <select class="minimalistic-input" v-model="selectedStatus">
                        <option value="all">{{ $t("all") }}</option>
                        <option v-for="status in [
                          'free',
                          'partly_occupied',
                          'fully_occupied',
                        ]" :key="status" :value="status">
                          {{ $t(status) }}
                        </option>
                      </select>
                    </div>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody class="text-center">
                <tr v-for="item in filterTable()" :key="item">
                  <td>
                    <button v-if="!item.notSaved" class="invisible-btn full-size"
                      @click="this.$router.push(`/hotels/room/${item.id}`)">
                      <p class="text-sm font-weight-thin mb-0">
                        {{ item.room_number }}
                      </p>
                    </button>
                    <span v-else>
                      <small>{{ $t("number_of_rooms") + ": " }}</small>
                      <input class="minimalistic-input w-25" type="number" min="1" v-model="roomsAmount" />
                    </span>
                  </td>
                  <td>
                    <p v-if="!item.notSaved" class="text-sm font-weight-thin mb-0">
                      {{ item.hotel }}
                    </p>

                    <select v-else class="minimalistic-input" v-model="item.hotel">
                      <option v-for="hotel in hotels" :key="hotel.id" :value="{ id: hotel.id, name: hotel.name }">
                        {{ hotel.name }}
                      </option>
                    </select>
                  </td>
                  <td>
                    <p v-if="!item.notSaved" class="text-xs font-weight-thin mb-0">
                      {{ item.room_type }}
                    </p>
                    <select v-else class="minimalistic-input" v-model="item.room_type">
                      <option v-for="roomType in roomTypes" :key="roomType" :value="roomType">
                        {{ roomType }}
                      </option>
                    </select>
                  </td>
                  <td>
                    <p v-if="!item.notSaved" class="text-xs font-weight-thin mb-0">
                      {{ item.comment }}
                    </p>
                    <input v-else class="minimalistic-input" type="text" v-model="item.comment" />
                  </td>
                  <td>
                    <p v-if="!item.notSaved" class="text-sm font-weight-thin mb-0">
                      {{ item.check_in.split("T")[0] }}
                    </p>
                    <input v-else class="minimalistic-input" type="date" :min="defaultCheckIn" :max="defaultCheckOut"
                      v-model="item.check_in" />
                  </td>
                  <td>
                    <p v-if="!item.notSaved" class="text-sm font-weight-thin mb-0">
                      {{ item.check_out.split("T")[0] }}
                    </p>
                    <input v-else class="minimalistic-input" type="date" :min="defaultCheckIn" :max="defaultCheckOut"
                      v-model="item.check_out" />
                  </td>
                  <td>
                    <span v-if="!item.notSaved">
                      <span v-if="item.is_free" class="text-dark badge badge-sm bg-success">
                        {{ $t("free") }}
                      </span>
                      <span v-if="item.is_fully_occupied" class="text-bg-contrast badge badge-sm bg-danger">
                        {{ $t("fully_occupied") }}
                      </span>
                      <span v-if="item.is_partly_occupied" class="text-dark badge badge-sm bg-warning">
                        {{ $t("partly_occupied") }}
                      </span>
                    </span>
                    <span v-else>
                      <button class="invisible-btn" @click="saveRoomBooking(item)" :title="$t('save')">
                        <i class="fas fa-save text-success"></i>
                      </button>
                      <button class="invisible-btn" @click="cancelRoomBooking(item)" :title="$t('cancel')">
                        <i class="fas fa-times-circle text-danger"></i>
                      </button>
                    </span>
                  </td>
                  <td>
                    <button v-if="!item.notSaved" class="invisible-btn"
                      @click="this.$router.push(`/hotels/room/${item.id}`)" :title="$t('info')">
                      <i class="fas fa-info text-bg-contrast"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-else class="text-center">
              <i class="fas fa-exclamation-triangle text-bg-contrast fa-3x"></i>
              <p class="mt-2 text-bg-contrast">
                {{ $t("event_has_no_rooms") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "EventHotelsCard",
  components: {},
  data() {
    return {
      headers: [

        "hotel",
        "room_type",
        "comment",
        "check_in",
        "check_out",
        "status",
      ],
      bookedRooms: {},
      unassignedChampionshipEvents: {},
      hotels: [],
      roomTypes: ["single", "double", "triple", "quad"],
      defaultCheckIn: "",
      defaultCheckOut: "",
      roomsAmount: 0,
      selectedHotel: "",
      selectedType: "",
      selectedStatus: "",
      sortColumn: "",
      sortDirection: "",
    };
  },

  methods: {
    reformatDate(date) {
      return new Date(date).toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
    },

    filterTable() {
      let filteredRooms = this.bookedRooms;
      // sort table

      if (this.selectedHotel !== "all") {
        filteredRooms = filteredRooms.filter(
          (room) => room.hotel === this.selectedHotel
        );
      }
      if (this.selectedType !== "all") {
        filteredRooms = filteredRooms.filter(
          (room) => room.room_type === this.selectedType
        );
      }
      if (this.selectedStatus !== "all") {
        if (this.selectedStatus === "free") {
          filteredRooms = filteredRooms.filter((room) => room.is_free);
        } else if (this.selectedStatus === "fully_occupied") {
          filteredRooms = filteredRooms.filter(
            (room) => room.is_fully_occupied
          );
        } else if (this.selectedStatus === "partly_occupied") {
          filteredRooms = filteredRooms.filter(
            (room) => room.is_partly_occupied
          );
        }
      }
      // save filter settings
      localStorage.setItem("EventRoomSelectedHotel", this.selectedHotel);
      localStorage.setItem("EventRoomSelectedType", this.selectedType);
      localStorage.setItem("EventRoomSelectedStatus", this.selectedStatus);
      return filteredRooms;
    },

    sortTable(header, change_direction) {
      if (change_direction) {
        if (this.sortColumn === header) {
          this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
        }
      }
      this.sortColumn = header;

      this.bookedRooms.sort((a, b) => {
        let modifier = 1;
        if (this.sortDirection === "desc") modifier = -1;
        if (this.sortColumn === "room_number") {
          return modifier * (a.room_number - b.room_number);
        }

        if (a[this.sortColumn] < b[this.sortColumn]) return -1 * modifier;
        if (a[this.sortColumn] > b[this.sortColumn]) return 1 * modifier;
        return 0;
      });
      localStorage.setItem("eventRoomSortColumn", header);
      localStorage.setItem("eventRoomSortDirection", this.sortDirection);
    },

    getDatesGaps() {
      // get date 2 days before event start date
      const checkIn = new Date(this.$parent.event.start_date);
      checkIn.setDate(checkIn.getDate() - 2);

      // get date 1 day after event end date
      const checkOut = new Date(this.$parent.event.end_date);
      checkOut.setDate(checkOut.getDate() + 1);

      this.defaultCheckIn = checkIn.toISOString().split("T")[0];
      this.defaultCheckOut = checkOut.toISOString().split("T")[0];
    },

    async getEventRooms() {
      await axios
        .get(
          `api/v1/hotels/rooms/?event=${this.$route.params.id}&page_size=500`
        )
        .then((res) => {
          if (res.data.count !== 0) {
            this.bookedRooms = res.data.results;
            this.getDatesGaps();
            this.bookedRooms.forEach((room) => {
              room.check_in = this.reformatDate(room.check_in);
              room.check_out = this.reformatDate(room.check_out);
            });

            this.$store.commit("setEventRooms", this.bookedRooms);

            this.sortTable(this.sortColumn, false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getHotels() {
      await axios
        .get(
          `api/v1/hotels/?location=${this.$parent.event.location}&fields=id,name`
        )
        .then((res) => {
          this.hotels = res.data.results;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async addNewRoom() {
      const newRoom = {
        event: this.$parent.event.name,
        hotel: "",
        room_number: "",
        room_type: "",
        comment: "",
        check_in: this.$parent.event.start_date,
        check_out: this.$parent.event.end_date,
        notSaved: true,
      };
      this.getHotels().then(() => {
        if (this.hotels.length > 0) {
          if (!this.bookedRooms) {
            this.bookedRooms = [newRoom]
          } else {
            this.bookedRooms.unshift(newRoom);
        }
        } else {
          this.$store.dispatch("showAlert", {
            type: "warning",
            message: this.$t("location_has_no_hotels"),
            timeout: 5000,
          });
        }
      });
    },

    getRoomsHotels() {
      if (this.bookedRooms.length > 0) {
        const hotels = this.bookedRooms.map((room) => room.hotel);
        return [...new Set(hotels)];
      }
      return [];
    },

    cancelRoomBooking(item) {
      this.bookedRooms = this.bookedRooms.filter((room) => room !== item);
    },

    async saveRoomBooking(item) {
      const hotelRooms = this.bookedRooms.filter(
        (room) => room.hotel === item.hotel.name
      );
      item.hotel = item.hotel.id;
      const hotelRoomsNumbers = hotelRooms.map((room) => room.room_number);
      let maxRoomNumber = Math.max(...hotelRoomsNumbers);
      if (maxRoomNumber === -Infinity) {
        maxRoomNumber = 0;
      }

      let rooms = [];

      for (let i = 1; i <= this.roomsAmount; i++) {
        let el = {
          event: item.event,
          hotel: item.hotel,
          room_number: maxRoomNumber + 1,
          room_type: item.room_type,
          comment: item.comment,
          check_in: item.check_in,
          check_out: item.check_out,
        };
        rooms = [...rooms, el];
        maxRoomNumber++;
      }
      for (let i = 0; i < rooms.length; i++) {
        await axios
          .post("api/v1/hotels/rooms/", rooms[i])
          .then(() => {
            this.bookedRooms = [...this.bookedRooms, rooms[i]];
          })
          .catch((err) => {
            console.log(err);
          });
      }
      this.roomsAmount = 0;
      this.getEventRooms();
    },
  },

  props: {
    title: {
      type: String,
      required: true,
    },
  },
  created() {
    this.getEventRooms();
  },
  mounted() {
    // get booked rooms from store state by key == event id
    this.bookedRooms = this.$store.state.eventRooms[this.$route.params.id];
    this.sortColumn = localStorage.getItem("eventRoomSortColumn")
      ? localStorage.getItem("eventRoomSortColumn")
      : "room_number";
    this.sortDirection = localStorage.getItem("eventRoomSortDirection")
      ? localStorage.getItem("eventRoomSortDirection")
      : "asc";
    this.selectedStatus = localStorage.getItem("EventRoomSelectedStatus")
      ? localStorage.getItem("EventRoomSelectedStatus")
      : "all";
    this.selectedHotel = localStorage.getItem("EventRoomSelectedHotel")
      ? localStorage.getItem("EventRoomSelectedHotel")
      : "all";
    this.selectedType = localStorage.getItem("EventRoomSelectedType")
      ? localStorage.getItem("EventRoomSelectedType")
      : "all";
  },
};
</script>
<style scoped>
td {
  padding-left: 0;
  padding-right: 0;
}
</style>
