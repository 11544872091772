<template>
  <button
    class="invisible-btn full-size"
    @click="this.$router.push(`/users/${item.id}`)"
  >
    <div class="d-flex px-2 py-1">
      <div v-if="item.profile_image">
        <soft-avatar
          :img="item.profile_image"
          size="sm"
          border-radius="lg"
          class="me-3"
          alt="user1"
        />
      </div>
      <div v-else class="d-flex px-2 py-1">
        <span
          class="avatar avatar-md rounded-circle me-3"
          :style="{
            background: `hsl(${(item.id * 30) % 360}, 30%, 70%)`,
          }"
        >
          {{ item.first_name ? item.first_name[0] : "" }}
          {{ item.last_name ? item.last_name[0] : "" }}
        </span>
      </div>

      <div class="d-flex flex-column justify-content-center">
        <h6 class="mb-0 text-sm text-white text-start">
          {{ item.full_name }}
        </h6>
        <p class="text-xs text-primary mb-0 text-start">
          {{ item.email }}
        </p>
      </div>
    </div>
  </button>
</template>

<script lang="js">
import SoftAvatar from "@/components/SoftAvatar.vue";

export default {
    name: "user-button",
    components: {
        SoftAvatar,
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    }
};
</script>
