<template>
  <div class="card h-200">
    <div class="p-3 pb-0 card-header">
      <div class="row">
        <div class="col-md-8 d-flex align-items-center">
          <h6 class="mb-0 text-bg-contrast">{{ title }}</h6>
        </div>
        <div class="col-md-4 text-end">
          <button v-if="!this.editable" class="invisible-btn" @click="this.editable = true">
            <i class="text-sm fas fa-pen text-bg-contrast" data-bs-toggle="tooltip" data-bs-placement="top"
              :title="$t('edit')"></i>
          </button>

          <button v-if="this.editable" class="invisible-btn"
            @click="bankdata.createNew ? postBankdata() : patchBankdata()">
            <i class="text-sm fas fa-check text-success" data-bs-toggle="tooltip" data-bs-placement="top"
              :title="$t('save')"></i>
          </button>
          <button v-if="this.editable" class="invisible-btn" @click="this.editable = false">
            <i class="text-sm fas fa-ban text-danger" data-bs-toggle="tooltip" data-bs-placement="top"
              :title="$t('cancel')"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="p-3 card-body">
      <div class="row">
        <div class="col">
          <ul class="list-group">
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast">{{ $t("iban") }}:</strong> &nbsp;
              <span v-if="!this.editable" class="text-primary fw-bold">{{
                formatIBAN(bankdata.iban)
              }}</span>
              <input v-if="this.editable" type="text" class="minimalistic-input" :placeholder="bankdata.iban"
                v-model="updatedBankdata.iban" />
            </li>
            <li class="text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast">{{ $t("bic") }}:</strong> &nbsp;
              <span v-if="!this.editable" class="text-primary fw-bold">{{
                bankdata.bic
              }}</span>
              <input v-if="this.editable" type="text" class="minimalistic-input" :placeholder="bankdata.bic"
                v-model="updatedBankdata.bic" />
            </li>
            <li class="text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast">{{ $t("bank_name") }}:</strong> &nbsp;
              <span v-if="!this.editable" class="text-primary fw-bold">{{
                bankdata.bank_name
              }}</span>
              <input v-if="this.editable" type="text" class="minimalistic-input" :placeholder="bankdata.bank_name"
                v-model="updatedBankdata.bank_name" />
            </li>
            <li class="text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast">{{ $t("holder_name") }}:</strong>
              &nbsp;
              <span v-if="!this.editable" class="text-primary fw-bold">{{
                bankdata.holder_name
              }}</span>
              <input v-if="this.editable" type="text" class="minimalistic-input" :placeholder="bankdata.holder_name"
                v-model="updatedBankdata.holder_name" />
            </li>
          </ul>
        </div>

        <!-- Temporary removed code below for the ADAC -->
<!--
        <div class="col">
          <ul class="list-group">
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast">{{ $t("cc_number") }}:</strong> &nbsp;
              <span v-if="!this.editable" class="text-primary fw-bold">{{
                formatCC(bankdata.cc_number)
              }}</span>
              <input v-if="this.editable" class="minimalistic-input" :placeholder="bankdata.cc_number"
                v-model="updatedBankdata.cc_number" />
            </li>
            <li class="text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast">{{ $t("cc_expiration_date") }}:</strong>
              &nbsp;
              <span class="text-bg-contrast" v-if="!this.editable">
                <span v-if="bankdata.cc_expiration_month && bankdata.cc_expiration_year">
                  {{ bankdata.cc_expiration_month }}/{{
                  bankdata.cc_expiration_year}}
                </span>
              </span>

              <div v-if="this.editable">
                <select class="minimalistic-input" v-model="updatedBankdata.cc_expiration_month">
                  <option v-for="month in [...Array(12).keys()].map((i) => i + 1)" :key="month" :value="month">
                    {{ month }}
                  </option>
                </select>
                <select class="minimalistic-input" v-model="updatedBankdata.cc_expiration_year">
                  <option v-for="year in [...Array(20).keys()].map(
                    (i) => i + new Date().getFullYear()
                  )" :key="year" :value="year">
                    {{ year }}
                  </option>
                </select>
              </div>
            </li>
            <li class="text-sm border-0 list-group-item ps-0">
              <strong class="text-bg-contrast">{{ $t("cc_holder_name") }}:</strong>
              &nbsp;
              <span v-if="!this.editable" class="text-primary fw-bold">{{
                bankdata.cc_holder_name
              }}</span>
              <input v-if="this.editable" type="text" class="minimalistic-input" :placeholder="bankdata.cc_holder_name"
                v-model="updatedBankdata.cc_holder_name" />
            </li>
          </ul>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ProfileBankdataCard",
  data() {
    return {
      editable: false,
      bankdata: {},
      updatedBankdata: {},
    };
  },
  methods: {
    formatIBAN(data) {
      if (!data) {
        return "";
      }
      return data.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
    },
    formatCC(cardNumber) {
      if (!cardNumber) {
        return "";
      }
      return cardNumber.replace(/[^\d]/g, '').replace(/(.{4})/g, '$1 ').trim();
    },
    setBankdata() {
      this.bankdata = this.info;
    },
    postBankdata() {
      axios
        .post(`/api/v1/users/${this.user_id}/bankdata/`, this.updatedBankdata)
        .then((response) => {
          Object.keys(response.data).forEach((key) => {
            this.bankdata[key] = response.data[key];
          });
          this.editable = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    patchBankdata() {
      Object.keys(this.updatedBankdata).forEach(
        (key) =>
          this.updatedBankdata[key] === "" && delete this.updatedBankdata[key]
      );
      axios
        .patch(`/api/v1/users/${this.user_id}/bankdata/`, this.updatedBankdata)
        .then((response) => {
          Object.keys(response.data).forEach((key) => {
            this.bankdata[key] = response.data[key];
          });
          this.editable = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.setBankdata();
  },

  props: {
    user_id: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      default: "profile",
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    info: {
      type: Object,
      fullName: String,
      mobile: String,
      email: String,
      country: String,
      default: () => { },
    },
    social: {
      type: Array,
      link: String,
      icon: String,
      default: () => [],
    },
    action: {
      type: Object,
      route: String,
      tooltip: String,
      default: () => ({
        route: "javascript:;",
      }),
    },
  },
};
</script>
