<template>
  <div class="container-fluid">
    <AvailabilityModal @save="saveAvailability" :visible="ShowModal" v-if="ShowModal" />
    <AvailabilitiesCard ref="availabilityCard">
      <template #headerButton>
        <!-- if user is admin display 'accept' button use getters.isSuperuser -->
        <span v-if="this.getters.isSuperuser && this.status === 'not_submitted'">
          <IconBtn v-if="!this.displayEditedWeeks" title="show_edited_weeks" icon="fa fa-eye text-info" @click="getEditedWeeks" />
          <IconBtn v-else title="show_all_weeks" icon="fa fa-eye-slash text-info" @click="displayAllWeeks" />
          <IconBtn title="accept" icon="fa fa-check text-success" @click="adminSubmitAvailability" />
        </span>
        <span v-if="this.isUserOwner()">
          <IconBtn title="edit_weeks" icon="fa fa-pencil text-bg-contrast" @click="displayModal" />
          <IconBtn v-if="this.editMode" title="save" icon="fa fa-save text-bg-contrast" @click="sendForSubmission" />
        </span>
      </template>
    </AvailabilitiesCard>
  </div>
</template>

<script>
import AvailabilitiesCard from "@/views/components/AvailabilitiesCard.vue";
import AvailabilityModal from "@/components/alerts/AvailabilityModal.vue";
import IconBtn from "@/components/buttons/IconBtn.vue";
import { useStore } from "vuex";

export default {
  setup() {
    return {
      getters: useStore().getters,
    };
  },
  data() {
    return {
      deleteMsg: "want_to_delete",
      ShowModal: false,
      editMode: false,
      status: "",
      selectedWeeks: [],
      editedWeeks: [],
      displayEditedWeeks: false,
      userId: "",
      year: new Date().getFullYear()
    };
  },
  props: {
    user_id: {
      type: Number,
      required: true,
    },
  },
  created() {
    this.setUserId();
  },
  methods: {
    setUserId() {
      if (this.$route.params.id) {
        this.userId = this.$route.params.id;
      } else {
        this.userId = this.getters.getCurrentUser.id;
      }
    },

    isUserOwner() {
      const pageUser = this.userId.toString();
      const loggedUser = this.getters.getCurrentUser.id.toString();
      return pageUser === loggedUser;
    },
    adminSubmitAvailability() {
      this.$store.dispatch("changeAvailabilitiesStatus", {
        userId: this.userId,
        status: "submitted",
      }).then((resp) => {
        if (resp.status === 200) {
          this.$store.dispatch("showAlert", {
            message: this.$t("availability_submitted"),
            type: "success",
            timeout: 3000,
          });
          this.status = "submitted";
        }
      });
    },

    getEditedWeeks() {
      const data = { userId: this.userId, year: new Date().getFullYear() };
      this.$store.dispatch("getEditedWeeks", data).then((resp) => {
        if (resp.status === 200) {
          this.editedWeeks = resp.data;
          // add old_weekdays_data to this.$refs.availabilityCard.weeks
          this.$refs.availabilityCard.weeks.forEach((week) => {
            const editedWeek = this.editedWeeks.find((w) => w.week === week.number);
            if (editedWeek) {
              week.old_data = editedWeek.old_weekdays_values;
            }
          });
          this.$refs.availabilityCard.filterWeeks("recently_edited")
          this.displayEditedWeeks = true;
        }
      })
    },
    displayAllWeeks() {
      this.$refs.availabilityCard.filterWeeks("all")
      this.displayEditedWeeks = false;
    },

    enableEditMode() {
      // enable edit mode and change availabilities status to in_edit
      this.editMode = true;
      this.$store.dispatch("changeAvailabilitiesStatus", {
        userId: this.userId,
        year: new Date().getFullYear(),
        status: "in_edit",
      });
    },

    getStatus() {
      const data = { userId: this.userId, year: this.year };
      this.$store.dispatch("checkStatus", data).then((resp) => {
        if (resp.status === 200) {
          if (resp.data.status === "in_edit") {
            this.editMode = true;
          }
          this.status = resp.data.status;
        }
      }).catch(() => {
        console.log('user has no availabilities');
      });
    },

    displayModal() {
      // check if any item is selected
      if (this.selectedWeeks.length > 0) {
        this.ShowModal = true;
      } else {
        this.$store.dispatch("showAlert", {
          message: this.$t("select_weeks_first"),
          type: "warning",
          timeout: 3000,
        });
      }
    },

    batchWeekUpd(weeks) {
      const data = { userId: this.userId, weeks: weeks };
      this.$store.dispatch("batchWeekUpdate", data).then((resp) => {
        if (resp.status === 204) {
          console.log("Weeks updated");
        } else {
          console.log("Weeks update failed");
        }
      });
    },

    saveAvailability(defaultWeek) {
      defaultWeek.weekdays = defaultWeek.weekdays.reduce((acc, cur) => {
        acc[cur.day] = cur.status;
        return acc;
      }, {});

      this.enableEditMode();
      this.selectedWeeks.forEach((week) => {
        week.earliest_start = defaultWeek.earliest_start.day + " " + defaultWeek.earliest_start.time;
        week.latest_end = defaultWeek.latest_end.day + " " + defaultWeek.latest_end.time;
        week.comment = defaultWeek.comment;
        for (const day in defaultWeek.weekdays) {
          week.weekdays[day] = defaultWeek.weekdays[day];
        }
      });
      this.batchWeekUpd(this.selectedWeeks);
    },

    sendForSubmission() {
      this.editMode = false;
      this.$store.dispatch("changeAvailabilitiesStatus", {
        userId: this.userId,
        year: new Date().getFullYear(),
        status: "not_submitted",
      });
    },

  },
  components: {
    AvailabilitiesCard,
    AvailabilityModal,
    IconBtn,
  },
};
</script>
