<template>
  <DeleteAlert
    @deleteEvent="deleteHotel"
    :msg="deleteMsg"
    :visible="ShowModal"
  />

  <div v-if="this.addHotelIsOpened" class="card mb-3">
    <div class="card-body">
      <div class="row flex-column-reverse flex-md-row text-bg-contrast">
        <div class="col">
          <ul class="list-group">
            <CustomInput
              v-for="item in ['name', 'phone', 'address', 'city', 'zip']"
              :key="item"
              :label="item"
              :editable="this.editable"
              :placeholder="selected_hotel[item]"
              :bindObj="item"
            />
          </ul>
        </div>
        <div class="col">
          <ul class="list-group">
            <SelectInput
              label="location"
              :editable="this.editable"
              :placeholder="selected_hotel.location"
              bindObj="location"
              :items="locations"
              valueObj="name"
              displayObj="name"
            >
              <template v-slot:optionAddition="slotItem">
                <span class="text-danger"> ({{ slotItem.item.country }})</span>
              </template>
            </SelectInput>
            <CustomInput
              type="time"
              label="check_in"
              :editable="this.editable"
              :placeholder="selected_hotel.check_in_time"
              bindObj="check_in_time"
            />

            <CustomInput
              type="time"
              label="check_out"
              :editable="this.editable"
              :placeholder="selected_hotel.check_out_time"
              bindObj="check_out_time"
            />
          </ul>
        </div>
      </div>
      <div class="row m-0 p-0">
        <div class="col-md-12 text-end">
          <IconBtn
            title="save"
            icon="fas fa-2x fa-save text-success"
            @click="addnewObj()"
          />
          <IconBtn
            title="cancel"
            icon="fas fa-2x fa-times-circle text-danger"
            @click="cancelAddHotel()"
          />
        </div>
      </div>
    </div>
  </div>

  <TableCard
    :tableItems="this.filteredHotels"
    :headers="this.headers"
    searchBy="name"
    filterPlaceholder="search_by_hotel"
    :sortAliases="{ location: 'location' }"
  >
    <template #cardHeader>
      <div class="row">
        <div class="col-5">
          <FilterSelection
            :items="this.hotelLocations"
            @filter="this.filterHotels"
            :translateOptions="false"
            label="location"
          />
        </div>
        <div class="col-7 text-end mb-2">
          <button
            class="btn btn-sm btn-primary text-primary-contrast"
            @click="openAddHotelCard()"
          >
            {{ $t("add_hotel") }}
          </button>
        </div>
      </div>
    </template>

    <template #tableBody="slotItem">
      <td width="30%">
        <p class="text-sm font-weight-thin mb-0 mx-4">
          {{ slotItem.item.name }}
        </p>
      </td>
      <td width="30%" class="text-center">
        <p class="text-sm font-weight-thin mb-0">
          {{ slotItem.item.location }}
        </p>
      </td>
      <td width="30%" class="text-center">
        <p class="text-sm font-weight-thin mb-0">
          {{ slotItem.item.country }}
        </p>
      </td>

      <td width="10%" class="text-center">
        <button
          class="invisible-btn"
          @click="
            () => {
              this.ShowModal = true;
              this.selected_hotel = slotItem.item;
            }
          "
        >
          <i
            class="text-sm fas fa-trash text-bg-contrast"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            :title="$t('delete')"
          ></i>
        </button>
      </td>
    </template>
  </TableCard>
</template>

<script>
import CustomInput from "@/components/inputs/CustomInput.vue";
import SelectInput from "@/components/inputs/SelectInput.vue";
import FilterSelection from "@/components/inputs/FilterSelection.vue";
import IconBtn from "@/components/buttons/IconBtn.vue";
import TableCard from "@/components/TableCard.vue";
import DeleteAlert from "@/components/alerts/DeleteAlert.vue";

import { useStore } from "vuex";

export default {
  name: "HotelsList",
  components: {
    CustomInput,
    SelectInput,
    FilterSelection,
    IconBtn,
    TableCard,
    DeleteAlert,
  },
  data() {
    return {
      deleteMsg: "want_to_delete",
      ShowModal: false,
      countries: this.$store.state.countries,
      hotels: this.getters.getHotels,
      locations: [],
      hotelLocations: [],
      filteredHotels: [],
      addHotelIsOpened: false,
      newObj: {},
      editable: false,
      selected_hotel: {},
      headers: ["name", "location", "country"],
    };
  },
  setup() {
    return {
      getters: useStore().getters,
    };
  },
  methods: {
    async getLocations() {
      await this.$store.dispatch("fetchLocations").then(() => {
        this.locations = this.getters.getLocations;
      });
    },

    getHotelLocations() {
      this.hotelLocations = this.hotels.map((item) => item.location);
      this.hotelLocations = [...new Set(this.hotelLocations)];
    },

    filterHotels(value) {
      if (value === "all") {
        this.filteredHotels = this.hotels;
      } else {
        this.filteredHotels = this.hotels.filter(
          (item) => item.location === value
        );
      }
    },

    async getHotels() {
      await this.$store
        .dispatch("getHotelsShort")
        .then(() => {
          this.hotels = this.getters.getHotels;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteHotel() {
      this.ShowModal = true;
      this.$store.dispatch("deleteHotel", this.selected_hotel).then(() => {
        this.ShowModal = false;
        this.hotels = this.hotels.filter(
          (item) => item.id !== this.selected_hotel.id
        );
        this.filterHotels("all");
      });
    },

    openAddHotelCard() {
      this.getLocations();
      this.addHotelIsOpened = true;
      this.editable = true;
    },
    cancelAddHotel() {
      this.addHotelIsOpened = false;
      this.editable = false;
    },

    async addnewObj() {
      let country = this.locations.find(
        (item) => item.name == this.newObj.location
      ).country;
      this.newObj.country = country;
      this.$store.dispatch("addHotel", this.newObj).then(() => {
        this.hotels = this.getters.getHotels;
        this.addHotelIsOpened = false;
        this.editable = false;
        this.filterHotels("all");
        this.$store.dispatch("showAlert", {
          type: "success",
          message: this.$t("hotel_added"),
          timeout: 5000,
        });
      }).catch((error) => {
        console.log(error);
        this.$store.dispatch("showAlert", {
          type: "error",
          message: error.response.data,
          timeout: 5000,
        });
      });
    },
  },
  created() {
    this.getHotels().then(() => {
      this.getHotelLocations();
      this.filterHotels("all");
    });
  },
};
</script>

<style scoped>
.card {
  max-height: 75vh;
  overflow-y: hidden;
  overflow-x: auto;
}
</style>
