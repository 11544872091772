<template>
  <div class="container-fluid">
    <div class="mt-n5 page-header min-height-200 border-radius-xl" :style="{
      backgroundImage:
        'url(' + require('@/assets/img/leomax-header-2.png') + ')',
      backgroundPositionY: '25%',
    }"></div>
    <div class="mb-n4 mx-4 overflow-hidden card card-body blur shadow-blur mt-n4">
      <div class="row gx-4">
        <div class="col-auto">
          <h6 class="mb-1">{{ $t("championship_event") }} {{ this.championship_event.name }}</h6>
          <p class="text-sm mb-1">
            {{ this.championship_event.readable_start_date }} -
            {{ this.championship_event.readable_end_date }} -
            <strong class="text-dark">{{ this.championship_event.location }}</strong>
          </p>
        </div>
        <div v-if="this.championship_event.event" class="col text-end">
          <h6 class="mb-1">{{ $t("event") }}: {{ this.championship_event.event }}</h6>
        </div>
      </div>
    </div>
  </div>

  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <!-- Spinning circle while data is loading -->
      <!-- TODO: move this element to the App.vue -->
      <div v-if="this.$store.state.isLoading" class="col-12">
        <div class="d-flex justify-content-center">
          <div class="spinner-border text-primary spinner-top" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      <div class="col-12">
        <championship-event-info-card :title="$t('championship_event_information')" :locations="this.locations" :info="championship_event"
          :key="championship_event" :action="{
            route: 'javascript:;',
            tooltip: $t('edit_championship_event'),
          }" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useStore } from "vuex";
import ChampionshipEventInfoCard from "./components/ChampionshipEventInfoCard.vue";

export default {
  name: "SingleChampionshipEvent",
  components: {
    ChampionshipEventInfoCard,
  },

  data() {
    return {
      championship_event: {},
      locations: this.getters.getLocations,
    };
  },
  setup() {
    return {
      getters: useStore().getters,
    };
  },
  methods: {
    switchCard(cardName) {
      this.activeCard = cardName;
    },

    goTo(link) {
      this.$router.push(link);
    },

    reformatDate(date) {
      return new Date(date).toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
    },

    async getChampionshipEventDetails() {
      await axios
        .get(`/api/v1/championship_events/${this.$route.params.id}/`)
        .then((response) => {
          this.championship_event = response.data;
          this.championship_event.readable_start_date = this.reformatDate(
            this.championship_event.start_date
          );
          this.championship_event.readable_end_date = this.reformatDate(this.championship_event.end_date);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getLocations() {
      await this.$store.dispatch("fetchLocations").then(() => {
        this.locations = this.getters.getLocations;
      });
    },
  },

  mounted() {
    this.getChampionshipEventDetails();
    this.getLocations();
    this.$store.dispatch("changeIsAbsolute", true);
  },
  beforeUnmount() {
    this.$store.dispatch("changeIsAbsolute", false)
  },
};
</script>
<style scoped>
.page-header {
  z-index: -100;
}
</style>
