<template>
  <div class="card h-200">
    <div class="p-3 pb-0 card-header">
      <div class="row">
        <div class="col-md-8 d-flex align-items-center">
          <h6 class="mb-0 text-bg-contrast">{{ this.title }}</h6>
        </div>
      </div>
    </div>
    <div class="p-3 card-body">
      <div class="row flex-column-reverse flex-md-row flex-sm-row">
        <div class="container">
          <div class="table-responsive">
            <table v-if="championship_events[0]" class="table align-items-center mb-0">
              <thead>
                <tr>
                  <th
                    v-for="header in [
                      'name',
                      'championship',
                      'location',
                      'start_date',
                      'end_date',
                      'jobs',
                    ]"
                    :key="header"
                    class="text-uppercase text-center text-primary text-xxs font-weight-bolder opacity-7"
                  >
                    {{ $t(header) }}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody class="text-center">
                <tr v-for="item in championship_events" :key="item">
                  <td>
                    <button
                      class="invisible-btn full-size text-primary"
                      @click="this.$router.push(`/championship_event/${item.id}`)"
                    >
                      <p class="text-sm font-weight-thin mb-0">
                        {{ item.name }}
                      </p>
                    </button>
                  </td>
                  <td>
                    <p class="text-sm font-weight-thin mb-0">
                      {{ item.event }}
                    </p>
                  </td>
                  <td>
                    <p class="text-sm font-weight-thin mb-0">
                      {{ item.location }}
                    </p>
                  </td>
                  <td>
                    <p class="text-sm font-weight-thin mb-0">
                      {{ item.start_date }}
                    </p>
                  </td>
                  <td>
                    <p class="text-sm font-weight-thin mb-0">
                      {{ item.end_date }}
                    </p>
                  </td>

                  <td>
                    <p
                      v-for="job in item.jobs"
                      :key="job.job"
                      class="text-sm font-weight-thin mb-0"
                    >
                      {{ job.job }}: {{ job.members }}
                    </p>
                  </td>
                  <td>
                    <button
                      class="invisible-btn"
                      @click="unassignChampionshipEvent(item.id)"
                    >
                      <i
                        class="text-sm fas fa-minus text-danger"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        :title="$t('unassign_from_event')"
                      ></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-else class="text-center">
              <i class="fas fa-exclamation-triangle text-bg-contrast fa-3x"></i>
              <p class="mt-2 text-bg-contrast ">
                {{ $t("event_has_no_championship_events") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card mt-3">
    <div class="p-3 pb-0 card-header">
      <div class="row">
        <div class="col-md-8 d-flex align-items-center">
          <h6 class="mb-0 text-bg-contrast">{{ $t("unassigned_championship_events") }}</h6>
        </div>
      </div>
    </div>
    <div class="p-3 card-body">
      <div class="row flex-column-reverse flex-md-row flex-sm-row">
        <div class="container">
          <div class="table-responsive">
            <table
              v-if="unassignedChampionshipEvents[0]"
              class="table align-items-center mb-0"
            >
              <thead class="text-center">
                <tr>
                  <th
                    v-for="header in [
                      'name',
                      'championship',
                      'location',
                      'start_date',
                      'end_date',
                      'jobs',
                    ]"
                    :key="header"
                    class="text-uppercase text-center text-primary text-xxs font-weight-bolder opacity-7"
                  >
                    {{ $t(header) }}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody class="text-center w-100">
                <tr v-for="item in unassignedChampionshipEvents" :key="item">
                  <td>
                    <button
                      v-if="!item.notSaved"
                      class="invisible-btn full-size text-primary"
                      @click="this.$router.push(`/championship_event/${item.id}`)"
                    >
                      <p class="text-sm font-weight-thin mb-0">
                        {{ item.name }}
                      </p>
                    </button>
                    <input
                      v-else
                      class="minimalistic-input"
                      type="text"
                      v-model="item.name"
                    />
                  </td>

                  <td>
                    <p
                      v-if="!item.notSaved"
                      class="text-sm font-weight-thin mb-0 w-100"
                    >
                      {{ item.championship }}
                    </p>
                    <select
                      v-else
                      class="minimalistic-input"
                      v-model="item.championship"
                    >
                      <option disabled selected></option>
                      <option
                        v-for="championship in championship"
                        :key="championship.id"
                        :value="championship.name"
                      >
                        {{ championship.name }}
                      </option>
                    </select>
                  </td>
                  <td>
                    <p class="text-sm font-weight-thin mb-0">
                      {{ item.location }}
                    </p>
                  </td>
                  <td>
                    <p
                      v-if="!item.notSaved"
                      class="text-sm font-weight-thin mb-0"
                    >
                      {{ item.start_date }}
                    </p>
                    <input
                      v-else
                      class="minimalistic-input"
                      type="date"
                      v-model="item.start_date"
                      :min="this.$parent.event.start_date"
                      :max="this.$parent.event.end_date"
                    />
                  </td>
                  <td>
                    <p
                      v-if="!item.notSaved"
                      class="text-sm font-weight-thin mb-0"
                    >
                      {{ item.end_date }}
                    </p>
                    <input
                      v-else
                      type="date"
                      class="minimalistic-input"
                      v-model="item.end_date"
                      :min="this.$parent.event.start_date"
                      :max="this.$parent.event.end_date"
                    />
                  </td>

                  <td>
                    <span v-if="!item.notSaved">
                      <p
                        v-for="job in item.jobs"
                        :key="job.job"
                        class="text-sm font-weight-thin mb-0"
                      >
                        {{ job.job }}: {{ job.members }}
                      </p>
                    </span>
                    <span v-else>
                      <p
                        v-for="job in item.jobs"
                        :key="job.job"
                        class="text-sm font-weight-thin mb-0"
                      >
                        {{ job.job }}:

                        <input
                          class="minimalistic-input w-50"
                          v-model="job.members"
                          type="number"
                          min="0"
                          max="50"
                        />
                      </p>
                    </span>
                  </td>
                  <td>
                    <button
                      v-if="!item.notSaved"
                      class="invisible-btn"
                      @click="assignChampionshipEvent(item.id)"
                    >
                      <i
                        class="text-sm fas fa-plus text-success"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        :title="$t('assign_to_the_event')"
                      ></i>
                    </button>
                    <span v-else>
                      <button
                        class="invisible-btn"
                        @click="saveChampionshipEvent(item)"
                        :title="$t('save')"
                      >
                        <i class="fas fa-save text-success"></i>
                      </button>
                      <button
                        class="invisible-btn"
                        @click="cancelChampionshipEvent(item)"
                        :title="$t('cancel')"
                      >
                        <i class="fas fa-times-circle text-danger"></i>
                      </button>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-else class="text-center">
              <i class="fas fa-exclamation-triangle text-bg-contrast fa-3x"></i>
              <p class="mt-2 text-bg-contrast ">
                {{ $t("no_championship_event_for_this_event") }}
              </p>
              <!-- button to add championship_event -->
              <button class="btn btn-sm btn-primary text-primary-contrast" @click="addChampionshipEventToList()">
                {{ $t("create_championship_event") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "EventChampionshipEventsCard",
  components: {},
  data() {
    return {
      championship_events: {},
      unassignedChampionshipEvents: {},
      allJobs: [],
      locations: [],
      championship: [],
    };
  },

  methods: {
    // setChampionshipEventInfo() {
    //   this.championship_events = this.info;
    // },

    async unassignChampionshipEvent(championship_eventId) {
      await axios
        .post(`api/v1/championship_events/${championship_eventId}/unassign/`)
        .then(() => {
          this.unassignedChampionshipEvents.push(
            this.championship_events.find((championship_event) => championship_event.id === championship_eventId)
          );

          this.championship_events = this.championship_events.filter(
            (championship_event) => championship_event.id !== championship_eventId
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async assignChampionshipEvent(championship_eventId) {
      await axios
        .patch(`api/v1/championship_events/${championship_eventId}/`, {
          event: this.$parent.event.name,
        })
        .then(() => {
          this.championship_events.push(
            this.unassignedChampionshipEvents.find((championship_event) => championship_event.id === championship_eventId)
          );
          this.unassignedChampionshipEvents = this.unassignedChampionshipEvents.filter(
            (championship_event) => championship_event.id !== championship_eventId
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getUnassignedChampionshipEvents() {
      await axios
        .get(
          `api/v1/championship_events/?min_date=${this.$parent.event.start_date}&max_date=${this.$parent.event.end_date}&location=${this.$parent.event.location}&event_isnull=true`
        )
        .then((res) => {
          this.unassignedChampionshipEvents = res.data.results;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    reformatDate(date) {
      return new Date(date).toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
    },

    async getEventChampionshipEvents() {
      await axios
        .get(`/api/v1/championship_events/?event_id=${this.$route.params.id}`)
        .then((response) => {
          this.championship_events = response.data.results;
          this.championship_events.forEach((championship_event) => {
            championship_event.start_date = this.reformatDate(championship_event.start_date);
            championship_event.end_date = this.reformatDate(championship_event.end_date);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getAllJobs() {
      await axios
        .get("/api/v1/jobs/")
        .then((response) => {
          this.allJobs = response.data.results.map((job) => job.name);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getLocations() {
      await this.$store.dispatch("fetchLocations").then(() => {
        this.locations = this.getters.getLocations;
      });
    },

    async getChampionship() {
      await axios
        .get("/api/v1/championship/?fields=id,name")
        .then((response) => {
          this.championship = response.data.results;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    addChampionshipEventToList() {
      // this.getChampionshipEventTracks();
      this.getChampionship();
      this.getAllJobs().then(() => {
        this.unassignedChampionshipEvents.push({
          name: "",
          championship: "",
          location: this.$parent.event.location,
          start_date: "",
          end_date: "",
          jobs: this.allJobs.map((job) => {
            return { job: job, members: 0 };
          }),
          notSaved: true,
        });
      });
    },

    async saveChampionshipEvent(championship_event) {
      delete championship_event.notSaved;
      championship_event.jobs = championship_event.jobs.filter((job) => job.members > 0);
      await axios
        .post("/api/v1/championship_events/", championship_event)
        .then(() => {
          this.getEventChampionshipEvents();
          this.getUnassignedChampionshipEvents();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    cancelChampionshipEvent(championship_event) {
      this.unassignedChampionshipEvents = this.unassignedChampionshipEvents.filter(
        (item) => item !== championship_event
      );
    },
  },

  props: {
    title: {
      type: String,
      required: true,
    },
  },
  created() {
    // this.setChampionshipEventInfo();
    this.getUnassignedChampionshipEvents();
    this.getEventChampionshipEvents();
  },
};
</script>
<style scoped></style>
