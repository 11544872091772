<template>
  <TableCard v-if="this.weeks.length" :tableItems="this.filteredWeeks" :headers="this.headers" searchBy="month"
    filterPlaceholder="search_by_month" :sortAliases="{
      week: 'id',
    }">
    <template #cardHeader>
      <div class="row">
        <div class="col-md-5 col-sm-12 mb-4">
          <FilterSelection :items="['previous', 'next']" @filter="this.filterWeeks" label="display" />
        </div>
        <div class="col-7 text-end mb-2">
          <slot name="headerButton"></slot>
        </div>
      </div>
    </template>

    <template #selectAll>
      <th class="text-uppercase text-primary text-xxs font-weight-bolder opacity-7">
        <input type="checkbox" id="select_all" @change="selectAllWeeks" :checked="
          this.$parent.selectedWeeks.length === this.filteredWeeks.length
        " :indeterminate="
  this.$parent.selectedWeeks.length > 0 &&
  this.$parent.selectedWeeks.length < this.filteredWeeks.length
" />
      </th>
    </template>

    <template #tableBody="slotItem">
      <td width="5%" class="text-center">
        <input type="checkbox" @change="this.selectWeek(slotItem.item.id)"
          :checked="this.$parent.selectedWeeks.includes(slotItem.item)" />
      </td>
      <td width="15%" class="text-center">
        <p class="text-sm font-weight-thin mb-0">
          {{ this.$store.getters.formatDate(slotItem.item.start_date) }} -
          {{ this.$store.getters.formatDate(slotItem.item.end_date) }}
        </p>
      </td>
      <td width="6%" v-for="el of this.parseWeekdays(slotItem.item)" :key="el.status" class="text-center p-0">
        <div v-if="el.old_data && this.$parent.displayEditedWeeks" class="diagonal-rectangle"
          :style="getDiagRectangleStyle(el.old_data, el.status)" :title="`${$t(el.old_data)} &rarr; ${$t(el.status)}`">
        </div>
        <button v-else style="width: 3.5rem" @click="changeWeekdayStatus(slotItem.item, el.day, el.status)"
          :title="$t(el.status)" class="badge badge-sm" :class="{
            'text-dark bg-success': el.status === 'available',
            'text-white bg-danger': el.status === 'unavailable',
            'text-dark bg-warning': el.status === 'not_sure',
            'text-dark bg-gray-300': el.status === 'not_set',
          }">
          <p></p>
        </button>
      </td>
      <td width="18%" class="text-center">
        <p class="text-sm font-weight-thin mb-0">
          {{ slotItem.item.comment }}
        </p>
      </td>
      <td width="10%" class="text-center">
        <p class="text-sm font-weight-thin mb-0">
          {{ slotItem.item.earliest_start }}
        </p>
      </td>
      <td width="10%" class="text-center">
        <p class="text-sm font-weight-thin mb-0">
          {{ slotItem.item.latest_end }}
        </p>
      </td>
    </template>
  </TableCard>
  <div v-else class="card card-body p-3">
    <div class="text-center">
      <div class="mb-3">
        <i class="fas fa-calendar fa-3x text-bg-contrast"></i>
      </div>
      <button class="btn btn-primary" @click="addWeeks">
        <span class="text-primary-contrast">{{ $t("add_availabilities") }} </span>
      </button>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import TableCard from "@/components/TableCard.vue";
import FilterSelection from "@/components/inputs/FilterSelection.vue";
import { useStore } from "vuex";

export default {
  name: "AvailabilitiesCard",
  components: {
    TableCard,
    FilterSelection,
  },
  data() {
    return {
      headers: [
        "week",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat",
        "Sun",
        "Mon",
        "comment",
        "earliest_start",
        "latest_end",
      ],
      weeks: [],
      filteredWeeks: [],
    };
  },

  setup() {
    return {
      getters: useStore().getters,
    };
  },
  methods: {
    parseOldData(old_data, day) {
      if (old_data) {
        return old_data[day];
      }
      return null;
    },

    parseWeekdays(item) {
      const old_data = item.old_data || null;
      const obj = item.weekdays;
      const weekdays = [
        { day: 'tuesday', status: obj.tuesday, old_data: this.parseOldData(old_data, "tuesday") },
        { day: 'wednesday', status: obj.wednesday, old_data: this.parseOldData(old_data, "wednesday") },
        { day: 'thursday', status: obj.thursday, old_data: this.parseOldData(old_data, "thursday") },
        { day: 'friday', status: obj.friday, old_data: this.parseOldData(old_data, "friday") },
        { day: 'saturday', status: obj.saturday, old_data: this.parseOldData(old_data, "saturday") },
        { day: 'sunday', status: obj.sunday, old_data: this.parseOldData(old_data, "sunday") },
        { day: 'monday', status: obj.monday, old_data: this.parseOldData(old_data, "monday") }
      ]
      return weekdays;
    },
    getDiagRectangleStyle(old_data, status) {
      const colorMap = {
        "available": "#82D622",
        "unavailable": "#A03113",
        "not_sure": "#FBCF33",
        "not_set": "#DEE2E6"
      }
      return `background: linear-gradient(to right bottom, ${colorMap[old_data]} 50%, ${colorMap[status]} 50.3%);`
    },

    async getWeeksData() {

      const user = this.$parent.userId;
      const data = { userId: user, year: new Date().getFullYear() };
      this.$store.dispatch("getUserWeeks", data).then((resp) => {
        if (resp.count > 0) {
          this.weeks = resp.results;
          this.filteredWeeks = this.weeks;
        } else {
          console.log("No weeks found for user");
        }
      });
    },

    addWeeks() {
      const data = { userId: this.$parent.userId, year: new Date().getFullYear() };
      this.$store.dispatch("addWeeks", data).then((resp) => {
        if (resp.status === 201) {
          console.log("Weeks added");
          this.getWeeksData();
        }
      });
    },

    filterWeeks(value) {
      const today = new Date();

      if (value === "all") {
        this.filteredWeeks = this.weeks;
      } else if (value === "previous") {
        this.filteredWeeks = this.weeks.filter((week) => {
          const weekDate = new Date(week.start_date);
          return weekDate <= today;
        });
      } else if (value === "next") {
        this.filteredWeeks = this.weeks.filter((week) => {
          const weekDate = new Date(week.start_date);
          return weekDate > today;
        });
      } else if (value === "recently_edited") {
        this.filteredWeeks = this.weeks.filter((week) => {
          return this.$parent.editedWeeks.some((editedWeek) => {
            return editedWeek.week === week.number;
          });
        });
      }
    },

    saveWeek(week) {
      console.log("Edit week");
      const user = this.$parent.userId;
      const data = { userId: user, week: week };
      this.$store.dispatch("editWeek", data).then((resp) => {
        if (resp.status === 200) {
          console.log("Week updated");
        } else {
          console.log("Week update failed");
        }
      });
    },

    changeWeekdayStatus(week, weekday, oldStatus) {
      this.$parent.enableEditMode();
      const statuses = ["available", "unavailable", "not_sure", "not_set"];
      const status =
        statuses[(statuses.indexOf(oldStatus) + 1) % statuses.length];

      week.weekdays[weekday] = status;
      // update selected week in weeks array
      this.weeks = this.weeks.map((w) => {
        if (w.id === week.id) {
          return week;
        } else {
          return w;
        }
      });
      this.saveWeek(week);
      this.filterWeeks("all");
    },

    isSuperuser() {
      return this.$store.getters.isSuperuser;
    },

    selectWeek(id) {
      const selectedWeek = this.weeks.find((week) => week.id === id);
      if (this.$parent.selectedWeeks.includes(selectedWeek)) {
        this.$parent.selectedWeeks = this.$parent.selectedWeeks.filter(
          (week) => week !== selectedWeek
        );
      } else {
        this.$parent.selectedWeeks = [
          ...this.$parent.selectedWeeks,
          selectedWeek,
        ];
      }
    },

    selectAllWeeks(event) {
      const checked = event.target.checked;

      if (checked) {
        this.$parent.selectedWeeks = this.filteredWeeks;
      } else {
        this.$parent.selectedWeeks = [];
      }
    },
  },

  created() {
    setTooltip();
    this.getWeeksData();
    this.$parent.getStatus();
  },
};
</script>
<style scoped>
.card {
  max-height: 70vh;
  overflow-y: hidden;
  overflow-x: auto;
}

.diagonal-rectangle {
  border: 2px outset rgb(118, 118, 118);
  margin: auto;
  border-radius: 0.45rem;
  height: 2rem;
  width: 3.5rem;
  overflow: hidden;
}
</style>
