<template>
  <AlertDefault @event="closeModal" :visible="visible">
    <template v-slot:title>
      <h5 class="modal-title text-white">{{ $t("new_expense") }}</h5>
    </template>

    <template v-slot:body>
      <div class="container">
        <div class="row">
          <div class="col-6">
            <p class="text-white">{{ $t("type") }}</p>
          </div>
          <div class="col-6">
            <select class="form-select" v-model="typeToSave.expense_type">
              <option
                v-for="type in this.$parent.expenseTypes"
                :key="type.id"
                :value="type.type"
              >
                {{ type.type }}
              </option>
            </select>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-6">
            <p class="text-white">
              {{ $t("cost_center") }}
            </p>
          </div>
          <div class="col-6">
            <input
              type="text"
              class="form-control text-white"
              style="border: 1px solid #fff; border-radius: 5px"
              v-model="typeToSave.cost_center"
            />
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <slot name="secondBtn"></slot>
      <button type="button" @click="saveItem" :class="'btn btn-outline-success'">
        {{ $t("save") }}
      </button>
    </template>
  </AlertDefault>
</template>

<script lang="js">
import AlertDefault from "./AlertDefault.vue";
export default {
  name: "AddExpenseModal",

  data() {
    return {
      typeToSave: {
        expense_type: "",
        cost_center: "",
      },
    };
  },

  methods: {
    closeModal() {
      this.$parent.editMode = false;
      this.$parent.ShowModal
        ? (this.$parent.ShowModal = false)
        : (this.$parent.ShowModal = true);
    },
    getAllJobs() {
      this.$store.dispatch("fetchJobs")
        .then((resp) => {
          this.jobsList = resp.results;
        })
        .catch((error) => {
          console.log(error);
        })
    },

    saveItem() {
      this.$emit("save", this.typeToSave);
      this.$parent.ShowModal = false;
    },
  },
  created() {
    this.getAllJobs();
  },
  components: {
    AlertDefault,
  },
  props: {
    visible: Boolean,
    title_text: String,
  },
};
</script>
<style scoped></style>
