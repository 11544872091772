<template>
  <div class="mx-auto mt-3 col-lg-6 col-md-6 my-sm-auto ms-sm-auto me-sm-0">
    <div class="nav-wrapper position-relative end-0">
      <ul class="p-1 bg-transparent nav nav-pills nav-fill" role="tablist">
        <li v-for="item in this.menuList" :key="item" class="nav-item">
          <a
            :name="item"
            class="px-0 py-1 mb-0 nav-link"
            :id="item"
            data-bs-toggle="tab"
            @click="this.switchMenuFunc(item)"
            href=""
            role="tab"
            aria-selected="true"
          >
            <span class="ms-1">{{ $t(item) }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// import setNavPills from "@/assets/js/nav-pills.js";

export default {
  name: "SwitchMenu",
  props: {
    menuList: {
      type: Array,
      default: () => [],
    },
    switchMenuFunc: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    addActiveClass() {
      this.$el.querySelector(".nav-link").classList.add("active");
    },
  },
  mounted() {
    this.addActiveClass();
  },
};
</script>
