<template>
  <div>
    <li class="text-sm border-0 list-group-item ps-0">
      <div class="row">
        <div class="col-5">
          <strong class="text-white fw-normal">{{ $t(label) }}:</strong>
        </div>
        <div class="col-7 p-0">
          <span v-if="!editable">{{ placeholder }}</span>
          <select
            v-if="editable"
            class="minimalistic-input"
            v-model="this.$parent[bindParent][bindObj]"
            @change="
              this.$emit('changeVal', $event.target.value) ? useEmit : null
            "
          >
            <option
              v-for="item in items"
              :value="item[valueObj] ? item[valueObj] : item"
              :key="item"
            >
              {{ item[displayObj] ? item[displayObj] : item }}
              <slot name="optionAddition" :item="item"></slot>
            </option>
          </select>
        </div>
      </div>
    </li>
  </div>
</template>

<script>
export default {
  name: "SelectInput",
  props: {
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    label: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
    bindParent: {
      type: String,
      required: false,
      default: "newObj",
    },
    bindObj: {
      type: String,
      required: false,
    },
    displayObj: {
      type: String,
      required: false,
      default: null,
    },
    valueObj: {
      type: String,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    items: {
      type: Array,
      required: true,
    },
    useEmit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
<style scoped>
.minimalistic-input {
  min-width: 100%;
}
</style>
