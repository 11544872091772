<template>
  <span class="badge badge-sm bg-success text-dark" v-if="item.is_active">
    {{ $t("active") }}
  </span>
  <span class="badge badge-sm bg-danger" v-else-if="!item.is_active">
    {{ $t("inactive") }}
  </span>
</template>

<script lang="js">
export default {
    name: "user-active-badge",

    props: {
        item: {
            type: Object,
            required: true
        }
    }
};
</script>
