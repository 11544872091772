<template>
  <div class="container-fluid">
    <div class="mx-4 overflow-hidden card card-body blur mt-0">
      <div class="row gx-4">
        <div class="col-auto">
          <div class="avatar avatar-xl position-relative">
            <div v-if="user.profile_image">
              <soft-avatar :img="user.profile_image" size="sm" border-radius="lg" class="me-3" alt="user1" />
            </div>
            <div v-else class="d-flex px-2 py-1">
              <span class="avatar avatar-md rounded-circle me-3" :style="{
                background: `hsl(${(user.id * 30) % 360}, 30%, 70%)`,
              }">
                {{ user.first_name ? user.first_name[0] : "" }}
                {{ user.last_name ? user.last_name[0] : "" }}
              </span>
            </div>
          </div>
        </div>
        <div class="col-auto my-auto">
          <div class="h-100">
            <h5 class="mb-1 text-dark">{{ user.first_name + " " + user.last_name }}</h5>
            <p class="mb-0 text-sm font-weight-bold">{{ user.company }}</p>
          </div>
        </div>

        <SwitchMenu :menuList="[
          'profile',
          'banking',
          'travel',
          'licenses',
          'availabilities',
        ]" :switchMenuFunc="switchCard" />
      </div>
    </div>

    <div class="py-4 container-fluid">
      <div class="mt-3 row">
        <div v-if="this.$store.state.isLoading" class="col-12">
          <div class="d-flex justify-content-center">
            <div class="spinner-border text-primary spinner-top" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>

        <div v-if="this.activeCard == 'profile' && user.username" class="col-12 col-md-6 col-xl-12">
          <div class="row">
            <div :class="getProfileInfoClass()">
              <profile-info-card :title="$t('profile_information')" :info="user" :key="user.signature" :action="{
                route: 'javascript:;',
                tooltip: $t('edit_profile'),
              }" />
            </div>
            <div class="col-md-3" v-if="this.$store.state.user.is_superuser">
              <jobs-info-card :title="$t('allowed_jobs')" :info="user" :key="user.id" :action="{
                route: 'javascript:;',
                tooltip: $t('edit_profile'),
              }" />
            </div>
          </div>
        </div>

        <div v-if="this.activeCard == 'banking' && user.bank_data" class="mt-4 col-12 col-md-6 col-xl-12 mt-md-0">
          <banking-info-card :title="$t('banking_information')" :info="user.bank_data" :user_id="user.id" :key="user.id"
            :action="{
              route: 'javascript:;',
              tooltip: $t('edit_bank_details'),
            }" />
        </div>
        <div v-if="this.activeCard == 'banking' && !user.bank_data">
          <div class="col-12 col-md-6 col-xl-12 mt-md-0">
            <div class="card">
              <div class="card-header pb-0 p-3">
                <h6 class="mb-0 text-bg-contrast">{{ $t("banking_information") }}</h6>
              </div>
              <div class="card-body p-3">
                <div class="text-center">
                  <div class="mb-3">
                    <!-- icon bank -->
                    <i class="fas fa-university fa-3x text-bg-contrast"></i>
                  </div>
                  <button class="btn btn-primary " @click="user.bank_data = emptyBankdata">
                    <span class="text-primary-contrast">{{ $t("add_bankdata") }} </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="this.activeCard == 'travel' && user.travel_settings" class="mt-4 col-12 col-md-6 col-xl-12 mt-md-0">
          <travel-info-card :title="$t('travel_information')" :username="user.username" :info="user.travel_settings"
            :user_id="user.id" :key="user.id" :action="{
              route: 'javascript:;',
              tooltip: $t('edit_travel_details'),
            }" />
        </div>
        <div v-if="this.activeCard == 'travel' && !user.travel_settings">
          <div class="col-12 col-md-6 col-xl-12 mt-md-0">
            <div class="card">
              <div class="card-header pb-0 p-3">
                <h6 class="mb-0 text-bg-contrast">{{ $t("travel_information") }}</h6>
              </div>
              <div class="card-body p-3">
                <div class="text-center">
                  <!-- big icon with button add_license -->
                  <div class="mb-3">
                    <!-- icon travel -->
                    <i class="fas fa-suitcase fa-3x text-bg-contrast"></i>
                  </div>
                  <button class="btn btn-primary" @click="user.travel_settings = emptyTravelData">
                    <span class="text-primary-contrast">{{ $t("add_travel_settings") }}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="this.activeCard == 'licenses' && user.licenses.length > 0"
          class="mt-4 col-12 col-md-6 col-xl-12 mt-md-0">
          <license-info-card :title="$t('licenses_information')" :username="user.username" :info="user.licenses"
            :user_id="user.id" :key="user.id" :action="{
              route: 'javascript:;',
              tooltip: $t('edit_licenses_details'),
            }" />
        </div>
        <div v-if="this.activeCard == 'licenses' && user.licenses.length == 0">
          <div class="col-12 col-md-6 col-xl-12 mt-md-0">
            <div class="card">
              <div class="card-header pb-0 p-3">
                <h6 class="mb-0 text-bg-contrast">
                  {{ $t("licenses_information") }}
                </h6>
              </div>
              <div class="card-body p-3">
                <div class="text-center">
                  <!-- big icon with button add_license -->
                  <div class="mb-3">
                    <i class="fas fa-id-card fa-3x text-bg-contrast"></i>
                  </div>
                  <button class="btn btn-primary" @click="user.licenses[0] = emptyLicense">
                    <span class="text-primary-contrast">{{ $t("add_license") }} </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="this.activeCard == 'availabilities'" class="col-12 col-md-6 col-xl-12">
          <AvailabilityInfoCard :user_id="user.id" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SwitchMenu from "@/components/SwitchMenu.vue";
import ProfileInfoCard from "./components/InfoCards/ProfileInfoCard.vue";
import BankingInfoCard from "./components/InfoCards/BankingInfoCard.vue";
import TravelInfoCard from "./components/InfoCards/TravelInfoCard.vue";
import LicenseInfoCard from "./components/InfoCards/LicenseInfoCard.vue";
import AvailabilityInfoCard from "./components/InfoCards/AvailabilityInfoCard.vue";
import JobsInfoCard from "./components/InfoCards/JobsInfoCard.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";

export default {
  name: "ProfileOverview",
  components: {
    // SoftSwitch,
    ProfileInfoCard,
    BankingInfoCard,
    TravelInfoCard,
    LicenseInfoCard,
    AvailabilityInfoCard,
    JobsInfoCard,
    SoftAvatar,
    SwitchMenu,
  },
  data() {
    return {
      showMenu: false,
      user: {},
      activeCard: this.$route.query.tab || "profile",
      emptyBankdata: {
        bank_name: "",
        iban: "",
        bic: "",
        account_holder: "",
        createNew: true,
      },
      emptyTravelData: {
        travel_distance: "",
        travel_distance_unit: "",
        travel_time: "",
        travel_time_unit: "",
        createNew: true,
      },
      emptyLicense: {
        license_type: "",
        license_number: "",
        license_valid_until: "",
        createNew: true,
      },
      path: "",
    };
  },
  methods: {
    getProfileInfoClass() {
      if (this.$store.state.user.is_superuser) {
        return "col-md-9 col-sm-12";
      } else {
        return "col-md-12 col-sm-12";
      }
    },
    async getUser() {
      if (this.$route.path === "/profile") {
        this.path = `/api/users/me/`;
        this.user = this.$store.state.user;
      } else if (
        this.$store.state.user.is_superuser &&
        this.$route.path !== "/profile"
      ) {
        this.path = `/api/v1/users/${this.$route.params.id}/`;
        this.$store.commit("setIsLoading", true);
      } else {
        this.path = `/api/users/me/`;
      }
      console.log(this.path);

      await axios
        .get(this.path)
        .then((response) => {
          this.user = response.data;
          this.$store.commit("setIsLoading", false);
          if (this.$route.path === "/profile") {
            this.$store.commit("setUser", this.user);
          }
        })
        .catch(() => {
          this.$store.commit("setIsLoading", false);
        });
    },

    switchCard(cardName) {
      this.activeCard = cardName;
    },
  },

  created() {
    this.getUser();
  },
  mounted() {
    if (this.$route.query.tab) {
      this.activeCard = this.$route.query.tab;
      document.querySelectorAll(".nav-link").forEach((item) => {
        item.classList.remove("active");
      });
      document.getElementById(this.$route.query.tab).classList.add("active");
    }
  },
};
</script>
<style scoped>
.spinner-top {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 999;
  width: 50px;
  height: 50px;
}
</style>
