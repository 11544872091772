<template>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class="card card-plain">
                <div class="card-header">
                  <div :style="logoStyle"></div>
                </div>
                <div class="card-body pt-0">
                  <form
                    role="form"
                    class="text-start pt-0"
                    @submit.prevent="submitForm"
                  >
                    <label class="text-bg-contrast">{{ $t("username_or_email") }}</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Username"
                      v-model="this.username"
                    />

                    <label class="text-bg-contrast">{{ $t("password") }}</label>
                    <input
                      class="form-control"
                      type="password"
                      placeholder="Password"
                      id="password"
                      name="password"
                      v-model="this.password"
                    />
                    <!-- TODO: implement remember me option -->
                    <soft-switch id="rememberMe" name="rememberMe" labelClass="text-bg-contrast" checked>
                      Remember me
                    </soft-switch>
                    <div class="notification is-danger" v-if="errors.length">
                      <p v-for="error in errors" v-bind:key="error">
                        {{ error }}
                      </p>
                    </div>
                    <div class="text-center">
                      <soft-button
                        class="my-4 text-primary-contrast"
                        variant="solid"
                        color="primary"
                        full-width
                        >Log In with Credentials
                      </soft-button>

                    </div>
                  </form>
                  <!-- ad word 'or' -->
                  <div class="text-center">
                    <span class="text-bg-contrast">{{ $t("or") }}</span>
                  </div>
                  <OAuthLogin />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import axios from "axios";
import SoftSwitch from "@/components/SoftSwitch.vue";
import SoftButton from "@/components/SoftButton.vue";
import OAuthLogin from "@/components/OAuthLogin.vue";

import AuthService from '@/services/authService';


const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";

export default {
  name: "Login",
  components: {
    SoftSwitch,
    SoftButton,
    OAuthLogin
  },

  data() {
    return {
      user: {},
      username: "",
      password: "",
      errors: [],
    };
  },

  created() {
    this.$store.commit("hideEveryDisplay");
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
    // console.log(process.env.VUE_APP_COMPANY_NAME);
  },
  computed: {
    logoStyle() {
      return {
        content: `url(/logos/${process.env.VUE_APP_COMPANY_NAME}_logo.png)`,
        scale: 0.5,
        'margin-top': "-100px",
        'margin-bottom': "-100px",
        'border-radius': '30px',
      };
    },
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    async submitForm() {
      axios.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("token");
      localStorage.removeItem("tokenType");

      const formData = {
        username: this.username,
        password: this.password,
      };

      await axios
        .post("/api/token/login/", formData)
        .then((response) => {
          const token = response.data.auth_token;
          const authService = new AuthService();
          authService.handleSuccessfullAuth('Token', token);

        })
        .catch((err) => {
          console.log("error in login");
          console.log(err);
          this.$store.dispatch("showAlert", {
            type: "error",
            message: "Invalid username or password",
            timeout: 5000,
          });
        });
    },
  },
};
</script>
<style scoped>
.card-header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.card {
  margin-top: 100px;
  border: solid;
  border-color: rgba(0, 0, 0, 0.087);
}

.form-control {
  background: transparent;
  color: white;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: rgba(255, 255, 255, 0.696) !important;
}
</style>
