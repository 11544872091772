<template>
  <div class="container">
    <div class="card overflow-auto">
      <div class="card-header">
        <div class="row">
          <div class="col-md-8 d-flex align-items-center">
            <h5 class="text-secondary">
              {{ $t("hotels_report") }} - {{ this.$route.params.eventName }}
            </h5>
          </div>
          <div class="col-md-4 text-end">
            <button
              class="btn btn-sm btn-primary text-dark"
              @click="generatePDF()"
            >
              <span>{{ $t("download_report") }}</span>
            </button>
          </div>
        </div>
      </div>

      <div class="card-body">
        <div class="page">
          <vue3-html2pdf
            :show-layout="false"
            :float-layout="false"
            :enable-download="false"
            :preview-modal="true"
            :paginate-elements-by-height="800"
            filename="report"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="a4"
            :pdf-margin="10"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            ref="html2Pdf"
            :html-to-pdf-options="{
              margin: [10, 0, 10, 0],
            }"
          >
            <template v-slot:pdf-content>
              <div class="container">
                <div
                  v-for="hotel in hotels"
                  :key="hotel.id"
                  style="
                    break-inside: avoid;
                    color: black;
                    margin-bottom: 20px;
                    margin-top: 20px;
                  "
                >
                  <span v-if="hotel.assigned_users.length !== 0">
                    <h6 style="color: black; font-size: 20px">
                      {{ hotel.name }}
                    </h6>
                    <div style="margin-bottom: 10px; font-size: 14px">
                      {{ hotel.address }}<br />
                      {{ hotel.zip }} {{ hotel.city }} <br />
                      {{ hotel.country }}<br />
                      {{ $t("phone") }}: {{ hotel.phone }}<br />
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <table class="w-100">
                          <thead>
                            <tr>
                              <th>{{ $t("name") }}</th>
                              <th v-for="date in hotel.dates" :key="date">
                                {{
                                  date.toLocaleDateString("de-DE", {
                                    weekday: "short",
                                    day: "2-digit",
                                    month: "2-digit",
                                  })
                                }}
                              </th>

                              <th>{{ $t("room_type") }}</th>
                              <th>{{ $t("shares_room") }}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="user in hotel.assigned_users"
                              :key="user.id"
                              :style="'background-color: ' + user.room_color"
                            >
                              <td>
                                {{ user.user.full_name }}
                              </td>

                              <td v-for="date in hotel.dates" :key="date">
                                <span
                                  class="text-danger fw-bolder"
                                  v-if="
                                    new Date(date)
                                      .toISOString()
                                      .split('T')[0] === user.check_out
                                  "
                                  ><div
                                    class="progress-bar progress-bar-striped bg-danger"
                                    role="progressbar"
                                    style="width: 50%"
                                  ></div
                                ></span>
                                <span class="text-dark fw-bolder" v-else>
                                  <span
                                    v-if="checkIfUserIsAssigned(user, date)"
                                  >
                                    <div
                                      v-if="
                                        date.toISOString().split('T')[0] ===
                                        user.check_in
                                      "
                                      class="progress-bar progress-bar-striped bg-danger"
                                      role="progressbar"
                                      style="
                                        width: 70%;
                                        position: relative;
                                        left: 40%;
                                      "
                                    ></div>

                                    <div
                                      v-else
                                      class="progress-bar progress-bar-striped bg-danger"
                                      role="progressbar"
                                      style="width: 110%; right: +10%"
                                    ></div>
                                  </span>
                                </span>
                              </td>
                              <td>{{ user.room_type }}</td>
                              <td v-if="user.common_users.length > 0">
                                <p
                                  class="m-0 p-0"
                                  style="font-size: 0.8rem"
                                  v-for="common_user in user.common_users"
                                  :key="common_user"
                                >
                                  {{ common_user }}
                                </p>
                              </td>
                              <td v-else>{{ $t("alone_in_room") }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </template>
          </vue3-html2pdf>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue3Html2pdf from "@erst-vg/vue3-html2pdf";
import axios from "axios";

export default {
  name: "GeneratedHotelReport",
  components: {
    Vue3Html2pdf,
  },
  data() {
    return {
      rooms: [],
      hotels: [],
    };
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  methods: {
    generatePDF() {
      this.$refs.html2Pdf.generatePdf();
    },

    checkIfUserIsAssigned(user, date) {
      let assigned = false;
      user.userDates.forEach((userDate) => {
        if (userDate === date.toISOString().split("T")[0]) {
          assigned = true;
        }
      });

      return assigned ? true : false;
    },

    async getHotelInfo(name) {
      await axios.get(`/api/v1/hotels/?name=${name}`).then((response) => {
        let result = response.data.results[0];

        result.rooms = this.rooms.filter((el) => el.hotel === name);
        result.earliest_check_in = this.rooms
          .filter((el) => el.hotel === name)
          .map((el) => el.check_in)
          .sort()[0];
        result.latest_check_out = this.rooms
          .filter((el) => el.hotel === name)
          .map((el) => el.check_out)
          .sort()
          .reverse()[0];

        result.dates = [];

        const check_in = new Date(result.earliest_check_in);
        const check_out = new Date(result.latest_check_out);
        check_out.setDate(check_out.getDate() + 1);

        for (let i = check_in; i <= check_out; i.setDate(i.getDate() + 1)) {
          result.dates.push(new Date(i));
        }

        result.assigned_users = [];
        result.rooms.forEach((room) => {
          room.assigned_users.forEach((user) => {
            user = {
              ...user,
              room_type: room.room_type,
              room_color: room.color,
              room_number: room.room_number,
            };

            const date1 = new Date(user.check_in);
            const date2 = new Date(user.check_out);
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            user.nights = diffDays;

            // get list of all user dates from check in to check out
            const dates = [];
            for (let i = 0; i < diffDays; i++) {
              const date = new Date(date1);
              date.setDate(date.getDate() + i);
              dates.push(date.toISOString().split("T")[0]);
            }
            user.userDates = dates;

            user.check_in = new Date(user.check_in).toISOString().split("T")[0];
            user.check_out = new Date(user.check_out)
              .toISOString()
              .split("T")[0];
            result.assigned_users.push(user);
          });
        });
        const hotel = this.getCommonUsers(result);
        this.hotels.push(hotel);
      });
    },

    async getReportData() {
      await axios
        .get(
          `/api/v1/hotels/rooms/?event=${this.$route.params.id}&page_size=500`
        )
        .then((response) => {
          this.rooms = response.data.results;

          this.rooms.forEach((room) => {
            // generate random color based on room_number with transparency 50%
            room.color = `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(
              Math.random() * 255
            )}, ${Math.floor(Math.random() * 255)}, 0.2)`;
          });

          const hotelsArr = this.rooms.map((el) => el.hotel);
          const uniqueHotels = [...new Set(hotelsArr)];

          uniqueHotels.forEach((hotel) => {
            this.getHotelInfo(hotel);
          });
        });
    },
    getCommonUsers(hotel) {
      // for each user find users in the same room with at least 1 common date
      // add those users to the common_users array

      hotel.assigned_users.forEach((user) => {
        user.common_users = [];
        hotel.assigned_users.forEach((user2) => {
          if (
            user2.user.id !== user.user.id &&
            user2.room_number === user.room_number
          ) {
            if (user2.userDates.some((r) => user.userDates.includes(r))) {
              user.common_users.push(user2.user.full_name);
            }
          }
        });
      });
      return hotel;
    },
  },

  created() {
    this.getReportData();
  },
};
</script>

<style scoped>
.card {
  background: rgba(255, 255, 255, 0.5);
}

table {
  border: 0.5px solid black;
  font-size: 13px;
  border-collapse: collapse;
}
td {
  border: 0.5px solid black;
  text-align: center;
}

th {
  border: 0.5px solid black;
  text-align: center;
}

tr {
  break-inside: avoid;
}
</style>
