<template>
  <button class="invisible-btn text-dark" :title="$t(title)">
    <i :class="icon"></i>
  </button>
</template>

<script>
export default {
  name: "IconBtn",
  props: {
    icon: {
      type: String,
      default: "fas fa-plus text-dark",
    },
    title: {
      type: String,
      default: "",
    },
  },
};
</script>
