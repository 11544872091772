<template>
  <nav aria-label="breadcrumb ">
    <button
      v-if="this.$route.path !== '/dashboard'"
      class="btn btn-icon-only text-bg-contrast mb-0"
      type="button"
      @click="$router.back()"
    >
      <span class="btn-inner--icon">
        <i class="fas fa-arrow-left"></i>
      </span>
    </button>
  </nav>
</template>

<script>
export default {
  name: "breadcrumbs-item",
  props: {
    currentPage: {
      required: true,
    },
    textWhite: {
      type: String,
    },
  },
};
</script>
