<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div class="card mx-6">
    <div class="card-header pb-0">
      <div class="row">
        <div class="col">
          <h3 class="card-title m-0 text-primary">
            {{ `${$t("calendar")} ${new Date().getFullYear()}` }}
          </h3>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-4 col-sm-6 mb-4">
          <multiselect
            v-model="this.selectedChampionships"
            @select="this.filterChampionships"
            @remove="this.filterChampionships"
            :placeholder="$t('select_championships')"
            :options="this.championshipNames"
            :multiple="true"
            :selectLabel="$t('enter_to_select')"
            :selectedLabel="$t('selected')"
            :deselectLabel="$t('enter_to_remove')"
          />
        </div>
        <div class="col-md-8 col-sm-6 text-end">
          <button class="btn btn-primary text-primary-contrast" @click="exportToExcel">
            <i class="fas fa-file-excel"></i>
            <span class="mx-2">{{ $t("download_excel_sheet") }}</span>
          </button>
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <div v-for="month in months" :key="month.month">
        <table class="table month-table table-sm text-sm text-center">
          <thead>
            <tr>
              <th class="px-0 text-primary align-middle" style="width: 3.8%">
                {{ $t(month.month) }}
              </th>
              <th
                class="px-0 text-primary align-middle faded"
                v-for="day in generateShiftDays(month.days[0].weekday)"
                :key="day"
              ></th>
              <th
                class="px-0"
                v-for="day in month.days"
                :key="day.date"
                :style="getCellStyle(day)"
              >
                <div>
                  <div class="date">{{ day.date }}</div>
                  <div class="weekday">{{ day.weekday }}</div>
                </div>
              </th>

              <th
                v-for="i in 31 -
                month.days.length +
                (6 - generateShiftDays(month.days[0].weekday))"
                :key="i"
                class="faded"
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="championship in filteredChampionships" :key="championship.id">
              <td class="align-middle" style="width: 3.8%">
                {{ championship.abbreviation }}
              </td>
              <td
                v-for="day in generateShiftDays(month.days[0].weekday)"
                :key="day"
                style="width: 2.6%"
                class="faded"
              ></td>
              <td
                v-for="day in month.days"
                :key="day.date"
                :id="championship.abbreviation + '_' + day.full_date"
                class="p-0"
                :style="getCellStyle(day)"
              ></td>
              <td
                style="width: 2.6%"
                class="faded"
                v-for="i in 31 -
                month.days.length +
                (6 - generateShiftDays(month.days[0].weekday))"
                :key="i"
              ></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "Calendar",
  components: {
    Multiselect,
  },
  data() {
    return {
      championships: [],
      filteredChampionships: [],
      months: [],
      championshipNames: [],
      selectedChampionships: "",
    };
  },
  methods: {
    getCellStyle(day) {
      if (day.weekday === "So.") {
        return "width: 2.6%; border-right: 1px solid #dee2e6";
      }
      return "width: 2.6%";
    },
    generateShiftDays(weekday) {
      const weekdays = {
        So: 0,
        Mo: 1,
        Di: 2,
        Mi: 3,
        Do: 4,
        Fr: 5,
        Sa: 6,
      };
      weekday = weekday.substring(0, weekday.length - 1);
      return weekdays[weekday];
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.value.push(tag);
    },
    filterChampionships() {
      if (this.selectedChampionships.length === 0) {
        this.filteredChampionships = this.championships;
        return;
      }
      this.filteredChampionships = this.championships.filter((championship) =>
        this.selectedChampionships.includes(championship.name)
      );
    },

    exportToExcel() {
      this.$store.dispatch("exportToExcel");
    },

    mergeCellWithinChampEvent(champEvent) {
      const firstCellSelector = `#${champEvent.abbreviation}_${champEvent.start_date}`;
      const lastCellSelector = `#${champEvent.abbreviation}_${champEvent.end_date}`;
      const firstCell = document.querySelectorAll(firstCellSelector)[0];
      const lastCell = document.querySelectorAll(lastCellSelector)[0];

      const style = {
        backgroundColor: champEvent.color,
        color: "black",
        fontWeight: "bold",
        fontSize: "0.65rem",
        verticalAlign: "middle",
      };
      const setCellAttributes = (cell, colspan) => {
        cell.setAttribute("colspan", colspan);
        cell.setAttribute("width", `${colspan * 2.5}%`);
        cell.innerHTML = champEvent.location;
        if (champEvent.is_test) {
          cell.innerHTML += " (Test)";
        }
        Object.entries(style).forEach(([key, value]) => (cell.style[key] = value));
      };
      if (!lastCell || !firstCell) {
        return;
      }

      const firstCellIndex = Array.prototype.indexOf.call(
        firstCell.parentNode.children,
        firstCell
      );

      let lastCellIndex = Array.prototype.indexOf.call(
        lastCell.parentNode.children,
        lastCell
      );
      if (lastCellIndex < firstCellIndex) {
        const nextMonthFirstDay = champEvent.end_date.slice(0, -2) + "01";
        const nextMonthFirstCell = document.querySelectorAll(
          `#${champEvent.abbreviation}_${nextMonthFirstDay}`
        )[0];
        const nextMonthFirstCellIndex = Array.prototype.indexOf.call(
          nextMonthFirstCell.parentNode.children,
          nextMonthFirstCell
        );

        let numberOfCells = lastCellIndex - nextMonthFirstCellIndex + 1;
        setCellAttributes(nextMonthFirstCell, numberOfCells);
        for (let i = 1; i < numberOfCells; i++) {
          nextMonthFirstCell.parentNode.removeChild(
            nextMonthFirstCell.parentNode.children[nextMonthFirstCellIndex + 1]
          );
        }
        const firstMonthDates = champEvent.dates.filter((date) => {
          return date.slice(0, -2) === champEvent.start_date.slice(0, -2);
        });
        const monthLastCellSelector = `#${champEvent.abbreviation}_${
          firstMonthDates[firstMonthDates.length - 1]
        }`;
        const monthLastCell = document.querySelectorAll(monthLastCellSelector)[0];
        lastCellIndex = Array.prototype.indexOf.call(
          monthLastCell.parentNode.children,
          monthLastCell
        );
      }
      let numberOfCells = lastCellIndex - firstCellIndex + 1;
      setCellAttributes(firstCell, numberOfCells);
      for (let i = 1; i < numberOfCells; i++) {
        firstCell.parentNode.removeChild(
          firstCell.parentNode.children[firstCellIndex + 1]
        );
      }
    },
    processAllChampionshipEvents() {
      this.championships.forEach((championship) => {
        if (championship.championship_events) {
          championship.championship_events.forEach((event) => {
            this.mergeCellWithinChampEvent(event);
          });
        }
      });
    },

    checkChampEventDate(championship, date) {
      let found = false;
      championship.championship_events.forEach((event) => {
        if (event.dates.includes(date)) {
          found = true;
        }
      });
      return found;
    },
    generateMonths() {
      const year = new Date().getFullYear();
      let months = [];
      for (let i = 0; i < 12; i++) {
        const month = [];
        const daysInMonth = new Date(year, i + 1, 0).getDate();

        for (let j = 1; j <= daysInMonth; j++) {
          const day = new Date(year, i, j);
          const nextDay = new Date(year, i, j + 1);
          const weekday = day.toLocaleString("de", { weekday: "short" }) + ".";
          month.push({
            date: day.toLocaleDateString("default", { day: "numeric" }),
            weekday,
            full_date: nextDay.toISOString().split("T")[0],
          });
        }

        months.push({
          month: new Date(year, i, 1).toLocaleString("default", { month: "long" }),
          days: month,
        });
      }
      this.months = months;
    },

    generateDates(start, end) {
      const dates = [];
      const startDate = new Date(start);
      const endDate = new Date(end);
      while (startDate <= endDate) {
        dates.push(new Date(startDate).toISOString().split("T")[0]);
        startDate.setDate(startDate.getDate() + 1);
      }
      return dates;
    },

    getAllChampionships() {
      this.$store.dispatch("fetchChampionshipWithEvents").then((resp) => {
        let championships = resp.results;
        championships.forEach((championship) => {
          if (championship.championship_events) {
            championship.championship_events.forEach((event) => {
              event.dates = this.generateDates(event.start_date, event.end_date);
              event.abbreviation = championship.abbreviation;
              event.color = championship.color;
            });
          }
        });
        this.championships = championships;
        this.championshipNames = championships.map((championship) => {
          return championship.name;
        });
        this.filteredChampionships = championships;
      });
    },
  },
  created() {
    this.getAllChampionships();
    this.generateMonths();
  },
  mounted() {
    this.$store.commit("closeSidenav");
  },
  updated() {
    this.processAllChampionshipEvents();
  },
  beforeUnmount() {
    this.$store.commit("openSidenav");
  },
};
</script>

<style scss scoped>
.table td,
.table th {
  padding: 0.25rem;
  vertical-align: top;
  border: 1px solid #dee2e680;
  white-space: break-spaces;
  font-size: small;
}
.table > :not(:last-child) > :last-child > * {
  border-bottom-color: var('$light');
}
.table tbody tr:last-child td {
  border-width: 1px;
}
.faded {
  background-color: rgba(0, 0, 0, 0.1);
  width: 2.6%;
}
</style>
