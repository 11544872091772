<template>
  <aside
    class="my-3 overflow-hidden border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-xl"
    :class="'ms-3'"
    id="sidenav-main"
  >
    <div class="sidenav-header">
      <div :style="logoStyle"></div>
    </div>
    <hr class="mt-5 horizontal dark" />

    <sidenav-list :cardBg="customClass" />
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";
import { mapState } from "vuex";

export default {
  name: "index-item",
  components: {
    SidenavList,
  },

  props: {
    customClass: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState(["isRTL"]),
    logoStyle() {
      return {
        content: `url(/logos/${process.env.VUE_APP_COMPANY_NAME}_logo.png)`,
        scale: 0.3,
        'margin-right': 'auto',
        'margin-top': "20px",
        'border-radius': '30px',
      };
    },
  },

};
</script>
<style scoped></style>
