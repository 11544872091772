import axios from "axios";
import store from '@/store';
import router from '@/router';

export default class AuthService {
  constructor() {
    this.authUrl = process.env.VUE_APP_AUTHENTIK_AUTH_URL;
    this.tokenUrl = process.env.VUE_APP_AUTHENTIK_TOKEN_URL;
    this.clientId = process.env.VUE_APP_AUTHENTIK_CLIENT_ID;
    this.clientSecret = process.env.VUE_APP_AUTHENTIK_CLIENT_SECRET;
    this.redirectUri = process.env.VUE_APP_AUTHENTIK_REDIRECT_URI;
  }

  login() {
    const url = `${this.authUrl}?response_type=code&client_id=${encodeURIComponent(this.clientId)}&redirect_uri=${encodeURIComponent(this.redirectUri)}`;
    window.location.href = url;
  }

  async getAccessToken(authCode) {
    const params = new URLSearchParams();
    params.append('grant_type', 'authorization_code');
    params.append('code', authCode);
    params.append('redirect_uri', this.redirectUri);
    params.append('client_id', this.clientId);
    params.append('client_secret', this.clientSecret);

    try {
      const response = await axios.post(this.tokenUrl, params, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      });
      return response.data.access_token;
    } catch (error) {
      console.error("Error fetching access token:", error);
      throw error;  // Rethrow the error to handle it in the caller
    }
  }

  async handleCallback(code) {
    if (!code) {
      throw new Error('Code not found from OAuth2 provider.');
    }

    try {
      const token = await this.getAccessToken(code);
      if (!token) {
        throw new Error('Access token not found from OAuth2 provider.');
      }
      this.handleSuccessfullAuth("Bearer", token);
    } catch (error) {
      console.log(error);
      store.dispatch("showAlert", {
        type: "error",
        message: "Authentication failed!",
        timeout: 5000,
      });
    }
  }


  setTokenAndHeaders(tokenType, token) {
    this.setCookie("token", token, 7); // 7 days for cookie expiration
    this.setCookie("tokenType", tokenType, 7); // 7 days for cookie expiration
    axios.defaults.headers.common["Authorization"] = `${tokenType} ${token}`;
  }

  setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  async handleSuccessfullAuth(tokenType, token) {
    this.setTokenAndHeaders(tokenType, token);
    store.commit("setIsAuthenticated", true);
    axios
      .get("/api/users/me/")
      .then((response) => {
        this.user = response.data;
        store.commit("setUser", this.user);
        if (!this.user.init_pass_changed && !this.user.active_dir_sync_enabled) {
          router.push("/change-password");
        } else {
          router.push("/dashboard");
        }
        store.commit('toggleEveryDisplay');
      })
      .catch((error) => {
        console.log("error in getting user data");
        console.log(error);
      });
  }
}
