import axios from "axios";

const championship = {
  actions: {
    fetchChampionshipTypes() {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/v1/championshipTypes/")
          .then((response) => {
            return resolve(response.data);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
    fetchChampionships() {
      const year = new Date().getFullYear();
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/championship/?year=${year}`)
          .then((response) => {
            return resolve(response.data);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
    fetchChampionshipWithEvents() {
      const year = new Date().getFullYear();
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/api/v1/championship/?year=${year}&expand=championship_events`
          )
          .then((response) => {
            return resolve(response.data);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
    patchChampionship(commit, data) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/api/v1/championship/${data.id}/`, data.championship)
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
    exportToExcel() {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/championship_events/export/`, {responseType: 'arraybuffer'})
          .then((response) => {
            const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            const objectURL = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = objectURL;
            link.download = `calendar.xlsx`;
            link.click();
            URL.revokeObjectURL(objectURL);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
  },
};

export default championship;
