<template>
  <div class="container-fluid">
    <div
      class="mt-n5 page-header min-height-200 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/leomax-header-1.png') + ')',
        backgroundPositionY: '25%',
      }"
    ></div>
    <div
      class="mb-n4 mx-4 overflow-hidden card card-body blur shadow-blur mt-n4"
    >
      <div class="row gx-4">
        <div class="col-auto">
          <h6 class="mb-1 text-dark">{{ $t("event") }} {{ this.event.name }}</h6>
          <p class="text-sm mb-1 text-dark">
            {{ this.event.readable_start_date }} -
            {{ this.event.readable_end_date }} -
            <strong>{{ this.event.location }}</strong>
          </p>
        </div>
        <!-- removed event-users tab (not implemented yet) -->
        <SwitchMenu
          :menuList="[
            'event_info',
            'event_championship_events',
            'event_rooms',
            'travel_expenses',
          ]"
          :switchMenuFunc="switchCard"
        />
      </div>
    </div>
  </div>

  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <!-- Spinning circle while data is loading -->
      <div v-if="this.$store.state.isLoading" class="col-12">
        <div class="d-flex justify-content-center">
          <div class="spinner-border text-primary spinner-top" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      <div v-if="this.activeCard == 'event_info'" class="col-12">
        <event-info-card
          :title="$t('event_information')"
          :locations="this.locations"
          :info="event"
          :key="event"
          :action="{
            route: 'javascript:;',
            tooltip: $t('edit_event'),
          }"
        />
      </div>
      <div
        v-if="this.activeCard == 'event_championship_events'"
        class="col-12 col-md-6 col-xl-12"
      >
        <event-championship-event-card :title="$t('event_championship_events')" />
      </div>
      <div
        v-if="this.activeCard == 'event_rooms'"
        class="col-12 col-md-6 col-xl-12"
      >
        <event-hotels-card :title="$t('booked_rooms_for_event')" />
      </div>
      <div
        v-if="this.activeCard == 'event_users'"
        class="col-12 col-md-6 col-xl-12"
      >
        <event-users-card :title="$t('event_availabilities')" />
      </div>
      <div
        v-if="this.activeCard == 'travel_expenses'"
        class="col-12 col-md-6 col-xl-12"
      >
        <event-expenses-card />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useStore } from "vuex";
import SwitchMenu from "@/components/SwitchMenu.vue";
import EventInfoCard from "./components/EventsCards/EventInfoCard.vue";
import EventChampionshipEventCard from "./components/EventsCards/EventChampionshipEventCard.vue";
import EventHotelsCard from "./components/EventsCards/EventHotelsCard.vue";
import EventUsersCard from "./components/EventsCards/EventUsersCard.vue";
import EventExpensesCard from "./components/EventsCards/EventExpensesCard.vue";

export default {
  name: "SingleEvent",
  components: {
    SwitchMenu,
    EventInfoCard,
    EventChampionshipEventCard,
    EventHotelsCard,
    EventUsersCard,
    EventExpensesCard,
  },

  data() {
    return {
      activeCard: this.$route.query.tab || "event_info",
      showMenu: false,
      event: {},
      locations: this.getters.getLocations,
      event_championship_events: [],
    };
  },
  setup() {
    return {
      getters: useStore().getters,
    };
  },
  methods: {
    switchCard(cardName) {
      this.activeCard = cardName;
    },

    goTo(link) {
      this.$router.push(link);
    },

    async saveEvent() {
      const eventData = {
        name: this.event.name,
        start_date: this.event.start_date,
        end_date: this.event.end_date,
        location: this.event.location,
      };
      await axios
        .patch(`/api/v1/events/${this.$route.params.id}/`, eventData)
        .then(() => {
          this.goTo({ name: "events" });
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    reformatDate(date) {
      return new Date(date).toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
    },

    async getEventDetails() {
      await axios
        .get(`/api/v1/events/${this.$route.params.id}/`)
        .then((response) => {
          this.event = response.data;
          this.event.readable_start_date = this.reformatDate(
            this.event.start_date
          );
          this.event.readable_end_date = this.reformatDate(this.event.end_date);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getLocations() {
      await this.$store.dispatch("fetchLocations").then(() => {
        this.locations = this.getters.getLocations;
      });
    },
  },

  mounted() {
    this.getEventDetails();
    this.getLocations();
    this.$store.dispatch("changeIsAbsolute", true);
    if (this.$route.query.tab) {
      this.activeCard = this.$route.query.tab;
      document.querySelectorAll(".nav-link").forEach((item) => {
        item.classList.remove("active");
      });
      document.getElementById(this.$route.query.tab).classList.add("active");
    }
  },
  beforeUnmount() {
    this.$store.dispatch("changeIsAbsolute", false)
  },
};
</script>
<style scoped>
.page-header {
  z-index: -100;
}
</style>
