<template>
    <soft-button @click="login" class="mb-2 text-primary-contrast" variant="solid" color="primary" full-width>Login
        with Authentik
    </soft-button>
</template>

<script>
import AuthService from '@/services/authService';
import SoftButton from "@/components/SoftButton.vue";

export default {
    name: 'OAuthLogin',
    components: {
        SoftButton
    },
    methods: {
        login() {
            const authService = new AuthService();
            authService.login();
        }
    }
}
</script>
