<template>
  <AlertDefault @event="closeModal" :msg="msg" :visible="visible">
    <template v-slot:body>
      <p class="text-center">
        <img src="@/assets/img/icons/png/delete-sign.png" />
      </p>
      <p class="text-center text-white">{{ $t(msg) }}</p>
    </template>

    <template v-slot:footer>
      <button
        type="button"
        @click="deleteItem"
        :class="'btn btn-success text-dark'"
      >
        {{ $t("yes") }}
      </button>
      <button
        type="button"
        @click="closeModal"
        :class="'btn btn-danger text-dark'"
      >
        {{ $t("no") }}
      </button>
    </template>
  </AlertDefault>
</template>

<script>
import AlertDefault from "./AlertDefault.vue";
export default {
  name: "DeleteAlert",

  data() {
    return {
      ShowModal: false,
    };
  },
  methods: {
    closeModal() {
      if (this.customClose) {
        this.$emit("closeModal");
      } else {
        this.$parent.ShowModal = false;
      }
    },
    deleteItem() {
      this.$emit("deleteEvent");
    },
  },
  components: {
    AlertDefault,
  },
  props: {
    msg: String,
    visible: Boolean,
    variant: {
      type: String,
      default: "warning",
    },
    customClose: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
