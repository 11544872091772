import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import DynamicStyles from "./dynamic_styles";
import axios from "axios";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faGauge, faPen } from "@fortawesome/free-solid-svg-icons";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faBed } from "@fortawesome/free-solid-svg-icons";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { faFlagCheckered } from "@fortawesome/free-solid-svg-icons";
import { faCarSide } from "@fortawesome/free-solid-svg-icons";
import { faEuroSign } from "@fortawesome/free-solid-svg-icons";
import { faScrewdriverWrench } from "@fortawesome/free-solid-svg-icons";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import i18n from "./i18n";
import VueCookies from "vue3-cookies";
import FloatingVue from 'floating-vue'


library.add(
  faUserSecret,
  faGauge,
  faCalendarCheck,
  faGear,
  faUser,
  faBed,
  faFlagCheckered,
  faCarSide,
  faPen,
  faEuroSign,
  faScrewdriverWrench,
  faCalendar,
  faCoins
);

if (process.env.NODE_ENV === "development") {
  axios.defaults.baseURL = "http://localhost:8009";
} else {
  axios.defaults.baseURL = window.location.origin;
}
const appInstance = createApp(App).use(i18n);
appInstance.use(store);
appInstance.use(VueCookies);
appInstance.use(router, axios);
appInstance.use(DynamicStyles);
appInstance.use(FloatingVue)
appInstance.component("font-awesome-icon", FontAwesomeIcon);
appInstance.mount("#app");
