<template>
  <div>
    <li class="text-sm border-0 list-group-item ps-0">
      <div class="row">
        <div class="col-4">
          <strong class="label text-white fw-normal">{{ $t(label) }}:</strong>
        </div>
        <div class="col-8 p-0">
          <span v-if="!editable">{{ placeholder }}</span>
          <input
            v-if="editable"
            :type="type"
            class="minimalistic-input"
            :placeholder="placeholder"
            v-model="this.$parent.newObj[bindObj]"
            :min="date_min"
            :max="date_max"
          />
        </div>
      </div>
    </li>
  </div>
</template>

<script>
export default {
  name: "CustomInput",
  props: {
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    label: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    bindObj: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    date_min: {
      type: String,
      required: false,
      default: "",
    },
    date_max: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
<style scoped>
.minimalistic-input {
  min-width: 100%;
}
</style>
