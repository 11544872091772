export default {
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "hotels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotels"])},
  "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
  "racings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Racings"])},
  "championship_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Championship Events"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
  "availabilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Availablities"])},
  "e-cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Cart Configurator"])},
  "submited_expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submitted Trip Expences"])},
  "pending_expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Trip Expences"])},
  "unpaid_expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpaid Trip Expences"])},
  "pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pages"])},
  "sign_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Out"])},
  "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log In"])},
  "next_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next Events"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "racing_track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Racing Track"])},
  "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
  "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
  "assigned_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned Users"])},
  "assigned_racings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned Racings"])},
  "assigned_championship_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned Championship Events"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "add_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Event"])},
  "completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completion"])},
  "event_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event name"])},
  "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "assigned_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned Jobs"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "want_to_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete this item?"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
  "event_added_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event added successfully"])},
  "users_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Users"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "is_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Admin"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
  "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
  "profile_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Information"])},
  "banking_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banking Information"])},
  "travel_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel Information"])},
  "licenses_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licenses Information"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "my_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Profile"])},
  "banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banking"])},
  "travel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel"])},
  "licenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licenses"])},
  "date_of_birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Birth"])},
  "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
  "current_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Password"])},
  "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
  "confirm_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm New Password"])},
  "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
  "change_password_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password changed successfully"])},
  "change_password_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password change error"])},
  "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zipcode"])},
  "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
  "last_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Login"])},
  "is_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Active"])},
  "is_superuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Superuser"])},
  "is_staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Staff"])},
  "edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Profile"])},
  "edit_bank_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Bank Details"])},
  "edit_travel_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Travel Details"])},
  "edit_licenses_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Licenses Details"])},
  "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
  "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])},
  "bank_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Name"])},
  "holder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holder Name"])},
  "cc_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit Card Number"])},
  "cc_expiration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit Card Expiration Date"])},
  "cc_holder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit Card Holder Name"])},
  "travel_passport_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel Passport Number"])},
  "travel_passport_validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel Passport Validity"])},
  "travel_passport_scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel Passport Scan"])},
  "not_uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Uploaded"])},
  "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
  "personal_id_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal ID Number"])},
  "personal_id_validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal ID Validity"])},
  "personal_id_scan_front": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal ID Scan Front"])},
  "personal_id_scan_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal ID Scan Back"])},
  "driving_license_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driving License Number"])},
  "driving_license_validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driving License Validity"])},
  "driving_license_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driving License Category"])},
  "driving_license_scan_front": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driving License Scan Front"])},
  "driving_license_scan_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driving License Scan Back"])},
  "preferred_airport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferred Airport"])},
  "alternative_airport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternative Airport"])},
  "preferred_start_point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferred Start Point"])},
  "license_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License Number"])},
  "license_stage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License Stage"])},
  "license_validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License Validity"])},
  "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
  "license_scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License Scan"])},
  "add_license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add License"])},
  "add_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add User"])},
  "add_bankdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Bankdata"])},
  "add_travel_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Travel Information"])},
  "general_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Information"])},
  "specific_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific Information"])},
  "address_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address Information"])},
  "user_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Settings"])},
  "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
  "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
  "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationality"])},
  "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
  "select_nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select nationality"])},
  "select_gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select gender"])},
  "select_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select country"])},
  "user_added_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User was added successfully"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "default_pass_is_users_username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default user password is user's username"])},
  "user_will_be_prompted_to_change_pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User will be prompted to change password on first login"])},
  "event_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Information"])},
  "edit_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Event"])},
  "event_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Info"])},
  "event_racings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Racings"])},
  "event_championship_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event-related Championship Events"])},
  "event_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Users"])},
  "event_rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Accomodation"])},
  "jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobs"])},
  "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "series": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Series"])},
  "championship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Championship"])},
  "unassign_from_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unassign from Event"])},
  "event_has_no_racings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event has no assigned racings"])},
  "event_has_no_championship_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event has no assigned championship events"])},
  "unassigned_racings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unassigned Racings"])},
  "unassigned_championship_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unassigned Championship Events"])},
  "assign_to_the_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign to the Event"])},
  "no_racing_for_this_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No available racings for this event"])},
  "no_championship_event_for_this_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No available championship events for this event"])},
  "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completely free"])},
  "partly_occupied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partly free"])},
  "fully_occupied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Full"])},
  "event_has_no_rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event has no assigned rooms"])},
  "room_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room Number"])},
  "room_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room Type"])},
  "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
  "check_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check In"])},
  "check_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Out"])},
  "booked_rooms_for_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booked Rooms for Event"])},
  "add_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Booking"])},
  "export_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export PDF"])},
  "remove_user_from_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove User from Room"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
  "hotel_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel Name"])},
  "booked_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booked Room"])},
  "room_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The room in the"])},
  "create_racing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Racing"])},
  "create_championship_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Championship Event"])},
  "add_racing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Racing"])},
  "add_championship_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Championship Event"])},
  "location_has_no_hotels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location has no hotels. Please add hotels first"])},
  "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])},
  "all_hotels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Hotels"])},
  "add_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Hotel"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "hotels_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotels List"])},
  "rooms_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rooms List"])},
  "all_racings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Racings"])},
  "all_championship_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Championship Events"])},
  "racings_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Racings List"])},
  "championship_events_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Championship Events List"])},
  "series_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Series List"])},
  "championship_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Championship List"])},
  "all_series": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Series"])},
  "all_championship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Championships"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
  "abbreviation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbreviation"])},
  "create_serie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Serie"])},
  "accomodation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accomodation"])},
  "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
  "add_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Room"])},
  "select_hotel_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select hotel first"])},
  "no_events_for_this_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No events near this hotel"])},
  "download_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht herunterladen"])},
  "nights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nights"])},
  "hotels_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotels report"])},
  "previous_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous Events"])},
  "unassigned_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unassigned Users"])},
  "event_availabilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Availabilities"])},
  "never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Never"])},
  "travel_expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel Expenses"])},
  "my_next_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Next Events"])},
  "old_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old Password"])},
  "passwords_not_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords does not match. Please try again"])},
  "password_changed_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password was changed successfully"])},
  "password_changed_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while changing password. Please try again"])},
  "old_password_incorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old password is incorrect. Please try again"])},
  "please_change_default_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please change default password"])},
  "you_have_active_directory_sync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have active directory sync. enabled. Please ask administrator to change your password in active directory"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
  "event_expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event-related expenses"])},
  "my_travel_expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Travel Expenses"])},
  "non_event_expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other expenses"])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
  "till": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Till"])},
  "add_expense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Expense"])},
  "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
  "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted"])},
  "declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declined"])},
  "all_statuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Statuses"])},
  "adac_expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADAC Travel Expenses"])},
  "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve"])},
  "expense_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expense was saved successfully"])},
  "expense_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expense was updated successfully"])},
  "expense_approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expense was approved successfully"])},
  "expense_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expense was deleted successfully"])},
  "search_by_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by name"])},
  "search_by_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by hotel"])},
  "admin_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin Panel"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "number_of_rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rooms amount"])},
  "select_hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select hotel"])},
  "select_room_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select room type"])},
  "select_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select status"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
  "password_reset_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password was reset successfully"])},
  "password_reset_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while resetting password. Please try again"])},
  "password_reset_error_active_directory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User is synchronized with Active Directory. Password cannot be reset."])},
  "download_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download PDF"])},
  "no_expense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No expense"])},
  "travel_expense_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel expense was added successfully"])},
  "travel_expense_not_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while adding travel expense. Please try again"])},
  "select_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select type"])},
  "add_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Address"])},
  "per_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per Page"])},
  "please_add_missing_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please add missing data"])},
  "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline"])},
  "next_expense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next Expense"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "to_expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Expenses"])},
  "no_more_expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No more expenses"])},
  "decline_expense_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to decline expense? Please enter below the decline reason"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline reason"])},
  "expense_declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expense was declined successfully"])},
  "error_occured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error occured"])},
  "new_expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Expenses"])},
  "my_next_booked_rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My next booked hotel rooms"])},
  "no_booked_rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have no booked rooms"])},
  "draw_signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draw signature"])},
  "signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature"])},
  "edit_signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit signature"])},
  "signature_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature was saved successfully"])},
  "signature_not_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while saving signature. Please try again"])},
  "alone_in_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alone in room"])},
  "shares_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shares room with"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
  "Mon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon"])},
  "Tue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tue"])},
  "Wed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wed"])},
  "Thu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thu"])},
  "Fri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fri"])},
  "Sat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sat"])},
  "Sun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sun"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week"])},
  "earliest_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earliest Start"])},
  "latest_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest End"])},
  "display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display"])},
  "search_by_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by month"])},
  "select_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select days"])},
  "click to change status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to change the status"])},
  "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available"])},
  "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unavailable"])},
  "not_sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not sure"])},
  "not_set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not set"])},
  "edit_weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit weeks"])},
  "edit_selected_weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit selected weeks"])},
  "select_weeks_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select weeks to edit first"])},
  "add_availabilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add availabilities"])},
  "in_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In edit"])},
  "initial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initial"])},
  "not_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not submitted"])},
  "submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submitted"])},
  "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
  "availability_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Availability was submitted successfully"])},
  "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job"])},
  "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Members"])},
  "select_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select job"])},
  "add_job_for_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add job for this event"])},
  "racing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Racing"])},
  "championship_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Championship Event"])},
  "job_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Type"])},
  "job_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job was added successfully"])},
  "add_event_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Event Job"])},
  "job_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job was deleted successfully"])},
  "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned"])},
  "racing_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Racing Name"])},
  "championship_event_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Championship Event Name"])},
  "racing_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Racing Information"])},
  "championship_event_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Championship Event Information"])},
  "edit_racing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Racing"])},
  "edit_championship_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Championship Event"])},
  "add_job_for_racing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add job for this racing"])},
  "add_job_for_championship_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add job for this championship event"])},
  "default_series_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unassigned default jobs"])},
  "default_championship_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unassigned default jobs"])},
  "add_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Job"])},
  "assign_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign All"])},
  "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign"])},
  "series_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Series Name"])},
  "chamionship_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Championship Name"])},
  "series_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default series jobs"])},
  "championship_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default championship jobs"])},
  "series_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Series Information"])},
  "championship_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Championship Information"])},
  "edit_series": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Series"])},
  "edit_championship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Championship"])},
  "add_jobs_to_racings?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add jobs to all racings in this serie?"])},
  "add_jobs_to_championship_events?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add jobs to all championship events in this championship?"])},
  "add_job_for_series": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add job for this series"])},
  "add_job_for_championship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add job for this championship"])},
  "assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned to"])},
  "unassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unassign"])},
  "assign_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign Users"])},
  "user_added_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User was added successfully"])},
  "something_went_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
  "user_removed_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User was removed successfully"])},
  "job_added_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job was added successfully"])},
  "job_removed_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job was removed successfully"])},
  "no_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have any jobs. Please contact your administrator"])},
  "allowed_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed Jobs"])},
  "not_submitted_availabilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not submitted availabilities"])},
  "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
  "hotel_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel was added successfully"])},
  "show_all_weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all weeks"])},
  "show_edited_weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show edited weeks"])},
  "January": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January"])},
  "February": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February"])},
  "March": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March"])},
  "April": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
  "May": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
  "June": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June"])},
  "July": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["July"])},
  "August": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
  "September": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
  "October": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October"])},
  "November": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
  "December": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])},
  "download_excel_sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Excel Sheet"])},
  "select_championships": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select championships"])},
  "enter_to_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter to select"])},
  "enter_to_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter to remove"])},
  "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected"])},
  "username_or_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username or email"])},
  "room_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room was added successfully"])},
  "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])},
  "clothes_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clothes size"])},
  "jacket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jacket"])},
  "pants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pants"])},
  "shirt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shirt"])},
  "profile_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile was updated successfully"])},
  "add_clothes_sizes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add clothes sizes"])},
  "travel_expense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel Expense"])},
  "leomax_expense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leomax Travel Expense"])},
  "adac_expense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADAC Travel Expense"])},
  "x4in_expense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["x4in Travel Expense"])},
  "sms_expense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS Travel Expense"])},
  "is_test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is test"])},
  "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not set"])},
  "type_car": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraftwagen"])},
  "type_public_transport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flug- und Bahnreisen"])},
  "type_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstige"])},
  "expense_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expense paid"])},
  "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay"])},
  "expense_paid_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while paying expense. Please try again"])},
  "cost_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cost Center"])},
  "new_expense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Expense"])},
  "expense_not_approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expense not approved"])},
  "Leomax_expense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leomax Travel Expense"])},
  "all_expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Expenses"])}
}