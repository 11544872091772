<template>
    <div class="card mt-4">
        <div class="p-3 pb-0 card-header">
            <div class="row">
                <div class="col-md-7 d-flex align-items-center">
                    <h6 class="mb-0 text-white">{{ $t(this.title) }}</h6>
                </div>
                <div v-if="this.getters.isSuperuser" class="col-md-5 text-end">
                    <slot name="headerBtn">
                        <IconBtn v-if="this.addUserMode !== true" title="add_job" icon="fas fa-plus text-primary"
                            @click="enableAddUserMode" />
                    </slot>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="table-responsive p-0 tableFixHead">
                <table class="table align-items-center mb-0">
                    <thead class="text-center">
                        <tr>
                            <th v-for="header in this.headers" :key="header"
                                class="text-uppercase text-primary text-xxs font-weight-bolder opacity-7 px-0">
                                {{ $t(header) }}
                            </th>
                        </tr>
                    </thead>
                    <tbody class="text-center">
                        <tr v-if="this.addUserMode">
                            <td>
                                <select class="form-control" v-model="this.newUser.userId">
                                    <option v-for="user in this.availableUsersList" :key="user.id" :value="user.id">
                                        {{ user.full_name }} <span v-if="user.jobs.length">
                                            (
                                            <span v-for="userJob in user.jobs" :key="userJob.job">
                                                {{ userJob.job }} |&nbsp;
                                            </span>
                                            )
                                        </span>
                                    </option>
                                </select>
                            </td>
                            <td>
                                <select class="form-control" v-model="this.newUser.selectedJob">
                                    <option v-for="job in getAvailableJobs()" :key="job.id" :value="job">
                                        {{ job.job }} - {{ $t(job.type) }}
                                        <span v-if="job.championship_event">"{{ job.championship_event }}"</span>
                                    </option>
                                </select>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <IconBtn title="save" icon="fas fa-save text-primary" @click="assignUser" />
                                <IconBtn title="cancel" icon="fas fa-ban text-primary" @click="clearAssignUser" />
                            </td>
                        </tr>
                        <tr v-for="user in this.assignedUsers" :key="user.id">
                            <td>
                                <button class="invisible-btn text-white full-size"
                                    @click="this.$router.push(`/users/${user.id}`)">
                                    {{ user.full_name }}
                                </button>
                            </td>
                            <td>
                                <span>
                                    <div class="row mx-auto d-block">{{ user.assigned_to_type }}</div>
                                    <div class="row mx-auto d-block">{{ user.assigned_to_name }}</div>
                                </span>
                            </td>
                            <td>{{ user.job.name }}</td>
                            <td>
                                <IconBtn title="unassign" icon="fas fa-minus text-primary" @click="unassign(user)" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import IconBtn from "@/components/buttons/IconBtn.vue";
import { useStore } from "vuex";

export default {
    name: "AssignUsersCard",
    components: {
        IconBtn,
    },
    data() {
        return {
            newUser: {
                userId: "",
                selectedJob: '',
            },
            availableUsersList: [],
            addUserMode: false,
            headers: ["name", "assigned_to", "job"],
            jobEditable: false,
            jobsList: [],
            updatedJob: {
                job: "",
                members: "",
            },
        };
    },
    setup() {
        return {
            getters: useStore().getters,
        };
    },
    props: {
        mainObj: {
            type: Object,
            required: true,
        },
        title: {
            type: String,
            default: "assigned_users",
        },
        parsedJobs: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        assignedUsers() {
            return this.parseUsersFromEvent(this.mainObj);
        }
    },
    created() {
        this.getAllAvailableUsers();
    },
    methods: {
        enableAddUserMode() {
            this.addUserMode = true;
        },
        getAvailableJobs() {
            const jobs = []
            this.parsedJobs.forEach((job) => {
                if (job.members > job.assigned_users) {
                    jobs.push(job);
                }
            });
            return jobs;
        },

        clearAssignUser() {
            this.addUserMode = false;
            this.newUser = {
                userId: "",
                selectedJob: '',
            };
        },
        assignUser() {
            const data = {
                userId: this.newUser.userId,
                params: {
                    job_id: this.newUser.selectedJob.id,
                    job_type: this.newUser.selectedJob.type,
                }
            }
            console.log(data);
            this.$store.dispatch("assignUserToJob", data)
                .then(() => {
                    this.$store.dispatch("showAlert", {
                        message: this.$t("user_added_successfully"),
                        type: "success",
                        timeout: 3000,
                    });
                    this.$parent.$parent.getEventDetails()
                    this.clearAssignUser();
                })
                .catch((error) => {
                    console.log(error);
                    this.$store.dispatch("showAlert", {
                        message: this.$t("something_went_wrong"),
                        type: "danger",
                        timeout: 3000,
                    });
                }
                );
        },
        getAllAvailableUsers() {
            const data = {
                event_id: this.$route.params.id,
            }
            this.$store.dispatch("fetchAllAvailableUsers", data)
                .then((resp) => {
                    this.availableUsersList = resp;
                })
                .catch((error) => {
                    console.log(error);
                })
        },
        patchJob(job) {
            this.$emit("patchJob", job);
        },
        openDeleteJobModal(job) {
            this.$parent.ShowDeleteModal = true;
            this.$parent.selectedJobId = job.id;
        },

        unassign(user) {
            let job_type = ''
            if (user.championship_event_id) {
                job_type = 'championship_event'
            } else if (user.event_id) {
                job_type = 'event'
            }
            const data = {
                userId: user.id,
                params: {
                    job_id: user.job.id,
                    job_type: job_type,
                }
            }
            this.$store.dispatch("unassignUserFromJob", data)
                .then(() => {
                    this.$store.dispatch("showAlert", {
                        message: this.$t("user_removed_successfully"),
                        type: "success",
                        timeout: 3000,
                    });
                    this.$parent.$parent.getEventDetails()
                })
                .catch((error) => {
                    console.log(error);
                    this.$store.dispatch("showAlert", {
                        message: this.$t("something_went_wrong"),
                        type: "danger",
                        timeout: 3000,
                    });
                }
                );
        },
        parseUsersFromEvent(event) {
            let users = [];
            if (event.jobs) {
                event.jobs.forEach((job) => {
                    if (job.assigned_users) {
                        job.assigned_users.forEach((user) => {
                            users.push({
                                id: user.user_id,
                                full_name: user.full_name,
                                assigned_to_type: this.$t('event'),
                                event_id: event.id,
                                job: {
                                    id: job.id,
                                    name: job.job,
                                }
                            });
                        });
                    }
                });
            }
            if (event.championship_events) {
                event.championship_events.forEach((championship_event) => {
                    if (championship_event.jobs) {
                        championship_event.jobs.forEach((job) => {
                            if (job.assigned_users) {
                                job.assigned_users.forEach((user) => {
                                    users.push({
                                        id: user.user_id,
                                        full_name: user.full_name,
                                        assigned_to_type: this.$t('championship_event'),
                                        assigned_to_name: championship_event.name,
                                        championship_event_id: championship_event.id,
                                        job: {
                                            id: job.id,
                                            name: job.job,
                                        }
                                    });
                                });
                            }
                        });
                    }
                });
            }
            return users;
        },
    }
};
</script>

<style scoped>
.card {
    min-height: 49vh;
    height: fit-content;
}

.tableFixHead {
    height: 100%;
    max-width: 100%;
}
</style>
