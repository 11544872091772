<template>
    <div>
        <li class="text-sm border-0 list-group-item ps-0 d-flex d-inline">
            <strong v-if="text" class="text-white d-flex d-inline">{{ $t(text) }}:
                <Tooltip :text="tooltipText" />
            </strong>
            &nbsp;
            <span v-if="!mainObj.editable" class="text-primary">
                <button v-if="fileUrl"
                    class="invisible-btn text-white"
                    @click="getFile(fileUrl)">
                    <i class="big-icon fas fa-file-download"></i>
                </button>
                <i v-else class="icon-file-slash"></i></span>
            <input v-else type="file" class="minimalistic-input"
                @change="uploadFile($event, mainObj, fileType)" />
        </li>
    </div>
</template>

<script>
import Tooltip from "@/components/Tooltip.vue";

export default {
    name: "FileDownloadElement",
    components: {
        Tooltip,
    },
    props: {
        text: {
            type: String,
            required: true,
        },
        tooltipText: {
            type: String,
            required: false,
            default: "Max 10MB",
        },
        fileUrl: {
            required: true,
        },
        mainObj: {
            type: Object,
            required: true,
        },
        fileType: {
            type: String,
            required: false,
            default: "file",
        },
    },
    methods: {
        getFile(url) {
            this.$emit("getFile", url);
        },
        uploadFile(event, mainObj, fileType) {
            this.$emit("upload", event, mainObj, fileType);
        }
    }
}
</script>
